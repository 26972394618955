import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { CiCircleChevDown } from "react-icons/ci";
import Plyr from 'plyr';
import Hls from 'hls.js';
import QuestionForm from './Form';

const Title = styled.h4`
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
`;

const StyledForm = styled.form`
  width: 50%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  height: max-content;
  box-shadow: rgba(113, 113, 113, 0.05) 0px 0px 0px 1px;
  padding: 60px;
`;



const FormField = styled.div`
  margin-bottom: 25px;


`;


const InputContainer = styled.div`
  display: flex;
  padding: 22px;
  position: relative;
  margin-bottom: 25px;
  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`;
const Label = styled.label`
  display: block;
  font-family: Poppins;
  color: #f16896;
  margin-bottom: 10px;
  font-size: 18px;
`;

const Label2 = styled.label`
  font-family: Poppins;
  color: #436586;
  margin-bottom: 10px;
  font-size: 18px;
`;

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  appearance: none; /* Native ok simgesini gizler */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;

  &:focus {
    border-bottom: 1px solid #666;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Simgeye tıklanmasını engeller */
  color: #436586;
`;



const StyledWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
  button {
   padding: 15px 25px;
   border: unset;
   border-radius: 15px;
   color: #436586;
   z-index: 1;
   background: #e8e8e8;
   position: relative;
   font-weight: 700;
   font-size: 17px;
   width: 100%;
   -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;   
   transition: all 250ms;
   overflow: hidden;
  }

  button::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 0;
   border-radius: 15px;
   background-color: #436586;
   z-index: -1;
   -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   transition: all 250ms
  }

  button:hover {
   color: #e8e8e8;
  }

  button:hover::before {
   width: 100%;
  }`;

const VideoWrapper = styled.div`
  flex: 2;
  height: 100%;
  min-height: 400px;
  
  position: relative;
  display: flex;
  flex-direction: column;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    min-height: auto;
    max-height: none;
  }
`;
const SaveAllButton = styled.button`
  background-color: #f16896;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e43f76;
  }
`;
const CustomSelect = ({ value, onChange, children }) => {
  return (
    <SelectContainer>
      <Select value={value} onChange={onChange}>
        {children}
      </Select>
      <IconWrapper>
        <CiCircleChevDown size={30} />
      </IconWrapper>
    </SelectContainer>
  );
};

const AddInteractiveQuestion = () => {


  const token = localStorage.getItem('token');
  const [grades, setGrades] = useState([])
  const [grade, setGrade] = useState(null)
  const [lessons, setLessons] = useState([])
  const [lessonId, setLessonId] = useState(null)
  const [topics, setTopics] = useState([])
  const [topicId, setTopicId] = useState("")
  const [unitId, setUnitId] = useState(null);
  const [contents, setContents] = useState([]);
  const [contentId, setContentId] = useState(null);
  const [units, setUnits] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const videoRef = useRef(null);
  const [vttFile, setVttFile] = useState('');
  const [videoId, setVideoId] = useState("")
  const playerRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [pausedTime, setPausedTime] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]); // Tüm soruları tutacak state


  const updatedFormData = {
  };
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/grades`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setGrades(response.data.grades);
        if (!response.data) console.log('Sınıflar bulunamadı.');
      } catch (err) {
        console.log(`Grades API request failed: ${err.message}`);
      }
    };
    fetchGrades();
  }, []);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/level/${grade}/lessons`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLessons(response.data.lessons);

        if (!response.data) console.log('Sınıflar bulunamadı.');
      } catch (err) {
        console.log(`Grades API request failed: ${err.message}`);
      }
    };
    fetchGrades();
  }, [grade]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await axios.get(
          `https://api.robark.com.tr/api/lesson/${lessonId}/level/${grade}/topics`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Veriyi setUnits'e ekle
        setUnits(response.data.units);

        // topics verilerini setTopics ile ekle
        setTopics(response.data.units.flatMap((unit) => unit.topics));

      } catch (err) {
        console.log(`Units API request failed: ${err.message}`);
      }
    };

    if (lessonId && grade) fetchUnits();
  }, [lessonId, grade]);

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await axios.get(
          `https://api.robark.com.tr/api/lesson/${lessonId}/level/${grade}/unit/${unitId}/topic/${topicId}/contents`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setContents(response.data.contents);

      } catch (err) {
        console.log(`Units API request failed: ${err.message}`);
      }
    };

    if (lessonId && grade && topicId) fetchContents();
  }, [lessonId, grade, topicId]);

  const handleSaveAll = async () => {
    try {
      // Boş soru kontrolü
      const hasEmptyQuestions = allQuestions.some(q => 
        !q.interactive_multiple_questions[0].question || 
        q.interactive_multiple_questions[0].options.some(opt => !opt.text)
      );
  
      if (hasEmptyQuestions) {
        toast.error('Lütfen tüm soruları ve seçenekleri doldurun!');
        return;
      }
  
      const formattedQuestions = allQuestions.map(q => ({
        duration: q.duration,
        interactive_multiple_questions: q.interactive_multiple_questions.map(question => ({
          question: question.question,
          correct_option_id: question.correct_option_id,
          options: question.options
        }))
      }));
  
      const requestBody = {
        tests: [{
          test_type: 1,
          video_id: videoId,
          durations: formattedQuestions
        }]
      };
  
      const response = await fetch('https://api.robark.com.tr/api/interactive-multiple-questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'API isteği başarısız oldu');
      }
  
      const data = await response.json();
      toast.success('Sorular başarıyla kaydedildi!');
  
    } catch (error) {
      console.error('Hata:', error);
      toast.error(error.message || 'Bir hata oluştu!');
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Formun default davranışını engelleyelim
    // Form validasyonu için gerekli kontroller burada kalabilir
    // Ama API çağrısını buradan kaldıralım
  };
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePause = () => {
      setPausedTime(video.currentTime);
      setShowAddButton(true); // Soru Ekle butonunu göster
    };

    const handlePlay = () => {
      setShowAddButton(false); // Soru Ekle butonunu gizle
    };

    video.addEventListener("pause", handlePause);
    video.addEventListener("play", handlePlay);

    if (Hls.isSupported()) {
      const hls = new Hls({
        xhrSetup: (xhr) => {
          xhr.setRequestHeader(
            "Authorization",
            `Bearer 184|wfpV2BVNI4ooL4cTe9nyLZCG5a1dK9NDnxnJdmsQf96dd22f`
          );
        },
      });
      hls.loadSource(videoUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels.map((level) => level.height);
        availableQualities.unshift(0);
        const options = {
          quality: {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (newQuality) => {
              if (newQuality === 0) {
                hls.currentLevel = -1;
              } else {
                hls.levels.forEach((level, index) => {
                  if (level.height === newQuality) {
                    hls.currentLevel = index;
                  }
                });
              }
            },
          },
          tooltips: {
            controls: true,
          },
          previewThumbnails: {
            enabled: true,
            src: vttFile, // VTT dosyası
          },
          hideControls: true,

          controls: [
            "restart", // Restart playback
            "rewind", // Rewind by the seek time (default 10 seconds)
            "play", // Play/pause playback
            "fast-forward", // Fast forward by the seek time (default 10 seconds)
            "progress", // The progress bar and scrubber for playback and buffering
            "current-time", // The current time of playback
            "duration", // The full duration of the media
            "mute", // Toggle mute
            "volume", // Volume control
            "captions", // Toggle captions
            "settings", // Settings menu
            "pip", // Picture-in-picture (currently Safari only)
            "airplay", // Airplay (currently Safari only)
            // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            "fullscreen", // Toggle fullscreen
          ],
          i18n: {
            qualityLabel: { 0: "Auto" },
            restart: "Baştan Başlat",
            rewind: "Geri Sar {seektime} saniye",
            play: "Oynat",
            pause: "Duraklat",
            fastForward: "İleri Sar {seektime} saniye",
            seek: "Ara",
            mute: "Sesi Kapat",
            unmute: "Sesi Aç",
            volume: "Ses",
            settings: "Ayarlar",
            captions: "Altyazılar",
            quality: "Kalite",
            loop: "Döngü",
            speed: "Hız",
          },
        };
        if (!playerRef.current) {
          playerRef.current = new Plyr(video, options);
        }
      });
      return () => {
        hls.destroy();
        playerRef.current?.destroy();
      };
    } else {
      playerRef.current = new Plyr(video);
    }

    return () => {
      video.removeEventListener("pause", handlePause);
      video.removeEventListener("play", handlePlay);
      playerRef.current?.destroy();
    };
  }, [videoUrl, vttFile]);

  const handleAddQuestion = () => {
    const minutes = Math.floor(pausedTime / 60);
    const seconds = Math.floor(pausedTime % 60);
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    setQuestions((prev) => [
      ...prev,
      { time: formattedTime, text: `Question at ${formattedTime}` },
    ]);
    setShowAddButton(false); // Butonu gizle
  };


  return (
    <div>
      <div style={{ backgroundColor: "rgba(111, 133, 147, 0.1)", borderRadius: "20px", padding: "40px" }}>
        <StyledForm onSubmit={handleSubmit} className='my-3'>
          <div style={{ backgroundColor: "#f16896", borderRadius: "20px", padding: "10px", marginBottom: "50px" }}> <Title>Yeni İnteraktif Soru</Title></div>
          <FormField>
            <Label>Sınıf Düzeyi</Label>
            <InputContainer>
              <CustomSelect value={grade} onChange={
                (e) => {
                  setGrade(parseInt(e.target.value));
                  setTopicId(null);
                  setLessonId(null);
                  setUnitId(null);
                  setContentId(null);
                }} required>
                <option value="">Seçiniz</option>
                {grades.map((grade) => (
                  <option key={grade.id} >
                    {grade.level}
                  </option>
                ))}
              </CustomSelect >
            </InputContainer>
          </FormField>
          {grade ? (
            <FormField>
              <Label>Ders Adı</Label>
              <InputContainer>
                <CustomSelect
                  defaultValue=""
                  onChange={(e) => setLessonId(e.target.value)}
                  required  >
                  <option value="">Seçiniz</option>
                  {lessons.map((lesson) => (
                    <option key={lesson.id} value={lesson.lesson_id}>
                      {lesson.lesson_title}
                    </option>
                  ))}
                </CustomSelect >
              </InputContainer>
            </FormField>
          ) : (<div></div>)}
          {lessonId ? (
            <FormField>
              <Label>Konu Adı</Label>
              <InputContainer>
                <CustomSelect
                  defaultValue=""
                  onChange={(e) => {
                    const selectedTopicId = e.target.value;
                    setTopicId(selectedTopicId);
                    // Seçilen topic ile ilgili unit_id'yi bulma
                    const selectedUnit = units.find(unit =>
                      unit.topics.some(topic => topic.id.toString() === selectedTopicId)
                    );
                    if (selectedUnit) {
                      setUnitId(selectedUnit.id);
                    } else {
                      setUnitId(null); // Unit bulunamazsa boş bırak
                    }
                  }}
                  required >
                  <option value="">Seçiniz</option>
                  {topics.length > 0 &&
                    topics.map((topic) => (
                      <option key={topic.id} value={topic.id}>
                        {topic.title}
                      </option>
                    ))}
                </CustomSelect >
              </InputContainer>
            </FormField>
          ) : (<div></div>)}
          {unitId ? (
            <FormField>
              <Label>İçerikler</Label>
              <InputContainer>
                <CustomSelect
                  defaultValue=""
                  onChange={(e) => {
                    const selectedId = e.target.value; // Seçilen ID'yi al
                    const selectedContent = contents.find(
                      (content) => content.id === parseInt(selectedId)
                    ); // Doğru içerik nesnesini bul
                    if (selectedContent) {
                      setVideoId(selectedContent.video_id);
                      setVideoUrl(`https://content.robark.com.tr/api/${selectedContent.video_url}`); // video_url'yi state'e ata
                      console.log("content", `https://content.robark.com.tr/api/${selectedContent.video_url}`)
                      const vttId = selectedContent.vtt_id;
                      const vttFile = vttId
                        ? `https://content.robark.com.tr/api/${vttId}/vtt`
                        : ''; // Eğer vtt_id mevcut değilse varsayılan değer
                      setVttFile(vttFile);
                    }
                  }}
                  required>
                  <option value="">Seçiniz</option>
                  {contents.length > 0 &&
                    contents.map((content) => (
                      <option key={content.id} value={content.id}>
                        {content.title}
                      </option>
                    ))}
                </CustomSelect>
              </InputContainer>
            </FormField>
          ) : (<div></div>)}
          {videoUrl ? (
            <VideoWrapper>
              <div style={{ position: "relative" }}>
                <video
                  ref={videoRef}
                  className="plyr"
                  controls
                  playsInline
                  style={{ width: "100%", height: "100%" }}
                >
                  <source src={videoUrl} type="video/mp2t" />
                </video>
                {showAddButton && (
                  <button
                    onClick={handleAddQuestion}
                    style={{
                      position: "absolute",
                      bottom: "45%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      padding: "15px ",
                      background: "#e03ba9c7",
                      color: "#fff",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white " stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                  </button>
                )}

              </div>

              <div style={{ marginTop: "20px" }}>
                <ul>
                  {questions.map((question, index) => (
                    <li key={index}>
                      <span style={{ fontFamily: "Poppins", color: "#f16896",fontSize:"17px" }}> <strong>{question.time}</strong> için eklenecek sorular</span>
                      <QuestionForm
                        time={question.time}
                        videoId={videoId}
                        setAllQuestions={setAllQuestions}
                      />                    </li>
                  ))}
                </ul>
                {videoUrl && questions.length > 0 && (
  <SaveAllButton onClick={handleSaveAll}>
    Tüm Soruları Kaydet
  </SaveAllButton>
)}
              </div>
            </VideoWrapper>
          ) : (<div></div>)}
        </StyledForm>
      </div>
    </div>
  );
};
export default AddInteractiveQuestion;