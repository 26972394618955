import React, { useState } from 'react';
import styled from 'styled-components';
import { RiDashboardFill } from "react-icons/ri";
import { PiVideoFill } from "react-icons/pi";
import { HiDocumentChartBar } from "react-icons/hi2";
import { FaFolderOpen } from "react-icons/fa6";
import { BsChatRightQuoteFill } from "react-icons/bs";
import { MdMapsHomeWork } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { IoLogOut } from "react-icons/io5";
import { FaUserGraduate } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { FaCaretRight } from "react-icons/fa6";
import { FaCaretLeft } from "react-icons/fa6";
import { RiRobot3Fill } from "react-icons/ri";
import logo from "../../../assets/images/logo.png";
import logo2 from "../../../assets/images/logo2.png";
import ProverbsAndIdioms from '../dictionary/ProverbsAndIdioms';
import TurkishDictionary from '../dictionary/TurkishDictionary';
import AnimatedListItem from '../dictionary/Dictionary';


const Sidebar = ({ selectedItem, setSelectedItem, }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [isTurkishChatbotVisible, setIsTurkishChatbotVisible] = useState(false);
  const [isProverbsChatbotVisible, setIsProverbsChatbotVisible] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
    const toggleChatbotVisibility = () => {
      setIsChatbotVisible((prev) => !prev);
      handleClose();
    };
  
    const toggleTurkishChatbotVisibility = () => {
      setIsTurkishChatbotVisible((prev) => !prev);
      handleClose();
    };
  
    const toggleIdıomsChatbotVisibility = () => {
      setIsProverbsChatbotVisible((prev) => !prev);
      handleClose();
    };
  
  

    const handleListItemClick = (component) => {
      // Handle dictionary visibility based on menu item
      switch(component) {
        case 'ingilizcesozluk':
          setIsChatbotVisible(!isChatbotVisible);
          setIsTurkishChatbotVisible(false);
          setIsProverbsChatbotVisible(false);
          break;
        case 'turkcesozluk':
          setIsTurkishChatbotVisible(!isTurkishChatbotVisible);
          setIsChatbotVisible(false);
          setIsProverbsChatbotVisible(false);
          break;
        case 'atasozlerisozlugu':
          setIsProverbsChatbotVisible(!isProverbsChatbotVisible);
          setIsChatbotVisible(false);
          setIsTurkishChatbotVisible(false);
          break;
        default:
          setSelectedItem(component);
          // Close all dictionaries when navigating to other pages
          setIsChatbotVisible(false);
          setIsTurkishChatbotVisible(false);
          setIsProverbsChatbotVisible(false);
      }
    };
  
  console.log(selectedItem)
  const userInfo = useSelector((state) => state.user.userInfo);
  console.log("userinfosu ", userInfo)
  return (
    <>
    <SidebarContainer isCollapsed={isCollapsed}>
      <div className='flex justify-center mb-5'>
        {!isCollapsed ? (
          <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '17px', position: 'relative', lineHeight: '90%' }}>
            <img src={logo} style={{ width: "150px" }} />

          </div>
        ) : (<div> <img src={logo2} style={{ width: "70px" }} /></div>)}
      </div>


      <Menus>
        <Btn onClick={toggleSidebar}>
          {isCollapsed ? <FaCaretRight style={{ width: "20px", height: "20px", color: "#ffffff" }} /> : <FaCaretLeft style={{ width: "20px", height: "20px", color: "#ffffff" }} />}
        </Btn>
        {!isCollapsed && <p style={{ color: "#ffffff", fontFamily: "Poppins", fontSize: "12px" }}>Ana Menü</p>}
        <MenuContainer>
          {menuItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed} selected={selectedItem === item.item}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>


        { !isCollapsed && <p style={{ color: "#ffffff", fontFamily: "Poppins", fontSize: "12px" }}>Sözlükler</p>}
        <MenuContainer service>
          {dictionaryItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed} selected={selectedItem === item.item}>{item.name}</MenuName>
            </Menu>
          ))
          }
        </MenuContainer>

        { !isCollapsed && <p style={{ color: "#ffffff", fontFamily: "Poppins", fontSize: "12px" }}>Diğerleri</p>}
        <MenuContainer service>
          {serviceItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed} selected={selectedItem === item.item}>{item.name}</MenuName>
            </Menu>
          ))
          }
        </MenuContainer>

      </Menus>
    </SidebarContainer>
      <AnimatedListItem
      isChatbotVisible={isChatbotVisible}
      setIsChatbotVisible={setIsChatbotVisible}
    />
    <TurkishDictionary
      isTurkishChatbotVisible={isTurkishChatbotVisible}
      setIsTurkihChatbotVisible={setIsTurkishChatbotVisible}
    />
    <ProverbsAndIdioms
      isProverbsChatbotVisible={isProverbsChatbotVisible}
      setIsProverbsChatbotVisible={setIsProverbsChatbotVisible}
    />
</>
  );
};



const SidebarContainer = styled.div`
  width: ${props => props.isCollapsed ? '100px' : '270px'} !important;
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  transition: width 0.9s;
  border: 2px solid #ffffff;
  border-radius: 30px;
  padding-top: 30px;
  background-color:#7f6ac5;
`;


const Btn = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  cursor: pointer;
  right: 5%;
`;

const Menus = styled.div`
padding: 20px;
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  
`;

const MenuImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 15px;
  color: #ffffff;
  background-color: ${props => props.selected ? '#fce300' : 'transparent'};
  margin: 1px;
  align-items: center;
  justify-content: ${props => props.isCollapsed ? 'center' : 'flex-start'};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #fce300;
    color: #3b3b3b;

 
 
  }

  ${MenuImg} {
    color: ${props => props.selected ? '#5a498f' : 'white'};
  }


 
`;


const MenuName = styled.h3`
margin-left: 20px;
  width: 80%;
  font-weight:400;
  font-size: 14px;
  display: ${props => props.isCollapsed ? 'none' : 'flex'};
  align-items: center;
  color: ${props => props.selected ? '#5a498f' : 'white'};

`;

const menuItems = [

  { name: 'Anasayfam', item: 'anasayfa', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-house"><path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" /><path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /></svg>, altText: 'dashboard-layout' },
  { name: 'Ders Kayıtları', item: 'dersler', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-tv-minimal-play"><path d="M10 7.75a.75.75 0 0 1 1.142-.638l3.664 2.249a.75.75 0 0 1 0 1.278l-3.664 2.25a.75.75 0 0 1-1.142-.64z" /><path d="M7 21h10" /><rect width="20" height="14" x="2" y="3" rx="2" /></svg>, altText: 'task' },
  { name: 'Testler', item: 'testler', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M10 9H8" /><path d="M16 13H8" /><path d="M16 17H8" /></svg>, altText: 'calendar' },
  { name: 'İçeriklerim', item: 'iceriklerim', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-down"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M12 18v-6" /><path d="m9 15 3 3 3-3" /></svg>, altText: 'new-post' },
  { name: 'Ödevler', item: 'odevler', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book-marked"><path d="M10 2v8l3-3 3 3V2" /><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" /></svg>, altText: 'replay' },
  { name: 'Chatbot', item: 'performans', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bot"><path d="M12 8V4H8" /><rect width="16" height="12" x="4" y="8" rx="2" /><path d="M2 14h2" /><path d="M20 14h2" /><path d="M15 13v2" /><path d="M9 13v2" /></svg>, altText: 'replay' },

];


const dictionaryItems = [
  { name: 'Türkçe Sözlük', item: 'turkcesozluk', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book-type"><path d="M10 13h4" /><path d="M12 6v7" /><path d="M16 8V6H8v2" /><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" /></svg>, altText: 'replay' },
  { name: 'Türkçe - İngilizce Sözlük', item: 'ingilizcesozluk', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book-a"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" /><path d="m8 13 4-7 4 7" /><path d="M9.1 11h5.7" /></svg>, altText: 'replay' },
  { name: 'Atasözleri ve Deyimler Sözlüğü', item: 'atasozlerisozlugu', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book-marked"><path d="M10 2v8l3-3 3 3V2" /><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" /></svg>, altText: 'replay' },
];
const serviceItems = [
  { name: 'Profilim', item: 'profilim', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-pen"><path d="M11.5 15H7a4 4 0 0 0-4 4v2" /><path d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" /><circle cx="10" cy="7" r="4" /></svg>, altText: 'laptop-metrics--v2' },
  { name: 'Çıkış Yap', item: 'cikis', imgSrc: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1="21" x2="9" y1="12" y2="12" /></svg>, altText: 'web--v1' },
];

export default Sidebar;
