import React from 'react';
import styled from 'styled-components';

const Button = () => {
  return (
    <StyledWrapper>
      <button>
        <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg> Keşfet
      </button>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`

@media (max-width: 1024px) {
   transform:scale(0.9);
  }
  @media (max-width: 420px) {
   transform:scale(0.7);
   button{
    font-weight: 400;
    font-size: 17px;
   font-family: Poppins;

   }

  }
  button {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   font-family: inherit;
   font-size: 17px;
   font-family: Poppins;
   font-weight: 500;
   letter-spacing: 0.4px;
   color: #384b63;
   background-color: #ffe600;
   border-style: solid;
   border-width: 2px 2px 2px 2px;
   border-color: rgba(255, 255, 255, 0.333);
   border-radius: 40px 40px 40px 40px;
   padding: 16px 24px 16px 28px;
   transform: translate(0px, 0px) rotate(0deg);
   transition: 0.2s;
   transform: scale(1.1);

  }

  button:hover {
   color: #3e5470;
   background-color: #E5EDF5;

  }

  button:active {
   box-shadow: none;
  }`;

export default Button;
