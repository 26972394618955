import React, { useState } from 'react';
import ingilizce from "../assets/images/ingilizce.png";
import matematik from "../assets/images/matematik.png";
import turkce from "../assets/images/turkce.png";
import sosyal from "../assets/images/sosyal.png";
import fen from "../assets/images/fen.png";
import din from "../assets/images/din.png";

const LottieLessons = ({ lesson }) => {
  let lessonImage;

  switch (lesson) {
    case "Yabancı Dil":
      lessonImage = ingilizce;
      break;
    case "Matematik":
      lessonImage = matematik;
      break;
    case "Türkçe":
      lessonImage = turkce;
      break;
    case "T.C. İnkılâp Tarihi ve Atatürkçülük":
      lessonImage = sosyal;
      break;
    case "Fen Bilimleri":
      lessonImage = fen;   
      break;
    case "Din Kültürü ve Ahlak Bilgisi":
      lessonImage = din;
      break;
    default:
      lessonImage = null; // Eğer bir eşleşme yoksa, null dönebilir veya default bir görsel tanımlayabilirsin
  }

  return (
    <div style={{ width: "250px", height: "170px" }}>
      {lessonImage ? <img src={lessonImage} alt={lesson.name} style={{ width: "100%", height: "100%" }} /> : null}
    </div>
  );
};
export default LottieLessons