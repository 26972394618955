import React from 'react';
import styled from 'styled-components';
import tick from "../assets/images/tick.png";
import highlight2 from "../assets/images/highlight2.png";
import section2 from "../assets/images/section2.png";

const Container = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 150px;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  z-index: -6;

  @media (max-width: 1024px) {
    gap: 100px;
  }

  @media (max-width: 760px) {
    gap: 50px;
    margin-top: 100px;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 40px;

  }
`;

const SectionImage = styled.img`
  width: 500px;
  height: 500px;

  @media (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 760px) {
    width: 300px;
    height: 300px;
  }
`;

const TextContainer = styled.div`
  width: 33%;

  @media (max-width: 760px) {
    width: 90%;
  }
`;

const StyledTitle2 = styled.h6`
  font-weight: 800;
  font-size: 34px;
  display: inline;
  font-family: 'Onest';
  color: rgb(255, 255, 255);
  
  @media (max-width: 1024px) {
    font-size: 28px;
  }
  
  @media (max-width: 760px) {
    font-size: 24px;
  }
`;

const HighlightImage = styled.img`
  width: 40px;
  margin-left: 10px;
  display: inline;

  @media (max-width: 760px) {
    width: 30px;
  }
`;

const StyledP = styled.p`
  font-weight: 600;
  font-size: 17px;
  font-family: 'Comfortaa';
  color: rgb(255, 255, 255);
  word-wrap: break-word;
  margin-top: 20px;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
  
  @media (max-width: 760px) {
    font-size: 14px;
    margin-top: 30px;
  }
`;

const FeaturesList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 30px;
  gap: 20px;

  @media (max-width: 760px) {
    margin-left: 15px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: 760px) {
    text-align: center;
  }
`;

const TickIcon = styled.img`
  width: 40px;
  height: 40px;
  display: inline;

  @media (max-width: 760px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledArticle = styled.div`
  font-weight: 600;
  font-size: 19px;
  font-family: 'Varela';
  color: #ffffff;
  word-wrap: break-word;
  display: inline;

  @media (max-width: 1024px) {
    font-size: 17px;
  }
  
  @media (max-width: 760px) {
    font-size: 15px;
  }
`;

const SecondSection = () => {
  const features = [
    "100% MEB içeriği ile uyumlu",
    "İnteraktif ve eğlenceli öğrenme",
    "Her zaman, her yerden erişim"
  ];

  return (
    <Container>
      <ContentWrapper>
        <SectionImage src={section2} alt="Section 2" />
        <TextContainer>
          <StyledTitle2>
            <div className="relative inline-block">
              <span>Sıkılmadan </span> &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 255 32.61"
                style={{
                  position: 'absolute',
                  bottom: '-15px',
                  left: 0,
                  width: '100%',
                  height: 'auto'
                }}
              >
                <path
                  d="M5 14.11s54-8 125-9 120 5 120 5-200.5-5.5-239.5 17.5"
                  style={{
                    fill: 'none',
                    stroke: '#ffe600',
                    strokeWidth: 6,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    animation: 'draw 1.8s ease-out forwards'
                  }}
                />
              </svg>
              <style>{`
                @keyframes draw {
                  from { stroke-dashoffset: 822; }
                  to { stroke-dashoffset: 0; }
                }
              `}</style>
            </div>
            öğren
            <HighlightImage src={highlight2} alt="Highlight" />
          </StyledTitle2>
          
          <StyledP>
            İnteraktif videolar ve eğlenceli içeriklerle dersleri keşfederken zamanın nasıl geçtiğini anlamayacaksın. 
            Her konu, seni sıkmadan, kolayca öğrenebileceğin şekilde hazırlandı. Hem eğlen, hem de öğren!
          </StyledP>

          <FeaturesList>
            {features.map((feature, index) => (
              <FeatureItem key={index}>
                <TickIcon src={tick} alt="Tick" />
                <StyledArticle>{feature}</StyledArticle>
              </FeatureItem>
            ))}
          </FeaturesList>
        </TextContainer>
      </ContentWrapper>
    </Container>
  );
};

export default SecondSection;