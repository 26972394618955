import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LottieLessons from '../../../lottie/LottieLessons';
import NavigationBar from './NavigationBar';

import Video from './Video.jsx';
import { store } from '../../../redux/store.js';
import { logout } from '../../../redux/userSlice.js';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;



const CardContainer = styled.div`
  --main-color: #323232;
  --sub-color: #ebe4ff;
  --bg-color: #d3caec;
  --accent-color: #ebe4ff;

  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 350px;
  padding: 25px;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--bg-color);
  transition: all 0.2s;
  cursor: pointer;
  z-index: 1; /* Kart içeriği pseudo-elementin önünde olacak. */

  &::before {
    content: '';
    width: 100%; /* Kart boyutlarına tam oturması için %100 kullanıldı. */
    height: 100%;
    background: var(--sub-color);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    z-index: -1; /* Kartın arkasında kalacak. */
    transition: transform 0.3s ease, background-color 0.3s ease; /* Daha pürüzsüz bir efekt için. */
  }

  &:hover {
    &::before {
      transform: rotate(10deg) scale(1.05); /* Hover sırasında dönüş ve ölçeklendirme. */
      background-color: var(--accent-color); /* Hover sırasında renk değişimi. */
    }
  }
`;
const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #4c5565;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #384152;
  }
`;

const CardImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardSubtitle = styled.div`
  color: var(--sub-color);
  font-weight: 600;
  font-size: 20px;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const CardTitle = styled.div`
  color: var(--main-color);
  font-weight: 400;
  font-size: 18px;
  font-family: Poppins;
`;
const CardIcon = styled.div`
  width: 40px;
  height: 40px;
  transform: rotate(-45deg);
  transition: all 0.3s;

  & svg {
    width: 100%;
    height: 100%;
  }

  & g {
    fill: white;
  }

  & circle, polyline, line {
    stroke: var(--main-color);
  }

  ${CardContainer}:hover & {
    transform: rotate(0);
  }
`;

const CardSvg = styled.svg`
  /* SVG-specific styles */
  .st1 {
    fill: #fff;
  }
  .st2 {
    fill: none;
    stroke: #242c88;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st7 {
    fill: #589fff;
  }
  .st9 {
    fill: #bc8d66;
  }
  .st15,
  .st16 {
    opacity: 0.4;
    fill: #242c88;
  }
  .st16 {
    opacity: 0.2;
  }
  .st18 {
    fill: #ffc408;
  }
  .st53 {
    fill: none;
    stroke: #242c88;
    stroke-width: 1.848;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
`;


const Search = styled.input`
    width: 300px;
    height:50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
      color: #384152;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
    }
`;

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;


const Lessons = () => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const levelId = userInfo?.student?.grade_id;
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);
  const [contents, setContents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/level/${levelId}/lessons`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data.status) {
          setLessons(response.data.lessons);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("status", error.response.status);
          store.dispatch(logout());
        } else {
          console.error('Error fetching lessons:', error);
        }
      }
    };
    fetchLessons();
  }, [levelId, token]);

  // Üniteleri getirme
  const fetchUnits = async (lessonId) => {
    try {
      const response = await axios.get(`https://api.robark.com.tr/api/lesson/${lessonId}/level/${levelId}/topics`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status) {
        setUnits(response.data.units);
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("status", error.response.status);
        store.dispatch(logout());
      }
      console.error('Error fetching units:', error);

    }
  };
  // Konuları getirme
  const fetchTopics = async (lessonId, unitId) => {
    try {
      const response = await axios.get(`https://api.robark.com.tr/api/lesson/${lessonId}/level/${levelId}/topics`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status) {
        const unitsWithTopics = response.data.units;
        // Sadece seçili ünitenin konularını filtrele
        const selectedUnit = unitsWithTopics.find(unit => unit.id === unitId);
        const allTopics = selectedUnit ? selectedUnit.topics : [];
        setTopics(allTopics);
        console.log("Topics:", allTopics);
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("status", error.response.status);
        store.dispatch(logout());
      }
      console.error('Error fetching topics:', error);

    }
  };
  // İçerikleri getirme
  const fetchContents = async (lessonId, topicId, unitId) => {
    try {
      if (!lessonId || !topicId || !unitId) {
        console.error('Missing required parameters:', { lessonId, topicId, unitId });
        return;
      }

      const response = await axios.get(
        `https://api.robark.com.tr/api/lesson/${lessonId}/level/${levelId}/unit/${unitId}/topic/${topicId}/contents`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status) {
        setContents(response.data.contents);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        store.dispatch(logout());
      }
      console.error('Error fetching contents:', error);
    }
  };
  // Ders seçimi
  // Modify handleLessonClick
  const handleLessonClick = async (lesson) => {
    setSelectedLesson(lesson);
    setSelectedUnit(null);
    setSelectedTopic(null);
    setContents([]);
    await fetchUnits(lesson.lesson_id);
  };

  // Modify handleUnitClick
  const handleUnitClick = async (unit) => {
    setSelectedUnit(unit);
    setSelectedTopic(null);
    setContents([]);

    if (unit.lesson_id === 5 && unit.topics?.length > 0) {
      const firstTopic = unit.topics[0];
      setSelectedTopic(firstTopic);
      // Store unit data before fetching contents
      await fetchContents(unit.lesson_id, firstTopic.id, unit.id);
    } else {
      await fetchTopics(selectedLesson.lesson_id, unit.id);
    }
  };

  // Modify handleTopicClick 
  const handleTopicClick = async (topic) => {
    if (!selectedUnit || !selectedLesson) return;
    setSelectedTopic(topic);
    await fetchContents(selectedLesson.lesson_id, topic.id, selectedUnit.id);
  };
  // Arama kutusu değişimi
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredLessons = lessons.filter(lesson =>
    lesson.lesson_title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(contents)
  return (
    <div className='px-5' style={{ width: "100%", height: "100vh", }}>
      <NavigationBar item="Derslerim" />
      <Container>
        {selectedLesson ? (
          selectedUnit ? (
            selectedTopic ? (

              <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "50px", height: "100%" }}>
                <Video contents={contents} selectedLesson={selectedLesson.lesson_title} selectedTopic={selectedTopic.title} selectedUnit={selectedUnit} />
              </Grid>
            ) : (
              <Grid container spacing={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {console.log("bbbbbb", units[0].lesson_id)}
                {topics.map((topic) => (
                  <Grid item xs={12} sm={6} md={4} key={topic.id}>
                    <CardContainer onClick={() => handleTopicClick(topic)}>
                      <CardWrapper>
                        <CardTitle> {topic.title} </CardTitle>
                        <CardIcon>
                          <svg viewBox="0 0 256 256">
                            <g>
                              <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
                              <circle cx="128" cy="128" r="96" fill="none" stroke="rgb(224, 223, 220)" stroke-miterlimit="10" stroke-width="16"></circle>
                              <polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                              <line x1="88" y1="128" x2="168" y2="128" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                            </g>
                          </svg>
                        </CardIcon>
                      </CardWrapper>
                    </CardContainer>
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <Grid container spacing={3}>
              {units.map((unit) => (
                <Grid item xs={12} sm={6} md={4} key={unit.id}>
                  <CardContainer onClick={() => handleUnitClick(unit)}>
                    <CardWrapper>
                      {console.log("aaaaaaaaaa", unit.lesson_id)}
                      <CardTitle> {unit.title}  {unit.lesson_id == 5 ? " : " + (unit.topics[0].title) : ""} </CardTitle>
                      <CardIcon>
                        <svg viewBox="0 0 256 256">
                          <g>
                            <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
                            <circle cx="128" cy="128" r="96" fill="none" stroke="rgb(224, 223, 220)" stroke-miterlimit="10" stroke-width="16"></circle>
                            <polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                            <line x1="88" y1="128" x2="168" y2="128" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                          </g>
                        </svg>
                      </CardIcon>
                    </CardWrapper>
                  </CardContainer>
                </Grid>
              ))}
            </Grid>
          )
        ) : (
          // derslerin listelenmesi
          <Grid container spacing={3} >
            {filteredLessons.map((lesson) => (
              <Grid item xs={12} sm={6} md={4} key={lesson.lesson_id} >
                <CardContainer onClick={() => handleLessonClick(lesson)}>
                  <CardImg>                   <LottieLessons lesson={lesson.lesson_title} />
                  </CardImg>
                  <CardWrapper>
                    <CardTitle style={{marginTop:"30px"}}> {lesson.lesson_title} </CardTitle>
                    <CardIcon>
                      <svg viewBox="0 0 256 256">
                        <g>
                          <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
                          <circle cx="128" cy="128" r="96" fill="none" stroke="rgb(224, 223, 220)" stroke-miterlimit="10" stroke-width="16"></circle>
                          <polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                          <line x1="88" y1="128" x2="168" y2="128" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                        </g>
                      </svg>
                    </CardIcon>
                  </CardWrapper>
                </CardContainer>
              </Grid>
            ))}
          </Grid>
        )}
        {selectedLesson && (
          <Button
            onClick={() => {
              if (selectedTopic) {
                setSelectedTopic(null);
                setContents([]);
              } else if (selectedUnit) {
                setSelectedUnit(null);
                setTopics([]);
              } else {
                setSelectedLesson(null);
                setUnits([]);
              }
            }}
            variant="contained"
            color="primary"
            style={{
              position: 'fixed',
              bottom: '50px',
              left: '40px',
              backgroundColor: '#53576b',
              color: '#fff',
              borderRadius: '50%',
              minWidth: '50px',
              minHeight: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ArrowBackIcon />
          </Button>
        )}
      </Container>
    </div>
  );
};

export default Lessons;


