export const data = {
    labels: ['Türkçe', 'Matematik', 'Fen Bilimleri', 'Sosyal Bilgiler', 'Din Kültürü'],
    datasets: [
        {
            label: 'Benim ortalamam',
            data: [75,55,80,75,67,96,65],
            borderColor: '#fce300',
            backgroundColor: 'rgba(75,192,192,0)',
        },
        {
            label: 'Sınıf Ortalaması',
            data: [55,85,70,57,75,86,95],
            borderColor: '#7f6ac5',
            backgroundColor: 'rgba(153,102,255,0)',
        },
        {
            label: 'Okul Ortalaması',
            data: [75,65,90,53,75,86,95],
            borderColor: '#aaaaa',
            backgroundColor: 'rgba(255,159,64,0)',
        }
    ]
};

