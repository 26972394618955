import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUserInfo = createAsyncThunk(
  'admin/fetchUserInfo',
  async (token) => {
    const response = await fetch('https://api.robark.com.tr/api/user', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }
    return await response.json();
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    userInfo: null,
    status: 'true',
    error: null,
    token: localStorage.getItem('token') || null, 
  },
  reducers: {
    logout: (state) => {
      state.userInfo = null;
      state.status = 'false';
      state.error = null;
      state.token = null; 
      localStorage.clear();
      window.location.href = '/admin/login';
    },
    saveToken: (state, action) => {
      state.token = action.payload; 
      localStorage.setItem('token', action.payload); 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { logout, saveToken } = adminSlice.actions;
export default adminSlice.reducer;
