import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import packet from "../assets/images/packet.png";
import section from "../assets/images/section4.png";
import section2 from "../assets/images/highlight2.png";
import tick from "../assets/images/tick2.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100vh;
  color: rgb(44, 62, 80);
  font-family: 'Open Sans', sans-serif;
  width: 100vw;

  @media (max-width: 1024px) {
    gap: 30px;
    height: auto;
    padding: 40px 20px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 40px;
    padding: 60px 20px;
  }
`;

const Item = styled.div`
  width: 360px;
  height: 561px;
  background: ${(props) => (props.color ? "#fc200" : "#fff")};
  border: ${(props) => (props.color ? "3px solid #ffffff" : "none")};
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  margin: 0 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.25s ease;
  position: relative;
  
  ${(props) =>
    props.color
      ? `&:hover { transform: scale(1.15); }`
      : `&:hover { transform: scale(1.05); }`}

  @media (max-width: 1024px) {
    width: 300px;
    height: 500px;
    padding: 20px;
    
    ${(props) =>
    props.color
      ? `&:hover { transform: scale(1.1); }`
      : `&:hover { transform: scale(1.02); }`}
  }

  @media (max-width: 760px) {
    width: 90%;
    max-width: 340px;
    height: auto;
    min-height: 480px;
    margin: 0;
    
    &:hover {
      transform: none;
    }
  }
`;

const Box = styled.div`
  height: max-content;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 30px;
  gap: 70px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;

    width: 85%;
    gap: 40px;
    padding: 15px;
    
   
  }

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    width: 90%;
    gap: 30px;
    margin-top: 40px;
    

  }
`;

const StyledTitle = styled.h6`
  font-weight: 700;
  font-size: 30px; 
  font-family: 'Onest';
  color: ${(props) => (props.color ? "#ffffff" : "rgb(73, 85, 107)")};

  @media (max-width: 1024px) {
    font-size: 26px;
  }

  @media (max-width: 760px) {
    font-size: 24px;
  }
`;

const Title = styled.h6`
  font-weight: 700;
  font-size: 32px; 
  display: inline;
  font-family: 'Onest';
  color: rgb(255, 255, 255);
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 760px) {
    font-size: 24px;
    text-align: center;
  }
`;

const StyledArticle = styled.div`
  font-weight: 500;
  font-size: 19px;
  font-family: 'Poppins';
  color: #ffffff;
  word-wrap: break-word;
  display: inline;

  @media (max-width: 1024px) {
    font-size: 17px;
    
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;

const ConImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s ease;
  img {
    width: 200px;
  }
  ${Item}:hover & {
    transform: scale(1.15);
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  h3 {
    font-size: 2rem;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  color: #7f6ac5;
  font-weight: bold;
  font-size: 0.85rem;
`;

const List = styled.ul`
  padding: 20px 0;
  width: 100%;
  list-style: none;
`;


const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${(props) => (props.color ? "#ffffff" : "rgba(44,62,80,0.5)")};

  b {
    color: ${(props) => (props.color ? "#ffffff" : "rgb(44,62,80)")};
    margin-left: 4px;
  }

  i {
    font-size: 1.6rem;
    margin-right: 15px;
    color: ${(props) => (props.color ? "#fff" : "rgb(44,62,80)")};
  }

  svg {
    color: ${(props) => (props.color ? "#fff" : "currentColor")};
  }
`;

const Button = styled.button`
  padding: 14px 20px;
  width: 100%;
  font-family: Poppins;
  background: ${(props) => (props.border ? "#fff" : "#ffe600")};
  color: ${(props) => (props.border ? "#7f6ac5" : "#ffffff")};
  border-radius: 20px;
  font-weight: 600;
  font-size: 1.1rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background: ${(props) => (props.border ? "#ffe600" : "#7f6ac5")};
    color:#ffffff;
  }
`;



const Exp = styled.div`
  font-weight:600;
  font-size: 17px;
  font-family: 'Comfortaa';
  width: 100%;
  color: rgb(255, 255, 255);
  @media (max-width: 1024px) {
    text-align: center;
  }

`;
const Desription = styled.p`
  font-weight: 400;
  margin-top: 10px;
  font-size: 15px; 
  font-family: 'Poppins';
  color: ${(props) => (props.color ? "#ffffff" : "rgb(73, 85, 107)")};
`;

const Pricing = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/plans`);
        if (response.data.status) {
          setPlans(response.data.plans);
          console.log("Fetched Plans:", response.data.plans);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized:", error.response.status);
        } else {
          console.error("Error fetching plans:", error);
        }
      }
    };

    fetchPlans();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
      <Container>
        {plans.map((plan, index) => (
          <Item key={plan.id} color={index === 1}>
            <ConImg>
              <img src={packet} alt={`${plan.plan_name} Plan`} />
            </ConImg>
            {index === 1 && <Badge>Popüler</Badge>}
            <Header>
              <StyledTitle color={index === 1}>{plan.plan_name}</StyledTitle>
              <p>
                <Desription color={index === 1}>{plan.plan_description}</Desription>
              </p>
            </Header>
            <List>
              <ListItem color={index === 1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-timer">
                  <line x1="10" x2="14" y1="2" y2="2" />
                  <line x1="12" x2="15" y1="14" y2="11" />
                  <circle cx="12" cy="14" r="8" />
                </svg>
                Süre: <b>{plan.day} gün</b>
              </ListItem>
              <ListItem color={index === 1}>
                ₺
                Fiyat: <b>{plan.price} ₺</b>
              </ListItem>
              <ListItem color={index === 1}>
                <i className="bx bx-check"></i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-award"><path d="m15.477 12.89 1.515 8.526a.5.5 0 0 1-.81.47l-3.58-2.687a1 1 0 0 0-1.197 0l-3.586 2.686a.5.5 0 0 1-.81-.469l1.514-8.526" /><circle cx="12" cy="8" r="6" /></svg>
                {index === 0
                  ? "Kapsamlı ders içerikleri"
                  : index === 1
                    ? "Başarıya yönelik stratejiler"
                    : "Sistemi test edin"}
              </ListItem>
            </List>
            <Button border={index === 1}>Planı Seç</Button>
          </Item>
        ))}
      </Container>
      <Box>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
          <Title>
            Hedefin başarıysa doğru yerden başla
            <img src={section2} style={{ width: "40px", height: "40px", display: "inline" }} />
          </Title>
          <Exp>
            Başarılı bir geleceğin anahtarı, doğru adımlarla başlar. Gelişimine yön verecek içerikler ve araçlar tam burada seni bekliyor!
          </Exp>
          <div style={{ display: "flex", flexDirection: "column", marginTop: "50px", gap: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={tick} style={{ width: "35px", height: "35px", marginRight: "10px" }} />
              <StyledArticle>Eğitimin için özel hazırlanmış araçlar</StyledArticle>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={tick} style={{ width: "35px", height: "35px", marginRight: "10px" }} />
              <StyledArticle>Kendini test et, gelişimini gör</StyledArticle>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={tick} style={{ width: "35px", height: "35px", marginRight: "10px" }} />
              <StyledArticle>Etkili ve eğlenceli bir öğrenme deneyimi</StyledArticle>
            </div>
          </div>
        </div>
        <img src={section} style={{ width: "550px" }} />
      </Box> </div>
  );
};

export default Pricing;