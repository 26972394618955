const contentData = [
  {
    "unit": "Unit 1",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [
          {
            "title": "Words 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/b_bCuN/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/_GCv8c/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 3",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/z0vgGM/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 4",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/4dLqP-/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 5",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/JPD49-/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 6",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/aLnU8p/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          }
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/tkWygi/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Grammar 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/hc7ml4/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          }
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
            ]
          }
        ]
      }
    ]
  },
  {
    "unit": "Unit 2",

    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [
          {
            "title": "Words 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/KX7KPb/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/O1xJzB/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 3",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/MH2HQR/embed' width='1200' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>>"
          },

          {
            "title": "Words 4",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/ajHWRX/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },

        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/d6yZep/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Grammar 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/58x_k7/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
            ]
          }
        ]
      }
    ]

  },
  {
    "unit": "Unit 3",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [
          {
            "title": "Words 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/_XUYy7/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/w-xLhi/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 3",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/AEXmH1/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 4",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/h1rqCu/embed' width='1920' height=320' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Words 5",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/G7C7ry/embed' width='1200' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/qzSptI/embed' width='850' height='850' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Grammar 2",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/EXz0XB/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
          {
            "title": "Grammar 3",
            "content": "<iframe src='https://app.Lumi.education/api/v1/run/ezbsBV/embed' width='850' height='920' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
            ]
          }
        ]
      }
    ]

  },

  {
    "unit": "Unit 4",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [
          {
            "title": "Words 1",
            "content": "https://quizizz.com/join?gc=85441031"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/join?gc=76946925"
          },

          {
            "title": "Words 3",
            "content": "https://quizizz.com/embed/quiz/6728a3a02192cb3008ffd4b1"
          },
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/6728c5b049afb9a7294602b1"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/6728d60869753d7dced59536"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
            ]
          }
        ]
      }
    ]
  },
  {
    "unit": "Unit 5",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/6729bdcfe9c2256e6e1051df"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/6729ca6d8cbddd2bdaf56e54"
          },
          {
            "title": "Words 3",
            "content": "https://quizizz.com/embed/quiz/6729d42e70133e481758195d"
          },
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/6729fe632551c93189f0e28f"
          },

          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/6729d9eb931caa37475f8a40"
          },
          {
            "title": "Grammar 3",
            "content": "https://quizizz.com/embed/quiz/672a05bb41f9b15bb88f43c2"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
              {"A": {
                "access": "bağlanmak, erişmek",
                "account": "hesap",
                "acronym": "kısaltma",
                "active": "aktif",
                "adult": "yetişkin",
                "advice": "tavsiye, öğüt",
                "aim": "amaç",
                "alert level": "alarm seviyesi",
                "all over the world": "tüm dünyada",
                "allow": "izin vermek",
                "arrangement": "ayarlama, düzenleme",
                "article": "makale",
                "asap (as soon as possible)": "mümkün olan en kısa sürede",
                "assignment": "görev, ödev, vazife",
                "attach": "eklemek, iliştirmek",
                "attachment": "eklenti",
                "average": "ortalama",
                "avoid": "kaçınmak"
              },
          },{"B": {
                "be careful": "dikkatli ol",
                "believe in": "inanmak",
                "besides": "bunun yanında",
                "beyond": "ötesinde",
                "billion": "milyar",
                "blog post": "blog gönderisi",
                "blogger": "blog yazarı",
                "blogging": "bloglama, günlük tutma",
                "brand": "marka",
                "brochure": "broşür",
                "browse": "taramak",
                "browser": "tarayıcı",
                "button": "buton, düğme",
                "buy tickets online": "internetten bilet almak",
                "by the way": "bu arada"
              },
          },{"C": {
                "cardholder": "kart sahibi",
                "catch": "yakalamak",
                "ceremony": "tören",
                "certainly": "kesinlikle",
                "chronological order": "kronolojik sıralama",
                "clarification": "açıklama",
                "click": "tıklamak",
                "combination": "kombinasyon, birleşim",
                "comment": "yorum yapmak",
                "commercial": "ticari",
                "communication": "iletişim",
                "complaint": "şikâyet",
                "confirm": "onaylamak",
                "connection": "bağlantı",
                "connection sign": "bağlantı işareti",
                "content": "içerik",
                "copyright issue": "telif hakkı konusu",
                "create": "yaratmak",
                "creeper": "sarmaşık, sürüngen",
                "cvv number (card verification value)": "kredi kartlarının arkasındaki güvenlik kodu"
              },
          },{"D": {
                "dangerous": "tehlikeli",
                "definitely": "kesinlikle",
                "delete": "silmek",
                "device": "alet, cihaz",
                "disadvantage": "dezavantaj",
                "discount": "indirim",
                "do research": "araştırma yapmak",
                "domain name": "alan adı",
                "double-click": "çift tıklama",
                "download": "indirmek"
              },
          },{"E": {
                "e-book": "elektronik kitap",
                "effectively": "etkili bir şekilde",
                "elder": "daha yaşlı",
                "encyclopedia": "ansiklopedi",
                "engage": "kullanmak, meşgul olmak",
                "equipment": "ekipman, malzeme",
                "etiquette": "görgü kuralları",
                "evaluate": "değerlendirmek",
                "exactly": "tam olarak, kesinlikle",
                "except": "hariç",
                "expert": "uzman",
                "expiry date": "son kullanma tarihi",
                "explain": "açıklamak",
                "explorer": "araştırmacı"
              },
          },{"F": {
                "face-to-face": "yüz yüze",
                "famous": "ünlü, meşhur",
                "fear": "korku",
                "file": "dosya",
                "flat surface": "düz yüzey",
                "follow-up": "takip etmek",
                "follower": "takipçi",
                "for ages": "yıllardır",
                "foreign": "yabancı",
                "forever": "daima",
                "functional": "fonksiyonel, pratik"
              },
          },{"G": {
                "gender": "cinsiyet",
                "gently": "kibar ve nazikçe",
                "get well soon": "kısa zamanda iyi ol, geçmiş olsun",
                "giant": "devasa, çok büyük",
                "graduate": "mezun olmak",
                "graduation ceremony": "mezuniyet töreni",
                "graphing package": "grafik paketi",
                "gym": "spor salonu"
              },
          },{"H": {
                "habit": "alışkanlık",
                "health cure": "sağlık bakımı, kür"
              },
          },{"I": {
                "identity": "kimlik",
                "ignore": "görmezden gelmek",
                "illustrate": "resimlendirmek",
                "improve": "geliştirmek",
                "in other words": "bir başka deyişle",
                "increase": "artmak, artırmak",
                "individual": "bireysel",
                "influencer": "etkileyici",
                "influential": "etkili",
                "insert": "eklemek",
                "interact": "etkileşmek",
                "ironic": "alaylı, ironili",
                "it is off": "kapalı",
                "it's my treat": "sıra bende, ben ısmarlıyorum"
              },
          },{"J": {
                "journalist": "gazeteci"
              },
          },{"K": {
                "keep in touch with": "iletişim kurmak"
              },
          },{"L": {
                "local": "yerel",
                "location": "yer, konum",
                "log in/on": "giriş yapmak",
                "log out/off": "çıkış yapmak"
              },
          },{"M": {
                "make comment": "yorum yapmak",
                "meet new people online": "internette yeni insanlarla tanışmak",
                "mimics and gestures": "jest ve mimikler",
                "moderately": "ılımlı olarak, kısmen",
                "modify": "değişiklik yapmak",
                "mostly": "sıklıkla",
                "multiplayer": "çoklu oyuncu"
              },
          },{"N": {
                "nearly": "neredeyse",
                "necessarily": "zorunlu olarak",
                "necessary": "gerekli",
                "network": "ağ",
                "neuron": "nöron, sinir hücresi",
                "nickname": "takma ad, rumuz",
                "non-refundable": "iade edilemeyen",
                "notification": "bilgilendirme"
              },
          },{"O": {
                "obey": "uymak",
                "offline": "çevrimdışı",
                "one way ticket": "tek yön bilet",
                "online": "çevrimiçi",
                "online banking": "internet bankacılığı",
                "online shopping": "internetten alışveriş yapmak",
                "opinion": "fikir",
                "organization": "kurum, organizasyon",
                "over the age of 65": "65 yaş üstü"
              },
          },{"P": {
                "password": "şifre",
                "pay attention": "dikkatini vermek",
                "pay the bills": "faturaları ödemek",
                "pc (personal computer)": "kişisel bilgisayar",
                "percentage": "yüzde(%)",
                "person to person": "yüz yüze",
                "pollution": "kirlilik",
                "post": "gönderi",
                "pretend": "numara yapmak",
                "profile picture": "profil resmi",
                "properly": "düzgünce, uygun olarak",
                "public": "halk",
                "publish": "yayımlamak",
                "punctuation": "noktalama",
                "purpose": "amaç"
              },
          },{"Q": {
                "questionnaire": "anket"
              },
          },{"R": {
                "readership": "okurlar",
                "recent": "yakında olmuş",
                "recognition": "tanıma",
                "refundable": "iade edilebilir",
                "register": "kayıt olmak",
                "regulate": "düzenlemek",
                "reliable": "güvenilir",
                "reply": "cevaplamak",
                "request": "istek, rica etmek",
                "research": "araştırmak",
                "resident": "sakin, oturan",
                "rights": "haklar",
                "round trip": "gidiş geliş seyahat"
              },
          },{"S": {
                "safe": "güvenli",
                "safety rules": "güvenlik kuralları",
                "screen": "ekran",
                "search": "araştırmak",
                "search engine": "arama motoru",
                "set a wifi password": "kablosuz şifresi oluşturmak",
                "settings": "ayarlar",
                "sign in": "oturum açmak",
                "sign up": "kaydolmak",
                "smartphone": "akıllı telefon",
                "social media": "sosyal medya",
                "social networking site (sns)": "sosyal ağ sitesi",
                "software": "yazılım",
                "source": "kaynak",
                "specify": "belirtmek",
                "speech bubbles": "konuşma balonu",
                "spring": "bahar",
                "stand for": "anlamına gelmek",
                "statistics": "istatistik",
                "subscriber": "abone, katılımcı",
                "suggest": "tavsiye etmek, önermek",
                "surf": "sörf yapmak, internette gezinmek",
                "surf per day": "günlük sörf miktarı",
                "survey": "araştırma, anket"
              },
          },{"T": {
                "take care": "dikkat etmek",
                "take place": "olmak, meydana gelmek",
                "the least": "en azından",
                "turn it on": "açık konuma getirmek",
                "type": "klavye ile yazmak"
              },
          },{"U": {
                "unfortunately": "ne yazık ki, maalesef",
                "unknown": "bilinmeyen",
                "update": "güncellemek",
                "upload": "yüklemek",
                "usage": "kullanım",
                "useful": "kullanışlı, faydalı"
              },
          },{"V": {
                "valuable": "değerli",
                "vary": "çeşitli olmak, değişmek",
                "via credit card": "kredi kartı ile",
                "vlog": "video günlüğü"
              },
          },{"W": {
                "wait a second": "bir saniye bekle",
                "web browser": "internet tarayıcısı",
                "webcam": "internet kamerası",
                "website": "internet sitesi",
                "widespread": "yaygın",
                "worldwide": "dünya çapında"
              }
            }
            ]
          }
        ]
      }
    ]
  },
  {
    "unit": "Unit 6",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/672b0f5b6fd666265d6c7456"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/672b17de2e733f499b358c06"
          },

        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/672b577b62c05f54dff40aae"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/672c7f05c9aaf477ba99f865"
          },


        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
              {},
    {"A": {
      "a couple of months": "bir kaç ay",
      "a pair of": "bir çift",
      "a piece of": "bir parça",
      "abroad": "yurt dışı",
      "accident": "kaza",
      "accommodation": "konaklama",
      "adrenalin seeker": "adrenalin tutkunu",
      "adventure": "macera",
      "adventurer": "maceracı",
      "adventurous": "maceralı",
      "advertisement": "reklam",
      "aerobatic": "havada akrobasi",
      "age range": "yaş dilimi, yaş aralığı",
      "aim": "amaç",
      "aircraft": "uçak",
      "all-inclusive": "her şey dahil",
      "along the beach": "plaj boyunca",
      "amazing": "büyüleyici, muhteşem",
      "ambitious": "hırslı",
      "among": "arasında",
      "amusing": "eğlenceli",
      "antenna": "anten",
      "archery": "okçuluk",
      "armour": "zırh",
      "at any moment": "herhangi bir anda",
      "attack": "saldırmak",
      "attend": "katılmak",
      "axe": "balta"
    },},
    {"B": {
      "back up": "desteklemek",
      "base jumping": "yüksek bina, çatı gibi yerlerden atlamak",
      "become healthy": "sağlıklı olmak",
      "black sea region": "karadeniz bölgesi",
      "blow": "üflemek",
      "boomerang": "bumerang",
      "booties": "patik",
      "boots": "bot",
      "boring": "sıkıcı",
      "bravery": "cesaret",
      "bridge": "köprü",
      "building": "bina",
      "bungee jumping": "belinden veya ayağından bağlayıp yüksek bir yerden boşluğa atlamak"
    },},
    {"C": {
      "call center specialist": "çağrı merkezi uzmanı",
      "canoeing": "kano sporu yapmak",
      "catch": "yakalamak",
      "catering": "yiyecek içecek",
      "cave pack": "mağaracılık sporu için taşınan çanta",
      "caving": "mağaracılık",
      "century": "yüzyıl",
      "challenger": "meydan okuyan",
      "challenging": "zorlayıcı",
      "clarify": "açıklamak",
      "cliff": "uçurum",
      "coal miners": "kömür madencileri",
      "comfortable": "konforlu",
      "commander": "kumandan",
      "common features": "ortak özellikler",
      "compare": "karşılaştırmak",
      "controller": "kontrolcü",
      "cost": "mal olmak",
      "courage": "cesaret",
      "cover": "kaplamak",
      "crampons": "krampon",
      "craziest dream adventure": "en çılgın macera hayali",
      "crazy": "çılgın",
      "culture": "kültür"
    },},
    {"D": {
      "danger": "tehlike",
      "dangerous": "tehlikeli",
      "decide": "karar vermek",
      "demonstration": "gösteri",
      "detail": "detay",
      "dictionary": "sözlük",
      "different": "farklı",
      "difficulty": "zorluk",
      "disappointing": "hayal kırıklığına uğratıcı",
      "distance": "mesafe",
      "district": "bölge",
      "dream of": "hayalini kurmak",
      "driving a jeep": "jip sürmek",
      "drown": "boğulmak",
      "dry-suits": "dalgıç kıyafeti"
    },},
    {"E": {
      "easy": "kolay",
      "edge": "eşik",
      "elbow pads": "dirsek koruyucu",
      "entertaining": "eğlenceli",
      "exact date": "kesin tarih",
      "exciting": "heyecanlı",
      "exotic": "egzotik",
      "expand": "genişletmek, yayılmak",
      "experience": "deneyim",
      "expert": "uzman",
      "explore": "keşfetmek",
      "extreme sports": "macera sporları",
      "eye-catching": "göz alıcı"
    },},
    {"F": {
      "fall": "sonbahar",
      "fall down": "düşmek",
      "famous for": "bir şeyle ünlü olmak",
      "fantastic": "hayali, fantastik",
      "fascinating": "büyüleyici",
      "fearless": "korkusuz",
      "female": "bayan",
      "fill with air": "hava ile doldurmak",
      "fire fighters": "itfaiyeciler",
      "flashlight": "el feneri",
      "flight instruments": "uçuş gereçleri",
      "flight suit": "uçuş kıyafeti",
      "fond of": "bir şeye düşkün olmak",
      "footwear": "ayakkabı",
      "freedom": "özgürlük",
      "free-fall flight": "serbest düşüş",
      "frightening": "korkutucu",
      "frozen waterfalls": "donmuş şelaleler"
    },},
    {"G": {
      "gain": "kazanmak",
      "get frostbite": "donmak",
      "gift": "hediye",
      "glacier": "buzul",
      "gloves": "eldiven",
      "goggles": "koruma gözlüğü",
      "great": "harika",
      "guess": "tahmin etmek"
    },},
    {"H": {
      "handmade": "el yapımı",
      "hang gliding": "delta üçgeni ile uçmak",
      "harbor": "liman",
      "hard": "zor",
      "healthy": "sağlıklı",
      "heli-skiing": "helikopter ile bir dağın zirvesine çıkıp kaymak",
      "helmet": "kask",
      "highlining": "yüksek bir yerde ip üstünde yürüme",
      "hill": "tepe",
      "historic places": "tarihi yerler",
      "hometown": "memleket",
      "hormone": "hormon"
    },},
    {"I": {
      "ice climbing": "buz tırmanışı",
      "ice screws": "tırmanış yardımcısı buz çivisi",
      "importance": "önem",
      "improve": "geliştirmek",
      "include": "dahil olmak",
      "individually": "bireysel olarak",
      "indoor": "kapalı alan, içerisi",
      "industry": "endüstri",
      "inexpensive": "ucuz",
      "inexperienced": "deneyimsiz",
      "inform": "bilgilendirmek",
      "inspire": "ilham vermek",
      "instructor": "eğitmen",
      "interesting": "ilginç",
      "it seems dangerous": "tehlikeli görünüyor"
    },},
    {"J": {
      "jacket": "ceket",
      "journey": "seyahat, yolculuk",
      "jump": "zıplamak",
      "jump off": "zıplamak"
    },},
    {"K": {
      "kayaking": "kano sporu yapmak",
      "keep": "tutmak",
      "kite surfing": "uçurtma sörfü",
      "knee": "diz",
      "knee pads": "diz koruyucu",
      "knife": "bıçak"
    },},
    {"L": {
      "life jacket": "can kurtaran yeleği",
      "look up": "bakmak, araştırmak"
    },},
    {"M": {
      "magnificent": "muhteşem, fevkalade",
      "manoeuvre": "manevra",
      "manually": "elle işletilen, manuel",
      "military": "askeri",
      "modern": "modern",
      "moreover": "daha fazla",
      "motor racing": "motor yarışı",
      "motorcycle tour": "motor turu",
      "mountain biking": "dağ bisikleti",
      "mountainous road": "dağlık yol",
      "move": "hareket etmek",
      "movement": "hareket",
      "mysterious": "gizemli"
    },},
    {"N": {
      "narrator": "anlatan, okuyucu",
      "nature": "doğa",
      "nickname": "takma ad"
    },},
    {"O": {
      "ocean": "okyanus",
      "on his own": "kendi kendine",
      "over the land": "karada",
      "overland track": "kara yolu"
    },},
    {"P": {
      "paddle": "kısa kürek",
      "parachuting": "parasütle atlamak",
      "parachutist": "parasütçü",
      "paragliding": "yamaç paraşütü",
      "perform": "sergilemek",
      "pleasure": "zevk",
      "plus": "artı",
      "polo": "at üzerinde oynanan top oyunu",
      "price": "ödül",
      "protect": "korumak",
      "pyramids": "piramitler"
    },},
    {"Q": {
      "qualification": "özellikler, vasıf"
    },},
    {"R": {
      "raceway": "su kanalı, yarış pisti",
      "rafting": "rafting",
      "rate of death": "ölüm oranı",
      "reach": "ulaşmak",
      "record": "kayıt etmek",
      "redeemer": "mesih, kurtarıcı",
      "reef": "sığ kayalık",
      "repair": "tamir etmek",
      "require": "gerekmek",
      "research": "araştırma",
      "rope": "ip"
    },},
    {"S": {
      "safe": "güvenli",
      "safe landing": "güvenli iniş",
      "safety equipment": "güvenlik ekipmanları",
      "scenery": "manzara",
      "scientist": "bilim insanı",
      "scuba diving": "tüplü dalış",
      "second choice": "ikinci seçenek",
      "set a record": "rekor kırmak",
      "skateboard": "kaykay",
      "skill": "beceri, yetenek",
      "skydiver": "hava dalışçısı",
      "skydiving": "gökyüzü dalışı",
      "slow down": "yavaş",
      "soft": "yumuşak",
      "sound barrier": "ses duvarı",
      "special": "özel",
      "spray skirt": "kayaking yaparken kanoya suyun girmesine engel olan ekipman",
      "sportsmen": "sporcu",
      "squadron": "hava filosu",
      "statue": "heykel",
      "stick": "çubuk, sopa",
      "stressful": "stresli",
      "structure": "yapı",
      "stuntman": "dublör",
      "succeed": "başarmak",
      "suitable": "müsait",
      "summer camp": "yaz kampı",
      "supersonic": "sesten daha hızlı olan",
      "suppose": "varsaymak",
      "surfing": "sörf yapmak",
      "survive": "hayatta kalmak",
      "swiss alps": "isviçre alpleri"
    },},
    {"T": {
      "take risk": "risk almak",
      "team sports": "takım sporları",
      "temple": "tapınak",
      "test the limits": "sınırları test etmek",
      "the end is so close": "sonu çok yakın",
      "the great wall of china": "çin seddi",
      "thermal clothes": "termal kıyafetler",
      "torch": "meşale",
      "tourist destinations": "turistik yerler",
      "tower": "kule",
      "track": "izlemek",
      "training": "idman, eğitim",
      "transportation": "ulaşım",
      "trekking route": "yürüyüş rotası"
    },},
    {"U": {
      "underwater hockey": "su altı hokeyi"
    },},
    {"V": {
      "vary": "çeşitli",
      "view": "manzara"
    },},
    {"W": {
      "weather condition": "hava durumu",
      "whales": "balinalar",
      "whole": "bütün",
      "wind": "rüzgar",
      "wing": "kanat",
      "wonderful": "muhteşem",
      "wrist guards": "bileklik"
    }
  }
            ]
          }
        ]
      }
    ]

  },
  {
    "unit": "Unit 7",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/672cbbc4cdf99a2e638a00ec"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/672cc0c5813e38f5929e2cfb"
          },

        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/6731b18d22c75d7251e4bb43"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/6734480270e4d164d716c9db"
          },
          {
            "title": "Grammar 3",
            "content": "https://quizizz.com/embed/quiz/6735a682c7651d5bbbeeed4e"
          },
        ]
      },

      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [ 
              {"A": {
                "abandoned": "terkedilmiş, kullanılmayan",
                "abroad": "yurt dışı",
                "accommodation": "konaklama",
                "across the world": "dünya çapında",
                "adventurous": "maceralı",
                "advertisement": "reklam",
                "albania": "arnavutluk",
                "all-inclusive resort": "herşey dahil otel",
                "almond": "badem",
                "alone": "yalnız",
                "along the shores of meriç": "meriç nehri boyunca",
                "amphitheater": "amfi tiyatro",
                "anchovy": "hamsi, ançuez",
                "ancient": "eski, antik",
                "antique": "antika",
                "archaeological site": "arkeolojik alan",
                "architecture": "mimari",
                "area": "alan",
                "artificial": "suni, yapay",
                "as famous as": "kadar ünlü",
                "atmosphere": "atmosfer",
                "attractive": "çekici",
                "authentic": "otantik",
                "average temperature": "ortalama sıcaklık"
              },},
              {"B": {
                "beach": "plaj",
                "beauty": "güzellik",
                "bed and breakfast hotel": "konaklama ve kahvaltı ücrete dahil otel",
                "being understanding": "anlayışlı olmak",
                "beliefs": "inançlar",
                "believe": "inanmak",
                "black sea region": "karadeniz bölgesi",
                "boat tour": "tekne turu",
                "border": "sınır",
                "bosnia and herzegovina": "bosna hersek",
                "bosphorus": "istanbul boğazı",
                "boutique hotel": "butik otel",
                "bow": "yay",
                "bridge": "köprü",
                "brochure": "broşür",
                "bullfighting": "boğa güreşi",
                "bungalow": "tek katlı kır evi",
                "byzantine": "bizans ile ilgili"
              },},
              {"C": {
                "camping": "kamp yapma",
                "capital": "başkent",
                "caravansaries": "kervansaraylar",
                "cartridge paper": "kartuş kağıdı",
                "castle": "kale",
                "cave": "mağara",
                "celebration": "kutlama",
                "ceremony": "tören",
                "charming": "çekici",
                "cheap": "ucuz",
                "cheese blocks": "peynir blokları",
                "chorus": "koro",
                "city square": "şehir meydanı",
                "city-sightseeing": "çevre gezisi",
                "climate": "iklim",
                "clock tower": "saat kulesi",
                "committed no crime": "suça karışmamış",
                "conquer": "fethetmek",
                "conquest": "fetih",
                "consider": "durumu değerlendirmek",
                "construct": "inşa etmek",
                "cooperation": "işbirliği",
                "countryside": "kırsal kesim",
                "crossroad": "kavşak",
                "cruise vacation": "gemi tatili",
                "cultural": "kültürel",
                "cultural diversity": "kültürel çeşitlilik",
                "curtain": "perde",
                "customs": "gelenekler"
              },},
              {"D": {
                "date back to": "tarihi geçmişe dayanmak",
                "depart": "yola çıkmak, kalkmak",
                "destination": "varış yeri",
                "determination": "saptama, kararlılık",
                "deva-i misk candy": "deva-i misk şekeri",
                "differences": "farklılıklar",
                "different": "farklı",
                "direct route": "rotayı belirlemek",
                "discover": "keşfetmek",
                "dish culture": "yemek kültürü",
                "dormitory": "yatakhane, yurt",
                "due": "vadesi dolmuş"
              },},
              {"E": {
                "earthen pottery": "toprak çömlekçilik",
                "ecosystem": "eko-sistem",
                "emperor": "imparator",
                "empire": "imparatorluk",
                "enormous": "çok büyük",
                "equality": "eşitlik",
                "exchange": "değiştirmek",
                "exciting": "heyecanlı",
                "exotic": "egzotik",
                "eye-catching": "göz alıcı"
              },},
              {"F": {
                "fame": "ün, şöhret",
                "famous for": "bir şeyiyle ünlü olmak",
                "fascinating": "büyüleyici",
                "five-star hotel": "beş yıldızlı otel",
                "fortune": "talih, servet",
                "fountain": "çeşme, fıskiye",
                "fragrant soap": "kokulu sabun",
                "freedom": "özgürlük",
                "fried liver": "kızartılmış ciğer yemeği"
              },},
              {"G": {
                "gallipoli peninsula": "gelibolu yarımadası",
                "gift": "hediye",
                "golden": "altın",
                "gondolas": "gondol",
                "grand": "büyük",
                "grape juice": "üzüm suyu",
                "guess": "tahmin etmek",
                "guide": "rehber"
              },},
              {"H": {
                "highland": "dağlık bölge, yayla",
                "historic sites": "tarihi yerler",
                "historical": "tarihi",
                "honesty": "dürüstlük",
                "hospitality": "misafirperverlik",
                "hostel": "pansiyon",
                "human race": "insan ırkı"
              },},
              {"I": {
                "incredible": "inanılmaz",
                "inform": "bilgilendirmek",
                "inner": "içsel",
                "invasion": "istila, saldırı",
                "island": "ada"
              },},
              {"J": {
                "journal entries": "günlük girişler",
                "justice": "adalet"
              },},
              {"K": {
                "keep on": "devam etmek",
                "kick": "tekmelemek"
              },},
              {"L": {
                "lake": "göl",
                "landmark": "kentin simgesi",
                "landscape": "manzara, peyzaj",
                "living city": "hareketli şehir",
                "local shops": "yerel dükkanlar",
                "location": "konum"
              },},
              {"M": {
                "madrasa": "medrese",
                "magic": "sihir, büyü",
                "magnificent rock structures": "muhteşem kaya yapılar",
                "mansion": "konak, köşk",
                "martyr's memorial": "şehitlik anıt",
                "mausoleum": "mozole",
                "mediterranean": "akdeniz",
                "memories": "hafıza",
                "mercifulness": "merhametlilik",
                "migration": "göç",
                "mistake": "hata",
                "monastery": "manastır",
                "monument": "abide",
                "moreover": "dahası, ilaveten",
                "mosque": "cami",
                "mountain": "dağ",
                "museum": "müze",
                "must-see place": "görülmesi gereken yer",
                "mustard": "hardal",
                "mystic": "mistik"
              },},
              {"N": {
                "natural beauty": "doğal güzellik",
                "natural port": "doğal liman",
                "necessary values": "gerekli değerler",
                "northeast": "kuzeydoğu"
              },},
              {"O": {
                "occupied": "meşgul",
                "old-fashioned": "eski moda",
                "orkhon inscriptions": "orhun yazıtları",
                "ottoman empire": "osmanlı imparatorluğu"
              },},
              {"P": {
                "palace": "saray",
                "pantheon": "en büyük tapınak",
                "pass": "geçmek",
                "patience": "sabır",
                "pension": "pansiyon",
                "per person": "kişi başı",
                "per year": "yıllık",
                "plateau": "plato",
                "pleasure": "zevk",
                "population": "nüfus",
                "preserve": "korumak",
                "price": "fiyat",
                "prophet": "peygamber",
                "province": "vilayet, il",
                "public": "halk"
              },},
              {"R": {
                "recreational": "eğlenceli",
                "rediscover": "tekrar keşfetmek",
                "refresh": "tazelemek",
                "relate to": "alakalı olmak",
                "relax": "rahatlamak",
                "relaxing": "rahatlatıcı",
                "release": "serbest bırakmak",
                "religious": "dinsel",
                "remarkable": "dikkat çekici",
                "respect": "saygı göstermek",
                "river": "nehir",
                "rock sites": "kayalık alan",
                "rock tomb": "kaya mezarı",
                "rules and the laws": "kurallar ve kanunlar",
                "rural": "kırsal"
              },},
              {"S": {
                "sand": "kum",
                "seaside": "deniz kenarı",
                "self-confidence": "özgüven",
                "settlement": "yerleşim",
                "shrine": "türbe",
                "sightseeing": "çevre gezisi",
                "social complex": "külliye",
                "south, east, north, west": "güney, doğu, kuzey, batı",
                "southern": "güneye ait",
                "souvenir varieties": "hediyelik eşya çeşitliliği",
                "species": "tür",
                "spectator": "izleyici",
                "splendid": "muhteşem",
                "square": "meydan",
                "state guesthouse": "devlet misafirhanesi",
                "stick": "sopa",
                "strategic": "stratejik",
                "structure": "yapı",
                "style": "stil",
                "suez": "süveyş",
                "suppose": "varsaymak",
                "surrounded by water": "su ile çevrili"
              },},
              {"T": {
                "temple complex": "tapınak kompleksi",
                "terrestrial": "karasal",
                "the natural heritage": "doğal miras",
                "till the end": "sonuna kadar",
                "tomb": "mezar",
                "touch": "dokunmak",
                "tour guide": "tur rehberi",
                "tourist attractions": "turist cazibe merkezleri",
                "tourist destination": "turistik yer",
                "trade route": "ticaret yolu",
                "traditional dishes": "geleneksel yemekler",
                "traditional handcraft": "geleneksel el sanatı",
                "transportation": "ulaşım",
                "travel": "seyahat",
                "travel brochure": "seyahat broşürü",
                "trojan horse": "truva atı",
                "truly": "gerçekten",
                "turkish bath": "türk hamamı",
                "turkish culture": "türk kültürü",
                "turkish delight": "lokum",
                "two-star hotel": "iki yıldızlı otel"
              },},
              {"U": {
                "un": "birleşmiş milletler",
                "unbelievable": "inanılmaz",
                "under the dominance of": "hakimiyeti altında",
                "unesco": "birleşmiş milletler eğitim, bilim ve kültür örgütü",
                "unforgettable": "unutulmaz",
                "unicef": "birleşmiş milletler çocuklara yardım fonu",
                "unity of the family": "aile bütünlüğü",
                "unpleasant": "hoş olmayan",
                "urban": "şehirsel, şehre ait"
              },},
              {"V": {
                "vacation": "tatil",
                "varieties": "çeşitlilik",
                "various": "çeşitli",
                "vary": "değişmek",
                "village": "köy"
              },},
              {"W": {
                "waterway": "kanal, akarsu yatağı",
                "wealth": "varlık",
                "weigh": "ağırlık",
                "whole year": "tüm yıl",
                "wickerwork basket": "hasır sepet",
                "wide": "geniş",
                "wildebeest": "afrika antilobu",
                "wildlife": "vahşi yaşam",
                "world heritage list": "dünya miras listesi",
                "worldwide": "dünya çapında",
                "wrestling and cultural activities": "güreş ve kültür aktiviteleri"
              }
            }
            ]
          }
        ]
      }
    ]

  },
  {
    "unit": "Unit 8",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/6736ee2a5ecf2fd848b331b4"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/6736f4ed4958a0bcf873f6ef"
          },
          {
            "title": "Words 3",
            "content": "https://quizizz.com/embed/quiz/677d34c9c07ea167a73fa49e"
          },

        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/673d89167c2b60ed231317a9"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/673de2a0ed230d3766fe64aa"
          },
          {
            "title": "Grammar 3",
            "content": "https://quizizz.com/embed/quiz/677e2940ed03efc16a44bab0"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [ 
              { "A": {
                 "academician": "akademisyen",
                 "achievement": "başarı",
                 "accomplishment": "üstesinden",
                 "air conditioner": "klima",
                 "airbag": "hava yastığı",
                 "alchemy": "simya",
                 "allow": "izin vermek",
                 "also": "ayrıca",
                 "aluminum foil": "alüminyum folyo",
                 "anatomy": "anatomi",
                 "announce": "anons etmek",
                 "another": "bir başka",
                 "around the world": "dünya çapında",
                 "article": "makale",
                 "astronomy": "astronomi",
                 "at a lower cost": "düşük fiyata",
                 "automatic": "otomatik",
                 "award": "ödül"
               },},
           {    "B": {
                 "back and forth": "aşağı yukarı",
                 "backward": "geri",
                 "battery free": "pilsiz",
                 "belong to": "ait olmak",
                 "bestselling": "en çok satan",
                 "biochemist": "biyokimyacı",
                 "biological": "biyolojik",
                 "bionic": "biyonik",
                 "biophysicist": "biyofizikçi",
                 "birthplace": "doğum yeri",
                 "black hole": "kara delik",
                 "blind": "kör",
                 "bliss-symbol": "ideografik yazma sistemi"
               },
           } ,   {"C": {
                 "carefully": "dikkatlice",
                 "characteristic": "özellik",
                 "choose": "seçmek",
                 "clean the bathroom": "banyoyu temizlemek",
                 "clean the kitchen": "mutfağı temizlemek",
                 "clean the windows": "camları temizlemek",
                 "clean up the garage": "garajı temizlemek",
                 "clean up the house": "evi temizlemek",
                 "clothes": "kıyafet",
                 "cooperate": "iş birliği yapmak",
                 "count": "saymak",
                 "complete": "tamamlamak",
                 "complain": "şikayet etmek"
               },},
               {"D": {
                 "decorate": "dekore etmek",
                 "disturb": "rahatsız etmek",
                 "do the chores": "ev işlerini yapmak",
                 "do the laundry": "çamaşır yıkamak",
                 "do the grocery shopping": "bakkal alışverişi yapmak",
                 "doorbell": "kapı zili",
                 "dry the dishes": "bulaşıkları kurutmak"
               },},
               {"E": {
                 "enjoyable": "eğlenceli",
                 "entire": "tam, bütün",
                 "effectively": "etkili bir şekilde",
                 "explain": "açıklamak",
                 "excited": "heyecanlı",
                 "experience": "deneyim",
                 "encouraging": "cesaretlendirici",
                 "everyone": "herkes"
               },},
               {"F": {
                 "family members": "aile üyeleri",
                 "fair": "adil",
                 "female": "kadın",
                 "feast": "bayram",
                 "feeling": "duygu",
                 "favorite": "favori",
                 "feed the dog/cat": "köpeği/kediyi beslemek"
               },},
              { "G": {
                 "go with the flow": "kendini bir olayın akışına bırakmak",
                 "gather": "toplamak",
                 "garden": "bahçe",
                 "get angry": "öfkelanmak",
                 "get annoyed": "kızmak",
                 "get bored": "sıkılmak",
                 "get tired": "yorulmak",
                 "graduate": "mezun olmak",
                 "guest": "misafir"
               },},
               {"H": {
                 "have a shower": "duş almak",
                 "household chores": "ev işleri",
                 "housework": "ev işleri",
                 "health problem": "sağlık sorunu",
                 "hospital": "hastane",
                 "however": "buna rağmen",
                 "husband": "koca"
               },},
               {"I": {
                 "immediately": "derhal",
                 "importance": "önem",
                 "in charge of": "den sorumlu",
                 "in the morning": "sabah",
                 "instead of": "yerine",
                 "interest": "ilgi",
                 "iron the clothes": "kıyafetleri ütülemek",
                 "invite": "davet etmek"
               },},
               {"J": {
                 "journal entry": "günlük girişi",
                 "janitor": "kapıcı"
               },},
               {"K": {
                 "keep a diary": "günlük tutmak",
                 "keep promises": "sözünü tutmak",
                 "keep": "tutmak",
                 "kids": "çocuklar",
                 "kitchen": "mutfak"
               },},
               {"L": {
                 "library": "kütüphane",
                 "living room": "oturma odası",
                 "leaf/leaves": "yaprak/yapraklar",
                 "look after": "bakımını üstlenmek",
                 "load the dishwasher": "bulaşık makinesini doldurmak"
               },},
               {"M": {
                 "male": "erkek",
                 "make a presentation": "sunum yapmak",
                 "make bed": "yatak yapmak",
                 "make mistakes": "hata yapmak",
                 "materials": "malzemeler",
                 "meat": "et",
                 "mess": "kargaşa",
                 "mind map": "kavram haritası",
                 "mine": "benimki",
                 "mop the floor": "yerleri paspaslamak",
                 "moral": "ahlaki",
                 "mow the grass": "çimleri biçmek",
                 "mow the lawn": "çimleri biçmek",
                 "much better": "daha iyi",
                 "neat": "düzenli",
                 "necessary": "gerekli",
                 "nervous": "gergin",
                 "noisily": "gürültülü"
               },},
               {"N": {
                 "nursery school": "anaokulu",
                 "object": "nesne",
                 "obligation": "zorunluluk",
                 "on time": "vaktinde",
                 "one by one": "sırasıyla",
                 "organize": "organize etmek",
                 "own": "kendi, sahip olmak"
               },},
               {"O": {
                 "obey": "kurallara uymak",
                 "pay the bills": "faturaları ödemek",
                 "peaceful": "huzurlu",
                 "periodically": "periyodik olarak",
                 "physics": "fizik",
                 "pick up": "toplamak",
                 "poem": "şiir",
                 "positively": "olumlu şekilde",
                 "possible": "mümkün"
               },},
               {"P": {
                 "preparation": "hazırlık",
                 "prepare the breakfast": "kahvaltı hazırlamak",
                 "prepare the meals": "yemekleri hazırlamak",
                 "proverb": "deyim",
                 "questionnaire": "anket",
                 "quiet": "sessiz",
                 "raise hand": "el kaldırmak",
                 "randomly": "rastgele",
                 "rarely": "nadiren",
                 "read aloud": "sesli okumak",
                 "real world": "gerçek dünya"
               },},
               {"R": {
                 "regularly": "düzenli",
                 "relative": "akraba",
                 "respectful": "saygılı",
                 "responsibility": "sorumluluk",
                 "responsible for": "bir şeyden sorumlu olmak",
                 "return the books": "kitapları iade etmek",
                 "reward": "ödül",
                 "rights": "haklar",
                 "ring": "halka"
               },},
               {"S": {
                 "say": "söylemek",
                 "separate laundry": "kirli çamaşırları ayırmak",
                 "severe health problem": "ciddi sağlık sorunu",
                 "shoelaces": "ayakkabı bağları",
                 "silent": "sessiz",
                 "situation": "durum",
                 "school chores": "okul görevleri",
                 "send": "göndermek",
                 "suggestion": "tavsiye",
                 "sociologist": "sosyolog",
                 "sociology": "sosyoloji",
                 "sweep the leaves": "yaprakları süpürmek",
                 "separate laundry": "kirli çamaşırları ayırmak"
               },},
               {"T": {
                 "take care of": "bakımını üstlenmek",
                 "take turns": "sırasıyla yapmak",
                 "together": "birlikte",
                 "untidy": "dağınık",
                 "tidy up": "düzenlemek",
                 "tired": "yorgun"
               },},
               {"U": {
                 "understand": "anlamak",
                 "undone": "yapılmamış",
                 "untidy": "dağınık"
               },},
               {"V": {
                 "vacuum the floor": "yerleri süpürmek",
                 "volunteer": "gönüllü"
               },},
              { "W": {
                 "wash the car": "arabayı yıkamak",
                 "wash the dishes": "bulaşıkları yıkamak",
                 "water the plants": "bitkileri sulamak",
                 "weekdays": "hafta içi günler",
                 "weekends": "hafta sonları",
                 "word power": "kelimenin gücü",
                 "worried": "endişeli",
                 "write down": "yazmak"
               }},
             
            ]
          }
        ]
      }

    ]

  }, {
    "unit": "Unit 9",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/673ee83c80a7c349516cb4a9"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/673eed8eb3c2843a6fd18476"
          },
          {
            "title": "Words 3",
            "content": "https://quizizz.com/embed/quiz/677cd0bb5212bb6b9b0f4e54"
          },
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {
            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/67406965b657ad7bd316e645"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/67406f40b3abb25990b32a90"
          },
          {
            "title": "Grammar 3",
            "content": "https://quizizz.com/embed/quiz/677cfd50bd875514c26a7d39"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
              {"A": {
                "academician": "akademisyen", 
                "achievement": "başarı", 
                "accomplishment": "üstesinden", 
                "air conditioner": "klima", 
                "airbag": "hava yastığı", 
                "alchemy": "simya", 
                "allow": "izin vermek", 
                "also": "ayrıca", 
                "aluminum foil": "alüminyum folyo", 
                "anatomy": "anatomi", 
                "announce": "anons etmek", 
                "another": "bir başka", 
                "around the world": "dünya çapında", 
                "article": "makale", 
                "astronomy": "astronomi", 
                "at a lower cost": "düşük fiyata", 
                "automatic": "otomatik", 
                "award": "ödül"
              }},
              {"B": {
                "back and forth": "aşağı yukarı", 
                "backward": "geri", 
                "battery free": "pilsiz", 
                "belong to": "ait olmak", 
                "bestselling": "en çok satan", 
                "biochemist": "biyokimyacı", 
                "biological": "biyolojik", 
                "bionic": "biyonik", 
                "biophysicist": "biyofizikçi", 
                "birthplace": "doğumyeri", 
                "black hole": "kara delik", 
                "blind": "kör", 
                "bliss-symbol": "ideografik yazma sistemi"
              }},
              {"C": {
                "cancer": "kanser",
                "cannon": "ilke, kanun, top",
                "cartridge paper": "kartuş kağıdı",
                "cataract": "katarakt",
                "catgut": "ameliyat ipliği",
                "cell": "hücre",
                "century": "yüzyıl",
                "changes into": "değişmek",
                "checkpoint": "kontrol noktası",
                "chemical substance": "kimyasal madde",
                "choice": "seçim",
                "circadian clock": "24 saatlik dilim",
                "clone": "klonlamak",
                "co-founder": "kurucu ortak",
                "common": "ortak",
                "company": "şirket",
                "compass": "pusula",
                "competition": "yarışma",
                "completely": "tam olarak",
                "computerized voice": "bilgisayarlı ses",
                "condition": "durum",
                "conduct an experiment": "deney yapmak",
                "conference": "konferans",
                "consular official": "konsolosluk görevlisi",
                "container": "kap",
                "continuously": "sürekli",
                "contribution": "katkı",
                "convey": "iletmek, nakletmek",
                "cosmology": "evren bilim",
                "cover": "kaplamak",
                "creature": "yaratık",
                "culture": "kültür",
                "cure the disease": "hastalığı tedavi etmek",
                "currently": "şu andaki"
              }},
              {"D": {
                "danger": "tehlike",
                "death": "ölüm",
                "decide": "karar vermek",
                "definition": "tanım",
                "design": "tasarlamak",
                "determination": "saptama, azim",
                "development": "gelişme",
                "device": "alet, cihaz",
                "diagnose": "teşhis etmek",
                "digital": "dijital",
                "disabled": "engelli",
                "discover": "keşfetmek",
                "discoverer": "kaşif",
                "discovery": "keşif",
                "discussion": "tartışma",
                "disease": "hastalık",
                "dissolve": "çözülmek",
                "divide": "bölmek",
                "DNA repair": "DNA yapılanması",
                "driver vision": "sürücünün görüş açısı",
                "durable": "sağlam",
                "during": "süresince"
              }},
              {"E": {
                "easily": "kolayca",
                "eco-friendly": "çevre dostu",
                "electronic device": "elektronik alet",
                "emergency situation": "acil durum",
                "encourage": "cesaretlendirmek",
                "energy resources": "enerji kaynakları",
                "engineering company": "mühendislik şirketi",
                "environment": "çevre",
                "equations": "eşitlikler",
                "equipment": "malzeme",
                "ethical": "ahlaki",
                "examine": "muayene etmek",
                "excavation area": "kazı alanı",
                "exhibition": "sergi",
                "exist": "var olmak",
                "experiment": "deney",
                "expert": "uzman",
                "explode": "patlamak",
                "explore": "araştırmak",
                "eye-catching": "göz alıcı"
              }},
              {"F": {
                "factory": "fabrika",
                "fingerprints": "parmak izi",
                "fire safety product": "yangın güvenlik ürünü",
                "firefighter": "itfaiyeci",
                "fit": "sığmak",
                "fix": "tamir etmek",
                "formulate": "formül",
                "fossil": "fosil",
                "foundation": "kurum, kuruluş",
                "frown": "somurtmak"
              }},
              {"G": {
                "generate": "üretmek",
                "genius": "zeki",
                "global media company": "küresel medya şirketi",
                "global positioning system": "küresel konum belirleme sistemi",
                "goal": "hedef",
                "government": "devlet, hükümet",
                "gravity": "yer çekimi",
                "ground-breaking": "çığır açan",
                "gun": "silah"
              }},
              {"H": {
                "happen": "olmak",
                "havan ball": "havan topu",
                "headdress": "başörtüsü",
                "healthy": "sağlıklı",
                "heart": "kalp",
                "high-tech device": "yüksek teknolojili alet",
                "holographic": "holografik",
                "honorary degree": "onur belgesi",
                "housemaid": "hizmetçi",
                "human endeavor": "insan çabası",
                "hybrid": "melez, karışık, hibrit"
              }},
              {"I": {
                "improve": "geliştirmek",
                "individual": "bireysel",
                "inform": "bilgi vermek",
                "infrared camera": "kızıl ötesi kamera",
                "insect": "böcek",
                "insert": "yerleştirmek",
                "institution": "kuruluş",
                "insulin": "insülin",
                "international": "uluslararası",
                "invent": "icat etmek",
                "invention": "buluş, icat",
                "inventor": "mucit",
                "item": "madde"
              }},
              {"K": {
                "keep away": "uzak durmak",
                "kidney dialysis": "böbrek diyalizi",
                "kill": "öldürmek",
                "known as": "olarak bilinir"
              }},
              {"L": {
                "lab": "laboratuvar",
                "laws of gravity": "yer çekimi kanunları",
                "lecture": "ders",
                "liquid": "sıvı",
                "living structure": "canlı yapı",
                "low-frequency radio waves": "düşük frekanslı radyo dalgaları"
              }},
              {"M": {
                "managed to": "idare etmek",
                "manufacturer": "imalatçı",
                "matter": "madde",
                "mechanical engineering": "mekanik mühendisliği",
                "medical": "tıbbi",
                "medicine": "ilaç",
                "medieval": "orta çağa ait",
                "mental capacity": "zihinsel kapasite",
                "mentor": "danışman",
                "microbe": "mikrop",
                "microscope": "mikroskop",
                "miniature": "minyatür",
                "missing": "kayıp",
                "modeling": "örnekleme",
                "mold": "şekillendirmek",
                "molecular biologist": "moleküler biyoloji uzmanı",
                "mosquito": "sivrisinek",
                "muscle": "kas"
              }},
              {"N": {
                "NASA": "Ulusal Havacılık ve Uzay Dairesi",
                "navigation": "navigasyon",
                "neuron disease": "nöron hastalıkları",
                "nobel prize": "nobel ödülü",
                "notebook": "defter"
              }},
              {"O": {
                "observe": "gözlemlemek",
                "octopus": "ahtapot",
                "operate": "ameliyat etmek, işletmek",
                "optimism": "iyimser"
              }},
              {"P": {
                "painlessly": "ağrısızca",
                "paradox": "çözümü olmayan",
                "paraplegic": "belden aşağısı felçli",
                "particular shape": "belirli bir şekilde",
                "patented": "patentli",
                "patience": "sabır",
                "patient": "hasta",
                "penetrate": "nüfuz etmek, dokunmak",
                "perhaps": "belki",
                "pharmacy": "eczane",
                "philosophy": "felsefe",
                "physical disability": "fiziksel engel",
                "physical object": "fiziksel nesne",
                "physicist": "fizikçi",
                "physics": "fizik",
                "planet": "gezegen",
                "political": "politik",
                "porcelain": "porselen",
                "portable": "taşınabilir",
                "possible": "mümkün",
                "prevent": "engellemek",
                "printing machine": "baskı makinası",
                "printing press": "baskı makinası",
                "private high-school": "özel lise",
                "prize": "ödül",
                "probe": "incelemek",
                "produce": "üretmek",
                "product": "ürün",
                "profession": "meslek",
                "promote": "teşvik etmek",
                "prototype": "model",
                "publish": "bastırmak, yayımlamak",
                "purpose": "amaç"
              }},
              {"R": {
                "rabies vaccine": "kuduz aşısı",
                "radiation": "radyasyon",
                "radio transmitter": "radyo vericisi",
                "recognize": "tanımak",
                "remote control": "uzaktan kumanda",
                "renown": "ün, şöhret",
                "repair": "tamir etmek",
                "rescue": "kurtarmak",
                "rescuer": "kurtarıcı",
                "research": "araştırma",
                "researcher": "araştırmacı",
                "road side": "yol kenarı"
              }},
              {"S": {
                "safer environment": "daha güvenli çevre",
                "sale": "indirim",
                "scientific facts": "bilimsel gerçekler",
                "scientist": "bilim insanı",
                "search": "araştırmak",
                "selected": "seçilmiş",
                "sheep": "koyun",
                "sight-disabled": "görme engelli",
                "signal": "işaret, sinyal",
                "skill": "beceri",
                "skin cancer": "cilt kanseri",
                "sliding automatic door": "sürgülü otomatik kapı",
                "smallpox vaccination": "çiçek aşısı",
                "smoke and flames": "duman ve alevler",
                "snowflake": "kar tanesi",
                "soil sample": "toprak numunesi",
                "solar gravity": "güneş çekimi",
                "solid": "katı",
                "sound system": "ses sistemi",
                "space": "uzay",
                "special": "özel",
                "specialty": "özellik",
                "species": "türler",
                "statement": "ifade, beyan",
                "steam engine": "buhar makinası",
                "stem cell": "kök hücre",
                "step": "basamak, adım",
                "substance": "madde",
                "succeeded to": "başarmak",
                "successful": "başarılı",
                "surface": "yüzey",
                "sweat glands": "ter bezleri",
                "sweet basil": "fesleğen",
                "swing": "sallanmak",
                "synthetic": "sentetik"
              }},
              {"T": {
                "take part": "yer almak",
                "technician": "teknisyen",
                "technique": "teknik",
                "test tube": "deney tüpü",
                "textbook": "ders kitabı",
                "the gravity of the matter": "yerçekimi meselesi",
                "the law": "kanun",
                "theoretical": "teorik",
                "theorist": "teorisyen",
                "theory of relativity": "izafiyet teorisi",
                "therefore": "bu nedenle",
                "thick": "kalın",
                "thin layers": "ince tabaka",
                "thought": "düşünce",
                "through a telescope": "teleskop aracılığı ile",
                "tomography": "tomografi",
                "tongue": "dil",
                "tool": "araç",
                "transform": "aktarmak",
                "translate": "tercüme etmek",
                "transmit": "iletmek",
                "trapped": "kapana sıkışmış",
                "tyre": "tekerlek, lastik"
              }},
              {"U": {
                "ultrasonic": "sesten hızlı",
                "unborn child": "doğmamış çocuk",
                "underground": "metro",
                "unit": "birim",
                "upon": "üstünde"
              }},
              {"V": {
                "vaccination": "aşı",
                "valuable": "değerli",
                "various": "çeşitli",
                "vision": "görüş"
              }},{"W": {
                "water stock": "su vanası",
                "weapon": "silah",
                "wearable heart chip": "giyilebilir takılabilir kalp pili",
                "well-equipped": "iyi donanımlı",
                "well-known": "iyi tanınan",
                "whale": "balina",
                "wheelchair bound": "tekerlekli sandalyeye bağlı",
                "wild chimpanzees": "vahşi şempanzeler",
                "windshield wiper": "cam sileceği",
                "written text": "yazılı metin"
              }}
            ]
          }
        ]
      }

    ]

  }, {
    "unit": "Unit 10",
    "sections": [
      {
        "section": "Vocabulary",
        "iframes": [

          {
            "title": "Words 1",
            "content": "https://quizizz.com/embed/quiz/674419d8693a971680244bf2"
          },
          {
            "title": "Words 2",
            "content": "https://quizizz.com/embed/quiz/67441f26db80b541f9072410"
          },
          {
            "title": "Words 3",
            "content": "https://quizizz.com/embed/quiz/677bc725a93331a465c54f49"
          },
        ]
      },
      {
        "section": "Grammar",
        "iframes": [
          {

            "title": "Grammar 1",
            "content": "https://quizizz.com/embed/quiz/67445b20bdbfac6bc6278810"
          },
          {
            "title": "Grammar 2",
            "content": "https://quizizz.com/embed/quiz/6744641022e76f95edd1aebc"
          },
          {
            "title": "Grammar 3",
            "content": "https://quizizz.com/embed/quiz/677bb3a64240a91e1b92d2c8"
          },
        ]
      },
      {
        "section": "Unite Words",
        "iframes": [
          {
            "title": "WORDS",
            "content": [
              {
                "A": {
                  "affect": "etkilemek",
                  "aftershock": "artçı sarsıntı",
                  "against": "karşı",
                  "ago": "önce",
                  "altitude": "yükseklik, rakım",
                  "amazing": "büyüleyici, inanılmaz",
                  "approach": "yaklaşım; yaklaşmak",
                  "Asia": "Asya",
                  "at least": "en az"
                }
              },
              {
                "B": {
                  "bacteria": "bakteri",
                  "balance": "denge",
                  "beware": "sakınmak, çekinmek",
                  "billion": "milyar",
                  "build up": "inşa etmek"
                }
              },
              {
                "C": {
                  "campaign": "kampanya",
                  "cause": "sebep; sebep olmak",
                  "climate": "iklim",
                  "cloud": "bulut",
                  "common": "yaygın",
                  "consist of": "içermek, ...-den oluşmak",
                  "cut down": "kesmek"
                }
              },
              {
                "D": {
                  "damage": "hasar; hasar vermek",
                  "danger": "tehlike",
                  "death": "ölüm",
                  "declare": "ilan etmek",
                  "destroy": "yıkmak, zarar vermek",
                  "disaster": "felaket, afet",
                  "double": "çift",
                  "dry": "kuru",
                  "dust": "toz"
                }
              },
              {
                "E": {
                  "educate": "eğitmek",
                  "effect": "etki, sonuç",
                  "efficiently": "verimli bir şekilde",
                  "elevator": "asansör",
                  "enough": "yeteri kadar",
                  "environmental issue": "çevreyle ilgili konu",
                  "erupt": "patlamak",
                  "evacuate": "boşaltmak, tahliye etmek",
                  "extreme": "aşırı"
                }
              },
              {
                "F": {
                  "financially": "maddi olarak",
                  "fossil fuel depletion": "fosil yakıt tüketimi",
                  "formation": "oluşum",
                  "future": "gelecek"
                }
              },
              {
                "G": {
                  "government": "hükûmet"
                }
              },
              {
                "H": {
                  "human": "insan",
                  "injure": "yaralamak",
                  "infection": "enfeksiyon"
                }
              },
              {
                "I": {
                  "intense": "yoğun",
                  "irresponsibly": "sorumsuzca",
                  "issue": "mesele, konu"
                }
              },
              {
                "L": {
                  "lake": "göl",
                  "land": "toprak",
                  "landslide": "toprak kayması",
                  "layer": "tabaka",
                  "lightning": "şimşek"
                }
              },
              {
                "M": {
                  "magnitude": "şiddet",
                  "main quake": "esas sarsıntı",
                  "man-made": "insan yapımı",
                  "mass": "kütle",
                  "measure": "ölçmek, önlem",
                  "melt": "eritmek"
                }
              },
              {
                "N": {
                  "natural phenomena": "doğal olaylar",
                  "necessary": "gerekli",
                  "non-toxic": "zehirsiz"
                }
              },
              {
                "O": {
                  "occur": "meydana gelmek",
                  "overpopulation": "aşırı nüfus artışı",
                  "outer": "dış"
                }
              },
              {
                "P": {
                  "particular": "özel, belirli",
                  "physical damage": "fiziksel hasar",
                  "potential": "potansiyel",
                  "prediction": "tahmin",
                  "preserve": "korumak",
                  "pressure": "baskı",
                  "process": "işlem, süreç"
                }
              },
              {
                "R": {
                  "reason": "sebep",
                  "regular": "düzenli",
                  "rescue team": "kurtarma ekibi",
                  "result": "sonuç"
                }
              },
              {
                "S": {
                  "serious": "ciddi",
                  "severity": "şiddet",
                  "source": "kaynak",
                  "stay calm": "sakin kalmak",
                  "sudden": "ani; aniden",
                  "surface": "yüzey",
                  "survivor": "hayatta kalan"
                }
              },
              {
                "T": {
                  "take necessary measures": "gerekli önlemleri almak",
                  "take place": "meydana gelmek",
                  "threat": "tehdit",
                  "toxic waste": "zehirli atık"
                }
              },
              {
                "V": {
                  "various": "çeşitli",
                  "victim": "kurban",
                  "virus": "virüs"
                }
              },
              {
                "W": {
                  "waste": "boşa harcamak",
                  "wild animal": "vahşi hayvan",
                  "wildlife conservation": "vahşi yaşamı koruma",
                  "worth living": "yaşamaya değer"
                }
              }
            ]
          }
        ]
      }
      
    ]

  }







]

export default contentData;