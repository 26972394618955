import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import FirstSection from '../components/FirstSection';
import SecondSection from '../components/SecondSection';
import Plans from '../components/Plans';
import styled from 'styled-components';
import Books from '../components/Books';
import Footer from '../components/Footer';
import bg from "../assets/images/background.svg"
const AppContainer = styled.div`
    width: 100%;
    height: 100%;
 
    background-image: url(${bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const App = styled.div`
    width: 100vw;
    height: 100%;
    position: relative;
    --color: #9685d3;
    background-color: #7f6ac5;
    background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
        linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent);
    background-size: 60px 60px;
    overflow-x: hidden;

`;

const Dashboard = () => {



  return (
    <App >
      <AppContainer>
        <Navbar/>
        <FirstSection />
        <Books/> 
        <SecondSection/>
        <Plans/>
        </AppContainer>
    </App>
  );
}

export default Dashboard;
