import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Title = styled.h4`
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
`;

const StyledForm = styled.form`
  width: 50%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(113, 113, 113, 0.05) 0px 0px 0px 1px;
  padding: 60px;
`;

const StyledLabel = styled.label`
  color: #555;
  font-family: "Raleway";
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 15px;
  border: 1px solid #ccc;
  outline: none;

  &:hover {
    border-color: #eaeaea;
  }

  &:focus {
    border-color: #B39DDB;
  }
`;
const FormField = styled.div`
  margin-bottom: 25px;
`;

const SubmitButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background-color: #e8e8e8  ;
  color: #535353;
  border: none;
  padding: 12px;
  font-family: Poppins;
  border-radius: 15px;
  cursor: pointer;
   transition: background 0.5s;

`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 22px;
  margin-bottom: 25px;
`;
const Label = styled.label`
  display: block;
  color: #194569;
  margin-bottom: 10px;
  font-size: 18px;
`;
const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const AdminEkle = () => {
    const [formData, setFormData] = useState({
        topic_id: "",
        title: "",
        description: "",
        video_url: null,
    });

    const token = localStorage.getItem('token');
    const [grades, setGrades] = useState([])
    const [grade, setGrade] = useState(null)
    const [lessons, setLessons] = useState([])
    const [lessonId, setLessonId] = useState(null)
    const [topics, setTopics] = useState([])
    const [topic, setTopic] = useState("")
    const [buttonState, setButtonState] = useState('idle'); // 'idle', 'loading', 'success'
    const [uploadProgress, setUploadProgress] = useState(0);

    const [units, setUnits] = useState([]);

    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await axios.get(`https://api.robark.com.tr/api/grades`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setGrades(response.data.grades);
                console.log(response.data.grades)
                if (!response.data) console.log('Sınıflar bulunamadı.');
            } catch (err) {
                console.log(`Grades API request failed: ${err.message}`);
            }
        };
        fetchGrades();
    }, []);


    useEffect(() => {
        if (grade === null) return; 
        
        console.log("oğğğğğğ",grade)
        const fetchGrade = async () => {
            try {
                const response = await axios.get(`https://api.robark.com.tr/api/level/${grade}/lessons`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setLessons(response.data.lessons);
                console.log("kkkk", response.data)
                if (!response.data) console.log('Sınıflar bulunamadı.');
            } catch (err) {
                console.log(`Grades API request failed: ${err.message}`);
            }
        };
        
        fetchGrade();
    }, [grade]);


    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await axios.get(
                    `https://api.robark.com.tr/api/lesson/${lessonId}/level/${grade}/topics`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                // Veriyi setUnits'e ekle
                setUnits(response.data.units);
                console.log("eeee", response.data.units)
                // topics verilerini setTopics ile ekle
                setTopics(response.data.units.flatMap((unit) => unit.topics));

            } catch (err) {
                console.log(`Units API request failed: ${err.message}`);
            }
        };

        if (lessonId && grade) fetchUnits();
    }, [lessonId, grade]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "video_url") {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonState('loading'); // Yükleme başlıyor

        try {
            const data = new FormData();
            data.append("topic_id", formData.topic_id);
            data.append("title", formData.title);
            data.append("description", formData.description);
            data.append("video_url", formData.video_url);

            const response = await axios.post('https://content.robark.com.tr/api/content', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });

            console.log(response.data);
            toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            setButtonState('success'); // Başarılı durumuna geçiş
            setTimeout(() => {
                setButtonState('idle'); // 2 saniye sonra varsayılan duruma dön
                setUploadProgress(0); // Yükleme ilerlemesini sıfırla
            }, 2000);

        } catch (error) {
            console.error(error);
            toast.error('Bir hata oluştu!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            setButtonState('idle'); // Hata durumunda butonu sıfırla
            setUploadProgress(0); // Yükleme ilerlemesini sıfırla
        }
    };







    return (
        <div>
            <div style={{ backgroundColor: "rgba(111, 133, 147, 0.1)", borderRadius: "20px", padding: "40px" }}>
                <StyledForm onSubmit={handleSubmit} className='my-3'>
                    <div style={{ backgroundColor: "#4e5c69", borderRadius: "20px", padding: "10px", marginBottom: "50px" }}> <Title>Yeni İçerik</Title></div>

                    <FormField>
                        <Label>Sınıf Düzeyi</Label>
                        <InputContainer>
                            <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
                            <Select value={grade} onChange={(e) => setGrade(e.target.value)} required>
                                <option value="">Seçiniz</option>
                                {grades.map((grade) => (
                                    <option key={grade.id} >
                                        {grade.level}
                                    </option>
                                ))}
                            </Select>
                        </InputContainer>
                    </FormField>
                    {grade ? (
                        <FormField>
                            <Label>Ders Adı</Label>
                            <InputContainer>
                                <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
                                <Select
                                    onChange={(e) => setLessonId(e.target.value)}
                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    {lessons.map((lesson) => (
                                        <option key={lesson.id} value={lesson.lesson_id}>
                                            {lesson.lesson_title}
                                        </option>
                                    ))}
                                </Select>
                            </InputContainer>
                        </FormField>
                    ) : (<div></div>)}
                    {lessonId ? (
                        <FormField>
                            <Label>Konu Adı</Label>
                            <InputContainer>
                                <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
                                <Select
                                    defaultValue=""
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            topic_id: Number(e.target.value),
                                        }))
                                    }
                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    {topics.length > 0 &&
                                        topics.map((topic) => (
                                            <option key={topic.id} value={topic.id}>
                                                {topic.title}
                                            </option>
                                        ))}
                                </Select>

                            </InputContainer>
                        </FormField>

                    ) : (<div></div>)}
                    <FormField>
                        <StyledLabel>Video Adı</StyledLabel>
                        <StyledInput
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}

                        />
                    </FormField>
                    <FormField>
                        <StyledLabel>Video Açıklaması</StyledLabel>
                        <StyledInput
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </FormField>
                    <FormField>
                        <StyledLabel>Video</StyledLabel>
                        <StyledInput
                            type="file"
                            name="video_url"
                            onChange={handleChange}
                            required
                        />
                    </FormField>
                    <FormField>
                        <SubmitButton type="submit">
                            {buttonState === 'loading' ? (
                                <div>
                                    {/* Yükleme Barı Arka Planı */}
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            backgroundColor: '#bbd5ff', // gray-200
                                            height: '20px', // h-5
                                            borderRadius: '10px', // rounded-full
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                height: '20px', // h-5
                                                backgroundColor: '#3b82f6', // blue-500
                                                borderRadius: '10px', // rounded-full
                                                width: `${uploadProgress}%`,
                                                transition: 'width 0.2s ease',
                                            }}
                                        />
                                    </div>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '5px', // mt-1
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '14px',
                                        }}
                                    >
                                        %{uploadProgress}
                                    </p>
                                </div>
                            ) : buttonState === 'success' ? (
                                'Eklendi'
                            ) : (
                                'Ekle'
                            )}
                        </SubmitButton>
                    </FormField>
                </StyledForm>
            </div>
        </div>
    );
};

export default AdminEkle;

