import React, { useEffect, useState } from 'react';
import Lessons from '../components/Lessons.jsx'; // Import the Lessons component
import Testler from '../components/Tests.jsx'; // Import the Testler component
import styled from 'styled-components';
import Sidebar from "../components/StudentDrawer.jsx";
import Homeworks from '../components/Homeworks.jsx';
import StudentDashboard from '../components/StudentDashboard.jsx';
import { logout } from '../../../redux/userSlice.js';
import Chatbot from '../components/Chatbot.jsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MyProfile from '../components/MyProfile.jsx';
import AnimatedListItem from '../dictionary/Dictionary.jsx';
import TurkishDictionary from '../dictionary/TurkishDictionary.jsx';
import ProverbsAndIdioms from '../dictionary/ProverbsAndIdioms.jsx';



const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Taşmayı engeller */
  font-family: "Poppins", sans-serif;
      --color: #9685d3;
    background-color: #7f6ac5;
    background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
        linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent);
    background-size: 60px 60px;

`;

const SidebarContainer = styled.div`
  flex-shrink: 0; /* Sidebar genişliğinin sabit kalmasını sağlar */
  transition: width 0.3s ease;
  background-color: #fcfcfc;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  flex-grow: 1; /* Sidebar dışında kalan tüm alanı kaplar */
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  transition: margin-left 0.3s, width 0.3s;
`;


const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState('anasayfa');
  const [drawerOpen, setDrawerOpen] = useState(true);


  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  const handleLogout = () => {
    dispatch(logout());
  };


  useEffect(() => {
    if (selectedItem === 'cikis') {
      handleLogout();
    }
  }, [selectedItem, handleLogout]); 


  const renderComponent = () => {
    switch (selectedItem) {
      case 'anasayfa':
        return <StudentDashboard/>;
      case 'iceriklerim':
        return;
      case 'odevler':
        return <Homeworks />;
      case 'testler':
        return <Testler />;
      case 'performans':
        return <Chatbot/>;
      case 'dersler':
        return <Lessons />;
      case 'profilim':
        return <MyProfile />;
      case 'turkcesozluk':
        return <TurkishDictionary />;
      case 'ingilizcesozluk':
        return <AnimatedListItem />;
      case 'atasozlerisozlugu':
        return <ProverbsAndIdioms />;
      default:
        return;
    }
  };

  return (
    <AppContainer>
      <Sidebar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <ContentContainer drawerOpen={drawerOpen}>
        {renderComponent()}
      </ContentContainer>


    </AppContainer>
  );
}

export default Dashboard;