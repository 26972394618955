import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Spline from '@splinetool/react-spline';
import marker from "../../../assets/images/marker.png"
import { RxPencil2 } from "react-icons/rx";
import { HiChevronRight } from "react-icons/hi2";
import { HiChevronDoubleRight } from "react-icons/hi2";
import { FaCircleChevronRight } from "react-icons/fa6";
import { Line } from 'react-chartjs-2';
import { data } from "../../admin/data/data2.js"
import NavigationBar from './NavigationBar.jsx';
import student from "../../../assets/images/student.png";
import { logout } from '../../../redux/userSlice.js';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';



const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 1024px) {
    gap: 20px;
    padding: 0 15px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0 10px;
  }
`;

const LeftDiv = styled.div`
  width: 65%;
  padding: 10px;

  @media (max-width: 1024px) {
    width: 60%;
    padding: 8px;
  }

  @media (max-width: 760px) {
    width: 100%;
    padding: 5px;
  }
`;

const RightDiv = styled.div`
  width: 35%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 40%;
    padding: 8px;
  }

  @media (max-width: 760px) {
    width: 100%;
    padding: 5px;
    margin-top: 20px;
  }
`;


const Title = styled.h6`
  font-family: "Poppins";
  font-weight: 600;
  color: #5a498f;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 18px;
    gap: 6px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;

const AssignmentContainer = styled.div`
  margin-top: 20px;
  padding: 15px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fcd200;

  @media (max-width: 1024px) {
    padding: 12px;
  }

  @media (max-width: 760px) {
    padding: 10px;
    margin-top: 15px;
  }
`;

const AssignmentTitle = styled.h3`
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  color: #5a498f; 
  font-size: 17px;
`;

const AssignmentDetail = styled.p`
  margin: 5px 0;
  font-family: "Poppins";
 font-size: 16px;
  color: #5a498f;
`;

const Styledh4 = styled.h4`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 5px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #34405e;
  font-weight: 500;
  font-size: 14px;
  font-family: Poppins;
  height: 45px;
  margin-top: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #fcd200;
    border-radius: 15px;
  }
  &.active {
    background-color: #fcd200;
    border-radius: 15px;

  }
`;

const StyledWrapper = styled.div`
    margin-left: 1%;
  .cssbuttons-io-button {
    background: #fce300;
    color: #483b72;
    font-weight: 500;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        font-family: Poppins;
    padding: 1em;
    padding-left: 1.3em;
    font-size: 17px;
    border-radius: 30px;
    border: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 3.6em;
    padding-right: 3.6em;
    cursor: pointer;
  }

  .cssbuttons-io-button .icon {
    background: #7f6ac5;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.4em;
    border-radius: 30px;
    right: 0.4em;
    transition: all 0.3s;
  }

  .cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
  }

  .cssbuttons-io-button .icon svg {
    width: 1.2em;
    transition: transform 0.3s;
    color: #ffffff;
  }

  .cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
  }

  .cssbuttons-io-button:active .icon {
    transform: scale(0.95);
  }`;

const StyledWrapper2 = styled.div`
margin-top: 20px;
button.youtube-button {
  font-family: inherit;
  font-size: 18px;
  background: linear-gradient(to bottom, #fcd200 0%, #fcd200 100%);
  color: white;
  padding: 0.5em 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  transition: all 0.3s;
}

button.youtube-button:hover {
  transform: translateY(-3px);
}

button.youtube-button:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

button.youtube-button span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.3s;
}

button.youtube-button svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}

button.youtube-button .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 0.5em;
  transition: all 0.3s;
}

button.youtube-button:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}

button.youtube-button:hover svg {
  transform: rotate(45deg);
}`;


const StyledWrapper3 = styled.div`
  .container {
    position: relative;
    width: 100%;
    margin-top:18px;
    padding: 27px;
    background: #fcfcfc;
    border-radius: 24px;
  }

  .container .skill-box {
    width: 100%;
    margin: 25px 0;
  }

  .skill-box .title {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: rgb(77, 77, 77);
  }

  .skill-box .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(236, 236, 236, 0.507);
  }

  .skill-bar .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 90%;
    border-radius: 6px;
    background:#5a498f;
    animation: progress 1s ease-in-out forwards;
    opacity: 0;
  }

  .skill-per.html {
    /*progreso de las diferentes lenguajes*/
    width: 70%;
    animation-delay: 0.1s;
  }

  .skill-per.scss {
    /*progreso de las diferentes lenguajes*/
    width: 80%;
    animation-delay: 0.1s;
  }

  .skill-per.Boostrap {
    /*progreso de las diferentes lenguajes*/
    width: 50%;
    animation-delay: 0.2s;
  }

  @keyframes progress {
    0% {
      width: 0;
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .skill-per .tooltip {
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 3px;
    background: rgb(226, 226, 226);
    z-index: 1;
  }

  .tooltip::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: rgb(226, 226, 226);
    transform: translateX(-50%) rotate(45deg);
  }`;

const StudentDashboard = () => {

  const user = useSelector((state) => state.user);
  const { userInfo, status, error } = user;

  console.log("userinfosuuuu ", user)
  console.log("statuss", status)
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (token) {
      fetch('https://api.robark.com.tr/api/get-student', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            dispatch(logout()); // Logout the user if the response status is 401
            return; // Exit early if unauthorized
          }

          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }

          return response.json(); // Proceed to parse the response if it's ok
        })
        .then((data) => {
          if (data) {
            // Handle the data if necessary
            console.log(data);
          }
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [dispatch]);

  if (status === 'loading') {
    return <div>Yükleniyor...</div>;
  }
  const assignments = [
    {
      id: 1,
      subject: "Matematik",
      topic: "Denklemler",
      dueDate: "01.03.2024",
      description: "İki bilinmeyenli denklemler konusunda 10 adet soru çözümü yapınız."
    },
    {
      id: 2,
      subject: "Türkçe",
      topic: "Cümle Yapısı",
      dueDate: "01.03.2024",
      description: "Cümle ögelerini inceleyerek 5 adet örnek cümle kurunuz."
    },

  ];

  return (
    <div className='px-5' style={{ width: "100%", }}>
      <NavigationBar item="Sayfam" />
      <Container>
        <LeftDiv>
          <div className=' h-52 bg-[#fcfcfc] rounded-3xl flex relative' style={{ border: " 1px solid #ddd" }}>
            <div className='p-7 ' style={{ display: "flex", flexDirection: "column", gap: "20%" }}>
              <div style={{ position: "relative", }}>
                <h6 style={{ fontFamily: "Rubik", fontSize: "20px", fontWeight: "400", color: "#5a498f" }}>   <br />Selam <span style={{ fontWeight: "500" }}>{userInfo?.student.first_name}</span>,  Bugün hangi konuyu keşfetmek istersin?</h6> </div>
              <StyledWrapper>
                <button className="cssbuttons-io-button">
                  Derslere Git
                  <div className="icon">
                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor" />
                    </svg>
                  </div>
                </button>
              </StyledWrapper>
            </div>
            <div>    <img src={student} style={{ height: "130%", width: "auto", position: "absolute", bottom: "-1%", right: "5%", zIndex: "111" }} />
            </div>
          </div>
          <div className='flex' >
            <div className='w-full bg-[#fcfcfc] mt-4  p-7 mr-5 rounded-3xl' style={{ border: " 1px solid #ddd" }}>
              <div className='flex justify-between align-middle mx-3 mb-10'>
                <Styledh4 style={{ color: "#5a498f" }}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chart-no-axes-combined"><path d="M12 16v5" /><path d="M16 14v7" /><path d="M20 10v11" /><path d="m22 3-8.646 8.646a.5.5 0 0 1-.708 0L9.354 8.354a.5.5 0 0 0-.707 0L2 15" /><path d="M4 18v3" /><path d="M8 14v7" /></svg> Performansım</Styledh4>
              </div>
              <Line
                data={data}
                options={{
                  scales: {
                    x: {
                      type: 'category'
                    }
                  }
                }}
              />
              <StyledButton className='px-7' >
                *Çözülen testlere göre hesaplanır.
              </StyledButton>
            </div>
          </div>
        </LeftDiv>
        <RightDiv>
          <div className=' bg-[#fcfcfc] rounded-3xl relative p-7' style={{ border: " 1px solid #ddd" }}>
            <div className='flex justify-between mb-3'>    <Title> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-notebook"><path d="M2 6h4" /><path d="M2 10h4" /><path d="M2 14h4" /><path d="M2 18h4" /><rect width="16" height="20" x="4" y="2" rx="2" /><path d="M16 2v20" /></svg>Ödevlerim  </Title>  <span style={{ fontSize: "14px", color: "#5a498f", cursor: "pointer" }}>Hepsini gör<HiChevronDoubleRight style={{ width: "15px", height: "15px", color: "#5a498f", cursor: "pointer", display: "inline" }} /> </span> </div>
            {assignments.map(assignment => (
              <AssignmentContainer key={assignment.id}>
                <div>
                  <div style={{ backgroundColor: "#ffffff", borderRadius: "50%", width: "min-content", padding: "13px" }} ><RxPencil2 style={{ width: "25px", height: "25px", color: "#5a498f", cursor: "pointer", display: "inline" }} /></div>
                </div>
                <div>
                  <AssignmentTitle>{assignment.subject} - {assignment.topic}</AssignmentTitle>
                  <AssignmentDetail>{assignment.dueDate}</AssignmentDetail>
                </div>
                <div><HiChevronRight style={{ width: "20px", height: "20px", color: "#5a498f", display: "inline" }} /></div>
              </AssignmentContainer>
            ))}
          </div>
          <div className=' bg-[#fcfcfc] rounded-3xl relative p-7 mt-4  ' style={{ border: " 1px solid #ddd" }}><Title> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-play"><polygon points="6 3 20 12 6 21 6 3" /></svg>Kaldığın yerden devam et   </Title> <div className='flex gap-8' style={{ alignItems: "center" }}> <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}><h6 style={{ fontSize: "13px", color: "#979797", marginTop: "15px" }}>Matematik</h6> <h6 style={{ fontSize: "17px", fontWeight: "600", color: "#4d4d4d" }}> Köklü Sayılar</h6> </div>    <StyledWrapper2>
            <button className="youtube-button">
              <div className="svg-wrapper-1">
                <div className="svg-wrapper">
                  <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" fill="currentColor" />
                  </svg>
                </div>
              </div>
              <span>İzle</span>
            </button>
          </StyledWrapper2></div>
          </div>
          <StyledWrapper3>
            <div className="container">
              <Title> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-loader-circle"><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg>Derslerde İlerleme Oranın   </Title>
              <div className="skill-box">
                <span className="title">Türkçe</span>
                <div className="skill-bar">
                  <span className="skill-per html">
                    <span className="tooltip">70%</span>
                  </span>
                </div>
              </div>
              <div className="skill-box">
                <span className="title">Matematik</span>
                <div className="skill-bar">
                  <span className="skill-per scss">
                    <span className="tooltip">80%</span>
                  </span>
                </div>
              </div>
              <div className="skill-box">
                <span className="title">Fen Bilimleri</span>
                <div className="skill-bar">
                  <span className="skill-per Boostrap">
                    <span className="tooltip">50%</span>
                  </span>
                </div>
              </div>
              <div className="skill-box">
                <span className="title">T.C. İnkılap Tarihi</span>
                <div className="skill-bar">
                  <span className="skill-per scss">
                    <span className="tooltip">20%</span>
                  </span>
                </div>
              </div>
              <div className="skill-box">
                <span className="title">Din Kltr ve Ahlk Bilgisi</span>
                <div className="skill-bar">
                  <span className="skill-per html">
                    <span className="tooltip">30%</span>
                  </span>
                </div>
              </div>
              <div className="skill-box">
                <span className="title">İngilizce</span>
                <div className="skill-bar">
                  <span className="skill-per scss">
                    <span className="tooltip">80%</span>
                  </span>
                </div>
              </div>
            </div>
          </StyledWrapper3>
        </RightDiv>
      </Container>
    </div>
  );
};

export default StudentDashboard;
