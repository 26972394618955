import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { CiCircleChevDown } from "react-icons/ci";

const Title = styled.h4`
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
`;

const StyledForm = styled.form`
  width: 50%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  height: max-content;
  box-shadow: rgba(113, 113, 113, 0.05) 0px 0px 0px 1px;
  padding: 60px;
`;

const StyledLabel = styled.label`
  color: #555;
  font-family: "Raleway";
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;
`;


const FormField = styled.div`
  margin-bottom: 25px;


`;


const InputContainer = styled.div`
  display: flex;
  padding: 22px;
  position: relative;
  margin-bottom: 25px;
  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`;
const Label = styled.label`
  display: block;
  font-family: Poppins;
  color: #436586;
  margin-bottom: 10px;
  font-size: 18px;
`;

const Label2 = styled.label`
  font-family: Poppins;
  color: #436586;
  margin-bottom: 10px;
  font-size: 18px;
`;

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  appearance: none; /* Native ok simgesini gizler */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;

  &:focus {
    border-bottom: 1px solid #666;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Simgeye tıklanmasını engeller */
  color: #436586;
`;



const StyledWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
  button {
   padding: 15px 25px;
   border: unset;
   border-radius: 15px;
   color: #436586;
   z-index: 1;
   background: #e8e8e8;
   position: relative;
   font-weight: 1000;
   font-size: 17px;
   width: 100%;
   -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;   
   transition: all 250ms;
   overflow: hidden;
  }

  button::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 0;
   border-radius: 15px;
   background-color: #436586;
   z-index: -1;
   -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
   transition: all 250ms
  }

  button:hover {
   color: #e8e8e8;
  }

  button:hover::before {
   width: 100%;
  }`;


const StyledWrapper2 = styled.div`
  .button {
    --main-focus: #2d8cf0;
    --font-color: #ffffff;
    --bg-color-sub: #436586;
    --bg-color: #5a80a7;
    --main-color: #dedede;
    position: relative;
    width: 170px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    background-color: var(--bg-color);
    border-radius: 10px;
    overflow: hidden;
  }

  .button, .button__icon, .button__text {
    transition: all 0.3s;
  }

  .button .button__text {
    transform: translateX(25px);
    color: var(--font-color);
    font-weight: 600;
  }

  .button .button__icon {
    position: absolute;
    transform: translateX(130px);
    height: 100%;
    width: 39px;
    background-color: var(--bg-color-sub);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button .svg {
    width: 20px;
    stroke: var(--main-color);
  }

  .button:hover {
    background: var(--bg-color);
  }

  .button:hover .button__text {
    color: transparent;
  }

  .button:hover .button__icon {
    width: 148px;
    transform: translateX(0);
  }

  .button:active {
    transform: translate(3px, 3px);
    box-shadow: 0px 0px var(--main-color);
}`;

const StyledInput = styled.input`
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #436586;
  }
`;
const CustomSelect = ({ value, onChange, children }) => {
    return (
        <SelectContainer>
            <Select value={value} onChange={onChange}>
                {children}
            </Select>
            <IconWrapper>
                <CiCircleChevDown size={30} />
            </IconWrapper>
        </SelectContainer>
    );
};

const MarkerEkle = () => {
    const [formData, setFormData] = useState({
        content_id: null,
        markers: {},
    });

    const token = localStorage.getItem('token');
    const [grades, setGrades] = useState([])
    const [grade, setGrade] = useState(null)
    const [lessons, setLessons] = useState([])
    const [lessonId, setLessonId] = useState(null)
    const [topics, setTopics] = useState([])
    const [topicId, setTopicId] = useState("")
    const [unitId, setUnitId] = useState(null);
    const [contents, setContents] = useState([]);
    const [contentId, setContentId] = useState(null);

    const [units, setUnits] = useState([]);
    const [markers, setMarkers] = useState([
        { time: "", end_time: "", label: "" }
    ]);

    const updatedFormData = {
        content_id: contentId, // Seçilen içerik ID'si
        markers: markers       // Marker state'i
    };
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await axios.get(`https://api.robark.com.tr/api/grades`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setGrades(response.data.grades);
                if (!response.data) console.log('Sınıflar bulunamadı.');
            } catch (err) {
                console.log(`Grades API request failed: ${err.message}`);
            }
        };
        fetchGrades();
    }, []);

    const handleAddMarker = () => {
        setMarkers([...markers, { time: "", end_time: "", label: "" }]);
    };


    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const response = await axios.get(`https://api.robark.com.tr/api/level/${grade}/lessons`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setLessons(response.data.lessons);

                if (!response.data) console.log('Sınıflar bulunamadı.');
            } catch (err) {
                console.log(`Grades API request failed: ${err.message}`);
            }
        };
        fetchGrades();
    }, [grade]);






    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await axios.get(
                    `https://api.robark.com.tr/api/lesson/${lessonId}/level/${grade}/topics`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                // Veriyi setUnits'e ekle
                setUnits(response.data.units);

                // topics verilerini setTopics ile ekle
                setTopics(response.data.units.flatMap((unit) => unit.topics));

            } catch (err) {
                console.log(`Units API request failed: ${err.message}`);
            }
        };

        if (lessonId && grade) fetchUnits();
    }, [lessonId, grade]);


    useEffect(() => {
        const fetchContents = async () => {
            try {
                const response = await axios.get(
                    `https://api.robark.com.tr/api/lesson/${lessonId}/level/${grade}/unit/${unitId}/topic/${topicId}/contents`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setContents(response.data.contents);

            } catch (err) {
                console.log(`Units API request failed: ${err.message}`);
            }
        };

        if (lessonId && grade && topicId) fetchContents();
    }, [lessonId, grade, topicId]);


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "video_url") {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    // Form submit işlemi
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("asasas", formData)
        try {

            const response = await axios.post(
                "https://content.robark.com.tr/api/add-marker",
                updatedFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            toast.error("Bir hata oluştu!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };


    const handleTimeInput = (value) => {
        // Sadece sayı içermesine izin ver
        const cleaned = value.replace(/\D/g, "");

        // İlk iki sayıdan sonra otomatik ":"
        let formatted = "";
        if (cleaned.length > 0) {
            formatted += cleaned.substring(0, 2);
        }
        if (cleaned.length > 2) {
            formatted += ":" + cleaned.substring(2, 4);
        }

        return formatted;
    };

    const handleMarkerChange = (index, field, value) => {
        const updatedMarkers = [...markers];

        if (field === "time" || field === "end_time") {
            // Zaman giriş formatını düzenle
            const cleaned = value.replace(/\D/g, ""); // Sadece rakamları al
            let formatted = "";
            if (cleaned.length > 0) {
                formatted += cleaned.substring(0, 2);
            }
            if (cleaned.length > 2) {
                formatted += ":" + cleaned.substring(2, 4);
            }
            updatedMarkers[index][field] = formatted; // Formatlanmış değeri ata
        } else {
            updatedMarkers[index][field] = value;
        }

        setMarkers(updatedMarkers);
    };



    return (
        <div>
            <div style={{ backgroundColor: "rgba(111, 133, 147, 0.1)", borderRadius: "20px", padding: "40px" }}>
                <StyledForm onSubmit={handleSubmit} className='my-3'>
                    <div style={{ backgroundColor: "#436586", borderRadius: "20px", padding: "10px", marginBottom: "50px" }}> <Title>Yeni Marker</Title></div>

                    <FormField>
                        <Label>Sınıf Düzeyi</Label>
                        <InputContainer>
                            <CustomSelect value={grade} onChange={
                                (e) => {
                                    setGrade(parseInt(e.target.value));
                                    setTopicId(null);
                                    setLessonId(null);
                                    setUnitId(null);
                                    setContentId(null);
                                }} required>
                                <option value="">Seçiniz</option>
                                {grades.map((grade) => (
                                    <option key={grade.id} >
                                        {grade.level}
                                    </option>
                                ))}
                            </CustomSelect >
                        </InputContainer>
                    </FormField>
                    {grade ? (
                        <FormField>
                            <Label>Ders Adı</Label>
                            <InputContainer>
                                <CustomSelect
                                    defaultValue=""
                                    onChange={(e) => setLessonId(e.target.value)}
                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    {lessons.map((lesson) => (
                                        <option key={lesson.id} value={lesson.lesson_id}>
                                            {lesson.lesson_title}
                                        </option>
                                    ))}
                                </CustomSelect >
                            </InputContainer>
                        </FormField>
                    ) : (<div></div>)}
                    {lessonId ? (
                        <FormField>
                            <Label>Konu Adı</Label>
                            <InputContainer>
                                <CustomSelect
                                    defaultValue=""
                                    onChange={(e) => {
                                        const selectedTopicId = e.target.value;
                                        setTopicId(selectedTopicId);

                                        // Seçilen topic ile ilgili unit_id'yi bulma
                                        const selectedUnit = units.find(unit =>
                                            unit.topics.some(topic => topic.id.toString() === selectedTopicId)
                                        );
                                        if (selectedUnit) {
                                            setUnitId(selectedUnit.id);
                                        } else {
                                            setUnitId(null); // Unit bulunamazsa boş bırak
                                        }
                                    }}
                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    {topics.length > 0 &&
                                        topics.map((topic) => (
                                            <option key={topic.id} value={topic.id}>
                                                {topic.title}
                                            </option>
                                        ))}
                                </CustomSelect >
                            </InputContainer>
                        </FormField>

                    ) : (<div></div>)}
                    {unitId ? (
                        <FormField>
                            <Label>İçerikler</Label>
                            <InputContainer>
                                <CustomSelect
                                    defaultValue=""
                                    onChange={(e) => {
                                        setContentId(e.target.value);
                                    }}

                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    {contents.length > 0 &&
                                        contents.map((content) => (
                                            <option key={content.id} value={content.id}>
                                                {content.title}
                                            </option>
                                        ))}
                                </CustomSelect >

                            </InputContainer>
                        </FormField>

                    ) : (<div></div>)}

                    {contentId ? (
                        <FormField>
                            <Label>Marker Ekle</Label>
                            <InputContainer>
                                {markers.map((marker, index) => (
                                    <div key={index} style={{backgroundColor:"#f8f8f8",marginBottom:"5px",borderRadius:"20px"}}>
                                        <InputContainer>
                                            <Label2>
                                                Time : 
                                                <StyledInput
                                                    type="text"
                                                    value={marker.time}
                                                    onChange={(e) => handleMarkerChange(index, "time", e.target.value)}
                                                    placeholder="00:00"
                                                    maxLength="5"
                                                    required
                                                />
                                            </Label2>
                                            <Label2>
                                                End Time : 
                                                <StyledInput
                                                    type="text"
                                                    value={marker.end_time}
                                                    onChange={(e) => handleMarkerChange(index, "end_time", e.target.value)}
                                                    placeholder="00:00"
                                                    maxLength="5"
                                                    required
                                                />
                                            </Label2>
                                            <Label2>
                                                Label : 
                                                <StyledInput
                                                    type="text"
                                                    value={marker.label}
                                                    onChange={(e) => handleMarkerChange(index, "label", e.target.value)}
                                                    placeholder="Açıklama"
                                                    required
                                                />
                                            </Label2>
                                        </InputContainer>
                                    </div>
                                ))}
                                <button title="Add New" className="group cursor-pointer outline-none hover:rotate-90 duration-300" onClick={handleAddMarker}>
      <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" className="stroke-zinc-400 fill-none  group-active:stroke-zinc-200 group-active:fill-zinc-600 group-active:duration-0 duration-300">
        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" strokeWidth="1.5" />
        <path d="M8 12H16" strokeWidth="1.5" />
        <path d="M12 16V8" strokeWidth="1.5" />
      </svg>
    </button>

                            </InputContainer>

                        </FormField>
                    ) : (<div></div>)}





                    <StyledWrapper type="submit">
                        <button> Kaydet
                        </button>
                    </StyledWrapper>
                </StyledForm>
            </div>
        </div>
    );
};

export default MarkerEkle;

