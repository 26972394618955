import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoMdLogIn } from "react-icons/io";
import student from "../assets/images/studentt.png";
import manager from "../assets/images/manager.png";
import teacher from "../assets/images/teacherr.png";
import logo from "../assets/images/logo.png";

import { AiOutlineLogin } from "react-icons/ai";
import { RiRobot3Fill } from "react-icons/ri";
import { MdOutlineLaptopMac } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from 'react-redux';
import { PiStudent } from "react-icons/pi";
import { HiChevronDown } from "react-icons/hi2";
import { Menu, MenuItem, IconButton } from '@mui/material';
import { logout } from '../redux/userSlice';
import { IoMdArrowDropdown } from "react-icons/io";

//koyu mavi #384152
//mavi :#d6e2f2
//pembe:#e485b3
//mavi-mor:#b2b1ee
// turuncu : #f36f25
//pembe: #fbebd2
//canlı pembe #ffc3be

const Containerdiv = styled.div`
  box-shadow: none;
  padding-top: 20px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 1024px) {
    padding:0;
  }
`;

const StyledDiv = styled.div`
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`;

const Styledh6 = styled.h6`
  font-weight: 500;
  font-size: 17px;
  font-family: Poppins;
  color: #363146;
  display: inline;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease; /* Renk geçişi için transition ekledik */
  text-decoration: none;

 
`;


const Buttons = styled.div`
  cursor: pointer;
  border-radius: 35px;
  background-color: #fcd200;
  visibility: hidden;
  padding: 16px 25px;
  display: flex;
  box-shadow: rgba(191, 191, 191, 0.16) 0px 1px 4px;
  align-items: center;
  gap: 15px;
  transition: all 0.5s;
  &:hover svg {
    color: #fcd200;
  }

  &:hover {
    background-color: #7f6ac5;
    color: white;

  
    ${Styledh6} {
      color: #fcd200;
    }
  }
`;
const StyledIcon2 = styled(MdOutlineLaptopMac)`
width: 20px; 
height: 20px; 
margin-left: 5px;
color: #363146;
background-color: transparent;
display: inline;
`;

const DialogContentCentered = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Modalin yüksekliğinin %80'i kadar boşluk bırakır */
`;





const StyledWrapper = styled.div`
  
  @media (max-width: 1024px) {
    transform: scale(0.8);
  }
  @media (max-width: 760px) {
    transform: scale(0.6);
  }
  .swipe {
    position: relative;
    background-color: #ffe600;
    width: 170px;
    height: 60px;
    font-weight: 500;
  font-size: 16px;
  font-family: Poppins;
    color: #324B4C;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-left: 40px;
    cursor: pointer;
    transition: 0.35s ease;
  }

  .swipe:hover {
    padding-left: 0;
    padding-right: 40px;
    color: #324B4C;
  }

  .container {
    position: absolute;
    left: 5px;
    width: 50px;
    height: 50px;
    background: linear-gradient(30deg, #8166e4 20%, #8166e4 80%);
    border-radius: 50%;
    transition: 0.35s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f4f7;
  }

  .swipe:hover .container {
    left: calc(100% - 55px);
  }`;


const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleModalOpen = () => {
    if (userInfo) {
      navigate('/student');
    } else {
      setIsOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };
  console.log(userInfo)
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch(); // Redux dispatch fonksiyonunu al

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/');
  };

  return (
    <Containerdiv >
      <div className='flex  z-10'>
        <Buttons>
          <Styledh6>Paketler</Styledh6>
          <StyledIcon2/>
        </Buttons>
      </div>
      <StyledDiv>
        <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '22px', position: 'relative', lineHeight: '90%' }}>
        <img src={logo} style={{width:"150px"}} />
        </div>
      </StyledDiv>

<div className="flex pr-20 z-10">
        <StyledWrapper onClick={handleModalOpen}>
      <a className="swipe">Giriş Yap <span className="container"><svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="transparent" /><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="#fcd200" /></svg></span> </a>
    </StyledWrapper>

      </div>

      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleModalClose}
      >
        <DialogContent>
          <DialogActions>
            <button onClick={handleModalClose} color="primary">
              <CgClose style={{ width: '30px', height: '30px', color: "#8b89a6", }} />

            </button>
          </DialogActions>
          <DialogContentCentered>
            <div className='flex justify-center gap-28 '>
              <div> <div onClick={() => navigate("/teacher/login")} style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={teacher} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Öğretmenim</p>   </div>
              <div> <div onClick={() => navigate("/student/login")} style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={student} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Öğrenciyim</p>   </div>
              <div> <div onClick={() => navigate("/staff/login")}  style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={manager} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Yetkiliyim</p>   </div>
            </div>
          </DialogContentCentered>
        </DialogContent>

      </Dialog>

    </Containerdiv>
  );
};




export default Navbar;
