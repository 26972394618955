import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { TfiClose } from "react-icons/tfi";
import turkiye from "../../../assets/images/turkiye.png"
import { LuDot } from "react-icons/lu";
import { IoMdArrowDropright } from "react-icons/io";
import { useSelector } from "react-redux";

const ChatbotContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 40px;
  height: 600px;
  background-image: linear-gradient(to top, #f9fafc 0%, #eef1f5 100%);
  z-index: 999;
  @media screen and (min-width: 640px) {
    max-width: 420px;
    right: 80px;
    top: auto;
  }

  &.chatbot--closed {
    top: auto;
    width: 100%;
  }
`;
const ChatbotHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
  }

  p {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;
const Styledh6 = styled.h6`
    font-weight: 400;
    margin-top: 5px;
    font-size: 15px;
    font-family: Poppins;
    color: #4c5565;
    text-decoration: none;
    text-align: center;
`;
const ListItem = styled.li`
  position: relative;
  list-style: none;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  &:hover {
    width: 180px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    opacity: 0;
    transition: 0.5s;
  }
  &:hover::before {
    opacity: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    transition: 0.5s;
    filter: blur(15px);
    z-index: -1;
    opacity: 0;
  }
  &:hover::after {
    opacity: 0.5;
  }
`;
const Title = styled.span`
  position: absolute;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
  ${ListItem}:hover & {
    transform: scale(1);
    transition-delay: 0.25s;
  }
`;
const EntryField = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  border-radius: 10px;
  margin: 25px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-top: 1px solid #ecececdd;
  height: 15%;

  &:last-child {
  height: 40%;
  padding: 0;
  padding-left: 20px;
  }
  input {
    flex-grow: 1;
    border: none;
    font-weight: 300;
    font-family: Poppins;
    font-size: 17px;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
  color: #5a5a5a; /* İstediğiniz rengi buraya yazın */
}
  svg {
    fill: #202631;
    width: 24px;
    cursor: pointer;
  }
`;
const Lang = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  position: relative;
  border-radius: 10px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 17%;
  margin-right: 25px;
  margin-left: 25px;

`;
const Langs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 10px;

`;
const TurkishDictionary = ({ isTurkishChatbotVisible, setIsTurkihChatbotVisible }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const messageEndRef = useRef(null);
  const [targetLanguage, setTargetLanguage] = useState("Türkçe");
  const [showTargetDropdown, setShowTargetDropdown] = useState(false);
  const token = useSelector((state) => state.user.token);   



  const handleTargetLanguageChange = (lang) => {
    setTargetLanguage(lang);
    setShowTargetDropdown(false);
  };

  const [messages, setMessages] = useState([
    {
      isAI: true,
      text: "Anlamı",
    },
  ]);
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleToggleChatbot = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseChatbot = () => {
    setIsTurkihChatbotVisible(false);
    setIsOpen(false);
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setUserInput(newValue);

    if (newValue.trim()) {
      setMessages([{ isAI: false, text: newValue }]);
    } else {
      setMessages([]);
    }
  };

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    setMessages((prev) => [...prev, { isAI: false, text: userInput }]);

    try {
      const response = await axios.get(
        `https://mars.robark.com.tr/dictionary?word=${userInput}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",

          },
        }
      );

      const meanings = response.data.means;
      if (meanings && meanings.length > 0) {
        setMessages((prev) => [
          ...prev,
          { isAI: true, text: meanings },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { isAI: true, text: ["Anlam bulunamadı."] },
        ]);
      }
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { isAI: true, text: ["Kelimenin anlamı getirilemedi."] },
      ]);
    }
  };




  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userInput.trim()) {
        handleSendMessage();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [userInput]);

  return (
    <>
      {isTurkishChatbotVisible && (
        <ChatbotContainer className={isOpen ? "" : "chatbot--closed"}>
          <ChatbotHeader onClick={handleToggleChatbot}>
            <p style={{ fontFamily: "Poppins", fontSize: "19px", fontWeight: "300" }}>
              Robark Sözlük
            </p>
            <div
              style={{ position: "absolute", top: "42%", right: "8%" }}
              className="icon-close"
              viewBox="0 0 32 32"
              isOpen={isOpen}
              onClick={handleCloseChatbot}>
              <TfiClose style={{ width: "15px", height: "15px", }} />
            </div>
          </ChatbotHeader>
          <Lang>

            <Langs style={{ position: "relative" }} >
              <img
                src={turkiye}
                style={{ width: "50px" }}
                alt={"Türkçe"}
              />
              <Styledh6>Türkçe Sözlük</Styledh6>
              {showTargetDropdown && (
                <div style={{ position: "absolute", top: "100%", left: 0, background: "#fff", boxShadow: "0px 2px 4px rgba(0,0,0,0.1)", borderRadius: "10px", zIndex: 10, padding: "10px 20px", gap: "10px", display: "flex", flexDirection: "column" }}>
                  <div onClick={() => handleTargetLanguageChange("Türkçe")}>Türkçe</div>
                </div>
              )}
            </Langs>
          </Lang>
          <EntryField>
            <input
              type="text"
              placeholder="Kelime girin"
              value={userInput}
              onChange={handleInputChange}
            />
          </EntryField>
          <EntryField>
  <div style={{ width: "100%", overflowY: "auto", maxHeight: "100%" }}>
    {messages
      .filter((message) => message.isAI)
      .map((message, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column", 
            marginBottom: "10px", 
          }}
        >
          {Array.isArray(message.text) ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column", 
                gap: "10px",
              }}
            >
              {message.text.map((meaning, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px", 
                  }}
                >
                  <LuDot style={{ width: "20px", height: "20px",flexShrink:"0" }} />
                  <span style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: 300 }}>
                    {meaning}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <span style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: 400 }}>
              {message.text}
            </span>
          )}
        </div>
      ))}
    <div ref={messageEndRef} />
  </div>
</EntryField>

        </ChatbotContainer>
      )}
    </>
  );
};

export default TurkishDictionary;
