import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled components remain the same...
const FormContainer = styled.form`
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    max-width: 600px;
  margin: 0 auto;
`;

const QuestionWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

const InputLabel = styled.label`
  font-weight: bold;
  color: #f16896;
  margin-bottom: 5px;
  display: block;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #f16896;
  }
`;

const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: #fff;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  appearance: none;
  transition: border-color 0.3s ease;
`;

const SubmitButton = styled.button`
  background-color: #5c6bc0;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3f51b5;
  }
`;

const AddQuestionButton = styled.button`
  background-color: #f16896;
  color: white;
  border: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 50px;
  margin: auto;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #f16896;
  }
`;

const SaveAllButton = styled.button`
  background-color: #2196f3;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1976d2;
  }
`;

const OptionsContainer = styled.div`
  margin-top: 20px;
`;

const OptionContainer = styled.div`
  margin-bottom: 10px;
`;

const ToggleButton = styled.button`
  background-color: #ffffff;
  color: #242323;
  padding: 8px 16px;
  margin: 2px;
  border: 1px solid #a1a1a1;
  border-radius: 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f16896;
    color: white;
    border:none;
  }
`;

const QuestionForm = ({ time, videoId,setAllQuestions }) => {
    const [questions, setQuestions] = useState([
        {
            duration: time,
            interactive_multiple_questions: [
                {
                    question: "",
                    correct_option_id: 1,
                    options: [
                        { option_id: 1, text: "" },
                        { option_id: 2, text: "" },
                        { option_id: 3, text: "" },
                        { option_id: 4, text: "" },
                    ],
                },
            ],
        },
    ]);
    const [isFormVisible, setIsFormVisible] = useState(true);
    useEffect(() => {
      setAllQuestions(prevQuestions => [...prevQuestions, ...questions]);
    }, [questions]);
  
    const handleAddQuestion = () => {
        setQuestions([
            ...questions,
            {
                duration: time,
                interactive_multiple_questions: [
                    {
                        question: "",
                        correct_option_id: 1,
                        options: [
                            { option_id: 1, text: "" },
                            { option_id: 2, text: "" },
                            { option_id: 3, text: "" },
                            { option_id: 4, text: "" },
                        ],
                    },
                ],
            },
        ]);
    };

    const handleSubmit = () => {
        console.log("Sorular kaydedildi:", questions);
        setIsFormVisible(false);
    };

    const handleOptionChange = (questionIndex, optionIndex, e) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].interactive_multiple_questions[0].options[
            optionIndex
        ].text = e.target.value;
        setQuestions(updatedQuestions);
    };

    const handleCorrectOptionChange = (questionIndex, e) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].interactive_multiple_questions[0].correct_option_id = Number(e.target.value);
        setQuestions(updatedQuestions);
    };

    const handleQuestionChange = (questionIndex, e) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].interactive_multiple_questions[0].question = e.target.value;
        setQuestions(updatedQuestions);
    };
    const token = localStorage.getItem('token');

    const handleSaveAll = async () => {
      try {
          // Boş soru kontrolü
          const hasEmptyQuestions = questions.some(q => 
              !q.interactive_multiple_questions[0].question || 
              q.interactive_multiple_questions[0].options.some(opt => !opt.text)
          );
  
          if (hasEmptyQuestions) {
              toast.error('Lütfen tüm soruları ve seçenekleri doldurun!', {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
              return;
          }
  
          const formattedQuestions = questions.map(q => ({
              duration: q.duration,
              interactive_multiple_questions: q.interactive_multiple_questions.map(question => ({
                  question: question.question,
                  correct_option_id: question.correct_option_id,
                  options: question.options
              }))
          }));
  
          const requestBody = {
              tests: [{
                  test_type: 1,
                  video_id: videoId,
                  durations: formattedQuestions
              }]
          };
  
          console.log('Gönderilen veri:', JSON.stringify(requestBody, null, 2));
  
          const response = await fetch('https://api.robark.com.tr/api/interactive-multiple-questions', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify(requestBody)
          });
  
          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'API isteği başarısız oldu');
          }
  
          const data = await response.json();
          console.log('Başarıyla kaydedildi:', data);
          setIsFormVisible(false);
          
          // Başarılı toast mesajı
          toast.success('Sorular başarıyla kaydedildi!', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
  
      } catch (error) {
          console.error('Hata:', error);
          // Hata toast mesajı
          toast.error(error.message || 'Bir hata oluştu!', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
      }
  };

    return (
        <div>
            {isFormVisible ? (
                <FormContainer onSubmit={(e) => e.preventDefault()}>
                    {questions.map((q, questionIndex) => (
                        <QuestionWrapper key={questionIndex}>
                            <InputContainer>
                                <InputLabel>Soru {questionIndex + 1}</InputLabel>
                                <InputField
                                    type="text"
                                    value={q.interactive_multiple_questions[0].question}
                                    onChange={(e) => handleQuestionChange(questionIndex, e)}
                                    placeholder="Soru girin"
                                    required
                                />
                            </InputContainer>

                            <OptionsContainer>
                                <InputLabel>Seçenekler</InputLabel>
                                {q.interactive_multiple_questions[0].options.map((option, optionIndex) => (
                                    <OptionContainer key={option.option_id}>
                                        <InputField
                                            type="text"
                                            value={option.text}
                                            onChange={(e) => handleOptionChange(questionIndex, optionIndex, e)}
                                            placeholder={`Seçenek ${optionIndex + 1}`}
                                            required
                                        />
                                    </OptionContainer>
                                ))}
                            </OptionsContainer>

                            <InputContainer>
                                <InputLabel>Doğru Seçenek</InputLabel>
                                <SelectField
                                    value={q.interactive_multiple_questions[0].correct_option_id}
                                    onChange={(e) => handleCorrectOptionChange(questionIndex, e)}
                                    required
                                >
                                    {q.interactive_multiple_questions[0].options.map((option) => (
                                        <option key={option.option_id} value={option.option_id}>
                                            {option.text || `Seçenek ${option.option_id}`}
                                        </option>
                                    ))}
                                </SelectField>
                            </InputContainer>
                            
                        </QuestionWrapper>
                    ))}

                    <AddQuestionButton type="button" onClick={handleAddQuestion}>
                        Soru Ekle
                    </AddQuestionButton>

                    <SubmitButton type="button" onClick={handleSubmit}>
                        {time} için soruyu/soruları kaydet
                    </SubmitButton>
                </FormContainer>
            ) : (
                <ToggleButton onClick={() => setIsFormVisible(true)}>
                    düzenle
                </ToggleButton>
            )}
   
        </div>
    );
};

export default QuestionForm;