import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import pp from "../assets/images/video_thumbnail.png";

import Button2 from './Button';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  width: 100vw;
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    div{
      font-size: 45px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
    div{
      font-size: 30px;
    }
  }
`;

const RightSection = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
    order: 2;
  }

  img {
    width: 700px;
    height: 500px;
    border-radius: 30px;
    object-fit: cover;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      max-height: 500px;
    }
  }
`;

// StyledWrapper ve StyledWrapper2'yi aynen koruyalım
const StyledWrapper = styled.div`

@media (max-width: 1024px) {
   transform:scale(0.9);
  }
  @media (max-width: 420px) {
   transform:scale(0.7);
  }
  .button {
    cursor: pointer;
    border: none;
    background: #8166e4;
    color: #ffe600;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    transition: background 300ms, transform 200ms;
    font-weight: 600;
  }

  .button__text {
    position: absolute;
    inset: 0;
    font-family: Poppins;
    animation: text-rotation 8s linear infinite;
    color: #ffe600;

    > span {
      position: absolute;
      transform: rotate(calc(19deg * var(--index)));
      inset: 7px;
    }
  }

  .button__circle {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: #fffae1;
    color: #ffe600;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button__icon--copy {
    position: absolute;
    transform: translate(-150%, 150%);
  }

  .button:hover {
    background: #7856f7;
    transform: scale(1.05);
    color: #ebe4ff;
  }
  .button:hover .button__icon {
    color: #ffa600;
  }
  .button:hover .button__text {
    color: #ebe4ff;
  }
  .button:hover .button__icon:first-child {
    transition: transform 0.3s ease-in-out;
    transform: translate(150%, -150%);
  }

  .button:hover .button__icon--copy {
    transition: transform 0.3s ease-in-out 0.1s;
    transform: translate(0);
  }

  @keyframes text-rotation {
    to {
      rotate: 360deg;
    }
  }
`;

const StyledWrapper2 = styled.div`
  position: absolute;
  top: 43%;
  left: 33%;

  @media (max-width: 1024px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 
  }

  @media (max-width: 1024px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .button{
      transform: scale(0.7);
    }
  }

  .button {
    display: flex;
    justify-content: center;
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #ffe600;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
    animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse;
    align-items: center;
    border: 0;
  }

  .button:is(:hover, :focus) {
    transform: scale(1.2);
  }

  @keyframes pulse {
    100% {
      box-shadow: 0 0 0 45px rgba(193,244,246,0);
    }
  }
`;

const Description = styled.p`
  font-size: 20px;
  font-family: "Raleway";
  font-weight: 500;
  color: white;
  margin-top: 90px;
  text-align: center;
  padding-right: 10px;

  @media (max-width: 1024px) {
    margin-top: 60px;
    padding-right: 0;
    font-size: 18px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  margin-top: 40px;

  @media (max-width: 1024px) {
    gap: 50px;
  }
  @media (max-width: 420px) {
    gap: 10px;
  }
`;

const FirstSection = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    if (animationComplete) {
      const cursorElement = document.querySelector('.Typewriter__cursor');
      if (cursorElement) {
        cursorElement.style.display = 'none';
      }
    }
  }, [animationComplete]);

  return (
    <AppContainer>
      <ContentWrapper>
        <LeftSection>
          <div
            className="animation"
            style={{
              fontSize: "50px",
              fontWeight: "600",
              textTransform: "uppercase",
              textAlign: "center",
              color: "#ffffff",
              borderRadius: "3px",
              fontFamily: "Playpen Sans",
              position: "relative",
            }}
          >
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("yenİ nesİl okulun")
                  .typeString("<br/>")
                  .callFunction(() => {
                    setAnimationComplete(true);
                  })
                  .start();
              }}
            />
            {animationComplete && (
              <div className="relative inline-block">
                <span style={{ fontFamily: 'Playpen Sans', color: '#ffe600', fontWeight: 600 }}>
                  robot arkadaşım
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 255 32.61"
                  className="absolute w-full h-auto"
                  style={{
                    opacity: animationComplete ? 1 : 0,
                    bottom: '-55px',
                    left: 0
                  }}
                >
                  <path
                    d="M5 14.11s54-8 125-9 120 5 120 5-200.5-5.5-239.5 17.5"
                    className="fill-none stroke-[#ffe600] stroke-[6] stroke-round stroke-linejoin-round animate-draw"
                  />
                </svg>
                <style>{`
                  @keyframes draw {
                    from {
                      stroke-dashoffset: 822;
                    }
                    to {
                      stroke-dashoffset: 0;
                    }
                  }
                  .animate-draw {
                    stroke-dasharray: 822;
                    stroke-dashoffset: 822;
                    animation: draw 1.8s ease-out forwards;
                  }
                `}</style>
              </div>
            )}
          </div>
          <Description>
            Öğrenme serüvenin için özel olarak hazırlanan <br />
            interaktif dersler ve yapay zeka destekli araçlarla <br />
            dolu bir dünya seni bekliyor.
          </Description>
          <ButtonContainer>
            <StyledWrapper>
              <button className="button">
                <p className="button__text">
                  {["R", "O", "B", "O", "T", "", "", "A", "R", "K", "A", "D", "A", "Ş", "I", "M", ""].map(
                    (char, index) => (
                      <span key={index} style={{ "--index": index }}>
                        {char}
                      </span>
                    )
                  )}
                </p>
                <div className="button__circle">
                  <svg
                    width={14}
                    className="button__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 15"
                  >
                    <path
                      fill="currentColor"
                      d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    />
                  </svg>
                  <svg
                    className="button__icon button__icon--copy"
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    fill="none"
                    viewBox="0 0 14 15"
                  >
                    <path
                      fill="currentColor"
                      d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    />
                  </svg>
                </div>
              </button>
            </StyledWrapper>
            <Button2 />
          </ButtonContainer>
        </LeftSection>
        <RightSection>
          <img src={pp} alt="Video thumbnail" />
          <StyledWrapper2>
            <button className="button">
              <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="26px">
                <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" fill="currentColor" />
              </svg>
            </button>
          </StyledWrapper2>
        </RightSection>
      </ContentWrapper>
    </AppContainer>
  );
}

export default FirstSection;