import React from 'react';
import { useSelector } from 'react-redux';
const Navbar = () => {
  
  
  const user = useSelector((state) => state.admin.userInfo?.user);

  if (!user) {
    return <div>Loading...</div>; 
  }

  return (
    <div >
   <div className=' mx-2  my-3 py-5 flex gap-2 justify-center    ' >
    <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '16px', position: 'relative', lineHeight: '90%' }}>
          <span style={{ color: '#153c5b' }}>
         admin <br/><br/>   {user.name}
          </span>
          <br />
        </div>
   </div>

   </div>
  );
}

export default Navbar;


