import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import p1 from "../assets/images/videoo.png";
import p2 from "../assets/images/prop2.png";
import p3 from "../assets/images/prop3.png";
import p4 from "../assets/images/prop4.png";
import p5 from "../assets/images/prop5.png";

const Books = () => {
  const items = [
    { img: p1, title: "Etkileşimli Öğrenme Deneyimi" },
    { img: p2, title: "Kapsamlı Sözlük Kaynağı" },
    { img: p5, title: "Kişiselleştirilmiş Chatbot" },
    { img: p3, title: "Pratik ve Günlük Kullanım" },
    { img: p4, title: "Testlerle Sürekli Gelişim" },
  ];
  const centerIndex = Math.floor(items.length / 2);


  return (
    <StyledWrapper>
      <ResponsiveContainer>
        {/* Desktop view */}
        <DesktopView>
          {items.map((item, index) => (
            <div className="book" key={index}>
              <Card className="cover">
                <IconContainer>
                  <img src={item.img} alt={item.title} />
                </IconContainer>
                <StyledTitle>{item.title}</StyledTitle>
              </Card>
            </div>
          ))}
        </DesktopView>

        {/* Mobile view with slider */}
        <MobileView>
          <Swiper
            spaceBetween={20}
            slidesPerView="auto"
            centeredSlides={true}
            loop={false}
            initialSlide={centerIndex} // Orta karttan başla
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="book">
                  <Card className="cover">
                    <IconContainer>
                      <img src={item.img} alt={item.title} />
                    </IconContainer>
                    <StyledTitle>{item.title}</StyledTitle>
                  </Card>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </MobileView>
      </ResponsiveContainer>
    </StyledWrapper>
  );
}

const ResponsiveContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const DesktopView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 100%;

  @media (max-width: 760px) {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;
  
  @media (max-width: 760px) {
    display: block;
    
    .swiper {
      padding: 20px 0;
    }

    .swiper-slide {
      width: 180px;
      height: auto;
    }
  }
`;

const IconContainer = styled.div`
  width: 80px;
  border-radius: 50%;
  padding: 15px;
  background-color: #8571cf;

  img {
    width: 100%;
    height: auto;
  }
`;

const Card = styled.div`
  height: 250px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  justify-content: space-evenly;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
  }
`;

const StyledTitle = styled.h6`
  font-weight: 500;
  font-size: 16px; 
  background-color: #8571cf;
  width: 100%;
  display: inline;
  padding: 5px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Onest';
  color: #ffffff;
  text-align: center;
`;

const StyledWrapper = styled.div`
  padding-top: 50px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 760px) {
    .book{transform: scale(0.8);}
 
  }
  .book {
    position: relative;
    border-radius: 20px;
    height: 200px;
    width: 180px;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    background-color: #ece8f8;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #523e8d;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #523e8d;
    font-family: Poppins;
    padding: 10px;
    padding-left: 35px;
    padding-right: 10px;
    padding-top: 10px;
  }
`;

export default Books;