import React, { useState } from "react";
import styled from 'styled-components';
import contentData from "../../../english/components/iframe.js"; // Iframe verilerini burada kullanıyoruz

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const Letter = styled.p`
  font-weight: 500;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
`;


const StyledButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #4c5565;
  color: white;
  border: none;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #384152;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 10px;
  font-family: "Poppins";

  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
  }

  button.learn-more {
    width: 12rem;
    height: auto;
  }

  button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
  }

  button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }

  button.learn-more .circle .icon.arrow {
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }

  button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
  }

  button.learn-more .button-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }

  button:hover .circle {
    width: 100%;
  }

  button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
  }

  button:hover .button-text {
    color: #fff;
    font-family: "Poppins";
  }
`;

const VocabularyComponent = ({ selectedUnit }) => {
  const [currentView, setCurrentView] = useState("menu");
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isWordsListVisible, setIsWordsListVisible] = useState(false);

  const handleTopicClick = (iframe) => {
    setSelectedTopic(iframe);
    setCurrentView("iframe");
  };

  const handleWordsButtonClick = () => {
    setIsWordsListVisible(true); // Kelimeler listesi görünür olacak
  };

  const handleGoBack = () => {
    setIsWordsListVisible(false); // Kelimeler listesinden geri git
  };

  const renderAllSections = () => {
    const filteredContent = contentData.find(content => content.unit === selectedUnit.title);
    if (!filteredContent) {
      return <p>Bu ünite için içerik bulunamadı.</p>;
    }
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: "transparent", width: "100%", margin:"60px",marginLeft:"150px"}}>
        {filteredContent.sections.map((section, sectionIndex) => (
          <div key={sectionIndex} style={{
            marginBottom: "20px", display: "flex", justifyContent: "center", flexDirection: "column", border: "1px solid #e7e7e7", borderRadius: "20px", width: "80vw", padding: "25px", backgroundColor: "#f6f6f6"
          }}>

            <Styledh6>{section.section || section.sectionTitle}</Styledh6>

            {section.iframes.map((iframe, iframeIndex) => (
              iframe.title === "WORDS" ? (
                <StyledWrapper key={iframeIndex} onClick={handleWordsButtonClick}>
                  <button className="learn-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow" />
                    </span>
                    <span className="button-text">{iframe.title}</span>
                  </button>
                </StyledWrapper>
              ) : (
                <StyledWrapper key={iframeIndex} onClick={() => window.open(iframe.content, '_blank', 'noopener,noreferrer')}>
                  <button className="learn-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow" />
                    </span>
                    <span className="button-text">{iframe.title}</span>
                  </button>
                </StyledWrapper>
              )
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderIframe = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginLeft: "200px" }}>
        {selectedTopic.content} 
      </div>
    );
  };

  const renderWordsList = () => {
    const wordsData = contentData.find(content => content.unit === selectedUnit.title)
      ?.sections.find(section => section.section === "Unite Words")
      ?.iframes.find(iframe => iframe.title === "WORDS")?.content;
  
    if (!wordsData) return null;
  
    return (
      <div style={{ width: "100%", padding: "20px",backgroundColor:"#f8f8f8",borderRadius:"20px" }}>
        <StyledButton onClick={handleGoBack}>Geri</StyledButton>
     
          {wordsData.map((group, index) => (
            <div key={index}  style={{width:"100%",}} >
              {Object.entries(group).map(([letter, words]) => (
                <div key={letter} style={{ borderBottom: "1px solid #d4d4d4", padding: "20px",width:"100%",fontFamily:"Poppins" ,display:"flex",justifyContent:"center",gap:"30px" }}>
                  <Letter>{letter}</Letter>
                  <ul
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(5, 1fr)", 
                      gap: "30px", 
                      listStyleType: "none", 
                      padding: 0,
                    }}
                  >
                    {Object.entries(words).map(([english, turkish]) => (
                      <li key={english} style={{ textAlign: "left" }}>
                        
                       <span style={{fontFamily:"Poppins",fontWeight:"500",color:"#384152"}}>{english}:</span>  
                       <span style={{fontFamily:"Poppins",fontWeight:"300",color:"#797979"}}>{turkish}</span>  
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
   
      </div>
    );
  };
  
  return (
    <div style={{ width: "100%", marginLeft:"100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {isWordsListVisible && renderWordsList()} {/* Kelimeler listesi gösteriliyor */}
      {!isWordsListVisible && currentView === "menu" && renderAllSections()} {/* Menü içerikleri gösteriliyor */}
      {currentView === "iframe" && renderIframe()}
    </div>
  );
};

export default VocabularyComponent;
