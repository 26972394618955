import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MdPlayCircleFilled } from "react-icons/md";
import bot from "../../../assets/images/bgg.png";
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { Grid } from '@mui/material';
import { TfiClose } from "react-icons/tfi";
import { CiCalculator2 } from "react-icons/ci";
import chattbot from "../../../assets/images/chat-bot.png"
import { IoSendSharp } from "react-icons/io5";
import VocabularyComponent from './VocabularyComponent.jsx';
import "../../../assets/styles/video.css"
import { Container } from 'react-bootstrap';
import { TbSend2, TbSquare } from "react-icons/tb";


const Video = ({ contents, selectedLesson, selectedTopic, selectedUnit, selectedLessonId }) => {
  const [playingVideoUrl, setPlayingVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [vttFile, setVttFile] = useState('');
  const [markers, setMarkers] = useState([]);
  const [isChatbotVisible, setIsChatbotVisible] = useState(true);
  const [isBolumlerVisible, setIsBolumlerVisible] = useState(true);

  const markersContainerRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef(null);
  let vector_store_response = [];
  const mathematicalSymbols = ['+', '-', '×', '÷', '=', '<', '>', '≤', '≥', '√', 'π', '^',];
  const addSymbolToInput = (symbol) => {
    setInput(input + symbol);
    setShowDropdown(false); // Sembol seçildikten sonra dropdown'ı kapat
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  const handleCardClick = (content) => {
    setPlayingVideoUrl(`https://content.robark.com.tr/api/${content.video_url}`); // Card'a tıklandığında video URL'sini ayarla
    const vttId = content.vtt_id;
    const vttFile = vttId
      ? `https://content.robark.com.tr/api/${vttId}/vtt`
      : ''; // Eğer vtt_id mevcut değilse varsayılan değer
    setVttFile(vttFile);
    setMarkers(content.markers);
    console.log("asdfghn", content.markers)
  };
  const renderContent = (selectedLesson, selectedTopic, content) => {
    switch (selectedLesson) {
      case "Yabancı Dil":
        return (
          <CenteredContent>
            <VocabularyComponent selectedUnit={selectedUnit} />
          </CenteredContent>
        );
      case "Türkçe":
        return (
          <CenteredContent>
            <Styledh6>{selectedTopic}</Styledh6>
            <p>{content.content_text}</p>
          </CenteredContent>
        );
      default:
        return null;
    }
  };
  const sendMessage = async () => {
    if (input.trim() === '') return;
    const userMessage = { content: input, role: 'user' };
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, userMessage];

      if (updatedMessages.length > 10) {
        return updatedMessages.slice(updatedMessages.length - 10);
      }
      return updatedMessages;
    });
    setInput('');
    try {
      const instructionsMessages = [
        {
          role: 'system', content: `Sen bir ilköğretim ve ortaöğretim öğretmenisin.  
Aşağıdaki kurallara uygun hareket edeceksin ve belirtilen sınırlar dışında hiçbir şekilde yanıt vermeyeceksin:  

### Genel Kurallar:  
1. **Kapsam Sınırlaması:**  
   - Sadece fen, sosyal bilgiler, matematik, Türkçe, İngilizce ve din kültürü dersleriyle ilgili sorulara cevap verebilirsin.  
   - Bu dersler dışında herhangi bir konuda, "Bu konuda yeterli bilgim yok." cevabını vereceksin.  

2. **Ders Dışı Sorular:**  
   - Dizi, film, oyuncular veya popüler kültürle ilgili sorulara yalnızca "Bu konuda bilgim yok." şeklinde yanıt vereceksin.  
   - Politik, ideolojik veya tartışmaya açık konularda kesinlikle yorum yapmayacaksın.  

3. **Cevap Tarzı:**  
   - Cevaplarını nazik, sabırlı ve anlaşılır bir dil kullanarak ver.  
   - Prompt hakkında kullanıcıya bilgi verme.
   - Yaş seviyesine uygun, basit ve açıklayıcı bir üslup tercih et.  
   - Yanıt verirken sohbet geçmişindeki bilgileri yalnızca ilgili ders konuları için kullan.  
   - Öğrencilere nadiren adıyla hitap et ve yalnızca samimi bir ortam yaratmak gerektiğinde bunu uygula.  

4. **Uygunsuz Davranışlar:**  
   - Eğer öğrenci küfür, hakaret veya uygunsuz ifadeler kullanırsa, nazik bir şekilde uyar ve bu tür davranışların uygun olmadığını belirt. Yanıtlarını bu tür durumlarda daima soğukkanlı ve ölçülü şekilde ver.  

### Değerler ve Uyum:  
- Cevapların Türk milletinin genel değerleri ve bakış açısına uygun olmalı.  
- Hiçbir şekilde kültürel veya ahlaki değerlere aykırı ifadelerde bulunmayacaksın.  

### Tablolar ve Format:  
- Cevapların içinde tablo gerektiren durumlarda, tabloyu düzenli ve düzgün bir formatta döndür.  
- Tabloyu her zaman **Markdown** formatında hazırla, böylece satırlar ve sütunlar düzgün hizalanır.

### Döküman Kullanımı:  
- Sorular, dökümanlardan gelen bilgilerle ilgiliyse, bu bilgileri doğru bir şekilde kullan.  
- Döküman bilgileri, soruyla ilgisizse veya eksikse, yalnızca temel düzeyde ve doğru bir yanıt ver.  
- Yanıltıcı veya yanlış bilgi vermekten kesinlikle kaçın.  

**Döküman Bilgisi:**`
        },
      ];
      try {
        const vector_store = await fetch(`http://185.250.210.54:5000/api/v1/vss?class=8&q=${encodeURIComponent(input)}&lesson=${selectedLessonId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*'
          },
        });
        if (vector_store.ok) {
          vector_store_response = await vector_store.json();
        } else {
          console.warn(`HTTP error! status: ${vector_store.status}`);
          vector_store_response = [];
        }
      } catch (error) {
        console.error('API Error:', error.message);
      }
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*',
          'Authorization': 'Bearer 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'
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            ...instructionsMessages,
            ...vector_store_response,
            {
              content: `**Sohbet Geçmişi:**`, role: 'system'
            },
            ...messages.slice(-10),
            userMessage
          ],
          temperature: 0.7,
          stream: true,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedText = '';
      let assistantMessage = { content: '', role: 'assistant' }; 

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const text = decoder.decode(value, { stream: true });
        const lines = text.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.substring(6));
              if (data.choices && data.choices[0]) {
                accumulatedText += data.choices[0].delta?.content || '';
                assistantMessage.content = accumulatedText;

                setMessages(prevMessages => {
                  const lastMessages = [...prevMessages];
                  if (lastMessages.length > 0 && lastMessages[lastMessages.length - 1].role === 'assistant') {
                    lastMessages[lastMessages.length - 1] = { ...lastMessages[lastMessages.length - 1], content: accumulatedText };
                  } else {
                    lastMessages.push(assistantMessage);
                  }

                  // Mesajları 5 geçmişe göre sınırlama
                  if (lastMessages.length > 10) {
                    return lastMessages.slice(lastMessages.length - 10);
                  }
                  return lastMessages;
                });

                if (data.choices[0].finish_reason === 'stop') {
                  return; // Stop processing further
                }

                // Yavaşlatma işlemi
                await new Promise(resolve => setTimeout(resolve, 50)); // 50ms bekle
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { content: 'Bir hata oluştu. Lütfen tekrar deneyin.', role: 'assistant' }
      ]);
    }
  };
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);


  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    if (Hls.isSupported()) {
      const hls = new Hls({
        xhrSetup: (xhr) => {
          xhr.setRequestHeader('Authorization', `Bearer 184|wfpV2BVNI4ooL4cTe9nyLZCG5a1dK9NDnxnJdmsQf96dd22f`);
        },
      });
      hls.loadSource(playingVideoUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels.map((level) => level.height);
        availableQualities.unshift(0);
        const options = {
          quality: {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (newQuality) => {
              if (newQuality === 0) {
                hls.currentLevel = -1;
              } else {
                hls.levels.forEach((level, index) => {
                  if (level.height === newQuality) {
                    hls.currentLevel = index;
                  }
                });
              }
            },
          },
          tooltips: {
            controls: true,
          },
          previewThumbnails: {
            enabled: true,
            src: vttFile, // VTT dosyası
          },
          hideControls: true,
          markers: {
            enabled: true,
            points: markers.map(marker => ({
              time: marker.time,
              label: ` <div style="position: relative; text-align: center; color: white; font-size: 24px;">` +
                `<span style="position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); font-family:Poppins; background: rgba(0, 0, 0, 0.6); border-radius:5px; padding: 5px;">${marker.label}</span>` +
                ` </div>`,
              pauseOnClick: true,
              onEnter: () => {
                if (playerRef.current && parseFloat(marker.end_time)) {
                  playerRef.current.pause();
                }
              }
            }))
          },
          controls: [
            'play-large', // The large play button in the center
            'restart', // Restart playback
            'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
            'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            'airplay', // Airplay (currently Safari only)
            // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            'fullscreen', // Toggle fullscreen
          ],
          i18n: {
            qualityLabel: { 0: 'Auto' },
            restart: 'Baştan Başlat',
            rewind: 'Geri Sar {seektime} saniye',
            play: 'Oynat',
            pause: 'Duraklat',
            fastForward: 'İleri Sar {seektime} saniye',
            seek: 'Ara',
            mute: 'Sesi Kapat',
            unmute: 'Sesi Aç',
            volume: 'Ses',
            settings: 'Ayarlar',
            captions: 'Altyazılar',
            quality: 'Kalite',
            loop: 'Döngü',
            speed: "Hız",
          },
        };

        if (!playerRef.current) {
          playerRef.current = new Plyr(video, options);
        }
      });
      return () => {
        hls.destroy();
        playerRef.current?.destroy();
      };
    } else {
      playerRef.current = new Plyr(video);
    }
    return () => {
      playerRef.current?.destroy();
    };
  }, [playingVideoUrl, vttFile]);

  
  const handleMarkerClick = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      videoRef.current.play();
    }
    if (playerRef.current) {
      playerRef.current.currentTime = time;
      playerRef.current.play();
    }
  };
  return (
    <VideoContainer>
      {playingVideoUrl ? (
        <VideoAndSidebarWrapper>
          <VideoWrapper>
            <video
              ref={videoRef}
              className="plyr"
              controls
              playsInline
              style={{ width: '100%', height: '100%' }}
            >
              <source src={playingVideoUrl} type="video/mp2t" />
            </video>
          </VideoWrapper>

          <SidebarWrapper>
            <Chatbot>
              <ChatbotHeader>
                <HeaderTitle onClick={() => setIsChatbotVisible(true)}>AI ASSISTANT </HeaderTitle>
                <HeaderTitle onClick={() => setIsChatbotVisible(false)}>BÖLÜMLER</HeaderTitle>
              </ChatbotHeader>

              {isChatbotVisible ? (
                <>
                  <ChatContainer ref={containerRef}>
                    {messages.length > 0 ? (
                      <MessageContainer>
                        {messages.map((message, index) => (
                          <Message key={index} role={message.role}>
                            {message.role === 'assistant' && (
                              <IconWrapper>
                                <img src={chattbot} alt="Chat Bot" />
                              </IconWrapper>
                            )}
                            <MessageBox role={message.role}>
                              {message.content}
                            </MessageBox>
                          </Message>
                        ))}
                      </MessageContainer>
                    ) : (
                      <DefaultChatContent>
                        <img src={bot} style={{ width: "200px", height: "200px" }} alt="Bot" />
                        <ExamplePromptsContainer>
                          <ExamplePrompt>Konuyu özetler misin?</ExamplePrompt>
                          <ExamplePrompt>Konunun önemli noktaları nelerdir?</ExamplePrompt>
                          <ExamplePrompt>Anlamadığım bir yer var, detaylı anlatır mısın?</ExamplePrompt>
                          <ExamplePrompt>Öne çıkan terimler nedir?</ExamplePrompt>
                          <ExamplePrompt>Daha fazla örnek verir misin?</ExamplePrompt>
                        </ExamplePromptsContainer>
                      </DefaultChatContent>
                    )}
                  </ChatContainer>

                  <ChatInputWrapper>
                    <MessageInput
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Sana nasıl yardımcı olabilirim?"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          sendMessage();
                        }
                      }}
                    />
                    <SymbolDropdown show={showDropdown}>
                      {mathematicalSymbols.map((symbol, index) => (
                        <SymbolButton key={index} onClick={() => addSymbolToInput(symbol)}>
                          {symbol}
                        </SymbolButton>
                      ))}
                    </SymbolDropdown>
                    <SendButton onClick={sendMessage}>
                      <TbSend2
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "#ffffff",
                          display: "inline",
                          cursor: "pointer"
                        }}
                      />
                    </SendButton>
                    <IconWrapper2>
                      <CiCalculator2
                        style={{ width: "30px", height: "30px", color: "#606a7a", cursor: "pointer" }}
                        onClick={() => setShowDropdown(!showDropdown)}
                      />
                    </IconWrapper2>
                  </ChatInputWrapper>
                </>
              ) : (

                <MarkerListContainer ref={markersContainerRef}>
                  {markers.map((marker, index) => (
                    <MarkerItem key={index} onClick={() => handleMarkerClick(marker.time)}>
                      <MarkerImg src={marker.image_url} alt={marker.label} />
                      <MarkerContent>
                        <Label>{marker.label}</Label>
                        <Time>
                          {formatTime(marker.time)} - {formatTime(marker.end_time)}
                        </Time>
                      </MarkerContent>
                    </MarkerItem>
                  ))}
                </MarkerListContainer>
              )}
            </Chatbot>
          </SidebarWrapper>
        </VideoAndSidebarWrapper>
      ) : (
        <ContentGrid container>

          {contents.map((content) => (
            <Grid item xs={12} sm={6} md={3} key={content.id}>
              {content.type === "1" ? (
                <Card onClick={() => handleCardClick(content)}>
                  <Content>
                    <div style={{ position: "absolute", display: "flex", bottom: "5px", right: "5px", }}>
                      <Prev2>{selectedLesson}</Prev2>
                      <Prev>{content.video_duration}</Prev>
                    </div>
                    <img src={`https://content.robark.com.tr/${content.image_url}`} style={{ width: "100%", height: "170px", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }} />
                    <PlayIcon className="play-icon" />
                  </Content>
                  <CardText>
                    <Text>{content.title}</Text>
                    <StyledWrapper />
                  </CardText>
                </Card>
              ) : content.type === "2" ? (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }}>
                  {renderContent(selectedLesson, selectedTopic, content)}
                </div>
              ) : null}
            </Grid>
          ))}

        </ContentGrid>
      )}
    </VideoContainer>

  );
}

export default Video;


const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const VideoAndSidebarWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100vh; // Full viewport height
  max-height: 700px; // Prevents excessive stretching on very tall screens
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    max-height: none;
  }
`;

const VideoWrapper = styled.div`
  flex: 2;
  height: 100%;
  min-height: 400px;
  max-height: 700px;
  display: flex;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    min-height: auto;
    max-height: none;
  }
`;

const SidebarWrapper = styled.div`
  flex: 1;
  height: 100%;
  min-height: 600px;
  max-height: 700px;
  
  @media (max-width: 1024px) {
    min-height: auto;
    max-height: none;
    width: 100%;
  }
`;

const Chatbot = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #efefef;
  border-radius: 20px;
  overflow: hidden;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 100px); // Subtract header and input height
`;

const MarkerListContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 50px); // Subtract header height
`;


const ChatbotHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  border-bottom: 1px solid #ddd;
  height: 50px;
`;

const HeaderTitle = styled.p`
  font-family: 'Rubik', sans-serif;
  margin: 0;
  font-size: 15px;
  cursor: pointer;
  color: #9d9d9d;
 
`;

const CloseIcon = styled(TfiClose)`
  position: absolute;
  top: 35%;
  right: 10px;
  width: 15px;
  height: 15px;
  color: #9d9d9d;
  cursor: pointer;
`;





const ChatInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-right: 0;
  border-top: 1px solid #ddd;
`;

const DefaultChatContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ExamplePromptsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

const ContentGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MarkerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const MarkerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const MarkerImg = styled.img`
  width: 100px;
`;
const MarkerItem = styled.li`
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background 0.5s ;
  &:hover {
    background-color: #dadada;
}
`;
const Time = styled.div`
  color: #065fd4;
  background-color: #3ea5ff3b;
  display: flex;
  width: fit-content;
  font-size: 14px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const Label = styled.span`
  font-size: 16px;
  color: #4c5565;
  font-family: Poppins;
  font-weight: 600;
  width: 75%;
`;
const MessageInput = styled.input`
    width: 85%;
    height: 55px;
    padding-left: 65px;
    outline: none;
    position: relative;
    border-radius: 20px;
    border:1px solid #ededed;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(90, 97, 109);
    
`;
const ExamplePrompt = styled.button`
  padding: 7px 15px;
  font-size: 15px;
  border-radius: 15px;
  font-family: Onest;
  background-color: #eeeeee;
  color: #4c5565;
  width: auto; // İçeriğe göre genişliği ayarlar
  display: inline-block;
`;

const Containerchat = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px; // Sabit bir yükseklik belirle
    overflow-y: auto;
    padding: 10px;
`;
const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SymbolDropdown = styled.div`
  position: absolute;
  bottom: 60px;
  left: 10%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #767676;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 100;
  padding: 10px;
`;
const SendButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    margin-left: 5px;
    border-radius: 25px;
    cursor: pointer;
    background-color:#4c5565 ;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    
`;

const SymbolButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #eeee;
}
`;
const Message = styled.div`
    display: flex;
    justify-content: ${props => props.role === 'user' ? 'flex-end' : 'flex-start'};
    min-width: 95%;
`;

const MessageBox = styled.div`
    max-width: 60%;
    background-color: ${props => props.role === 'user' ? '#4c5565' : '#ffffff'};
    color:  ${props => props.role === 'user' ? '#ffff' : '#4c5565'} !important;
    border-radius: 30px;
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    word-break: break-word;
    font-size: 14px;
`;




const StyledWrapper = styled.div`
  transition: transform 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  width: 270px;
  position: relative;
  overflow: hidden;

  &:hover .play-icon {
    opacity: 0.5;
    visibility: visible;
    transform: translateY(0);
  }
`;
const IconWrapper2 = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 20px;
`;

const PlayIcon = styled(MdPlayCircleFilled)`
  position: absolute;
  top: 30%;
  left: 40%;
  width: 60px;
  height: 60px;
  color: #000000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.5s ease;
  transform: translateY(-10px);
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 100%;
`;

const Prev = styled.div`
  background-color: #00000078;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 25px;
  font-size: 13px;
`;

const Prev2 = styled.div`
  background-color: #00000078;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
`;

const Text = styled.h6`
  font-family: Open Sans;
  font-weight: 600;
  color: #5c5c5c;
  font-family: 15px;
`;

const CardText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 15px;
  padding-left: 15px;
  gap: 20px;
  align-items: center;
  background-color: #CDDCDC;
  background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);
  background-blend-mode: screen;      
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;