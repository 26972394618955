import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from './redux/userSlice.js';
import './App.css';
import LoginPage from './views/admin/components/LoginPage';
import MyDrawer from './views/admin/pages/DrawerCom.jsx';
import Home from './pages/Dashboard.jsx';
import OkulDashboard from "./views/school/pages/Dashboard.jsx";
import LoginStudent from './pages/LoginStudent.jsx';
import LoginTeacher from './pages/LoginTeacher.jsx';
import TeacherDashboard from "./views/teacher/pages/Dashboard.jsx";
import StaffLogin from './pages/LoginStaff.jsx';
import PaymentIframeComponent from './components/Payment.jsx';
import Basket from './pages/Basket.jsx';
import TestManager from "./views/student/manager/TestManager.jsx"
import Lessons from './views/student/components/Lessons.jsx';
import Test from './views/student/components/Tests.jsx';
import Homeworks from './views/student/components/Homeworks.jsx';
import Chatbot from './views/student/components/Chatbot.jsx';
import StudentDashboard from './views/student/pages/Dashboard.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

//site renk kodları  
//sarı :  #fcd200   #ffe600
//mor :  #ac99f2
// açık mor : #ebe4ff
//mavi : 


function App() {
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.userInfo);

  const adminToken = useSelector((state) => state.admin.token);

  return (
    <Router>  <ToastContainer />
      <Routes>
        <Route path="/admin/login" element={<LoginPage />} />

        {adminToken && <Route path="/admin/*" element={<MyDrawer />} />}

        <Route path="/" element={<Home />} />
        <Route path="/student/login" element={<LoginStudent />} />
        <Route path="/staff/login" element={<StaffLogin />} />
        <Route path="/teacher/login" element={<LoginTeacher />} />
        <Route path="/teacher" element={<TeacherDashboard />} />
        <Route path="/sepet" element={<Basket />} />
        <Route path="/odeme" element={<PaymentIframeComponent iframeSrc={"https://sandbox-cpp.iyzipay.com/?token=0a42282f-ec9c-4dcd-afb4-19b64a819f47&lang=tr"} />} />
        <Route path="/staff" element={<OkulDashboard />} />

        {token ? (
          <>
            <Route path="/student/homeworks" element={<Homeworks />} />
            <Route path="/student/chatbot" element={<Chatbot />} />
            <Route path="/student/lessons" element={<Lessons />} />
            <Route path="/student/tests" element={<Test />} />
            <Route path="/student/test" element={<TestManager />} />
            <Route path="/student" element={<StudentDashboard />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;


