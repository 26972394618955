import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { TbSend2, TbSquare } from "react-icons/tb";
import chatbot from "../../../assets/images/chatbot.png"
import { CiZoomIn } from "react-icons/ci";
import { CiCircleQuestion } from "react-icons/ci";
import { CiCircleList } from "react-icons/ci";
import chattbot from "../../../assets/images/chat-bot.png"
import { PiMathOperations } from "react-icons/pi";
import ReactMarkdown from 'react-markdown';
import NavigationBar from './NavigationBar';
import { useSelector } from 'react-redux';
import bg from "../../../assets/images/bg.svg";
import remarkGfm from 'remark-gfm';


const Search = styled.input`
    width: 300px;
    height: 50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
        color: #384152;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
    }
`;


const StyledTitle = styled.h6`
    font-size: 20px;
    font-family: Varela;
    color: #4c5565;
    font-weight: 600;
    display: inline;
    text-decoration: none;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 70vh;
    max-height:70vh;
    background-color: #fafafa;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e6e6;
    border-radius: 30px;
    padding: 10px;
    overflow-y: auto;
`;

const MessageInput = styled.input`
    width: calc(100% - 200px);
    height: 55px;
    padding-left: 65px;
    outline: none;
    position: relative;
    background-color: #fcfcfc;
    border-radius: 25px;
    border: 1px solid #ddd;
    font-family: 'Poppins', sans-serif;
    color: rgb(90, 97, 109);
    
`;

const SendButton = styled.button`
    width: 50px;
    height: 50px;
    background-color: #4c5565;
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
     max-height: 100%; // Konteynerin tamamını kullan

    
`;
const Info = styled.div`
   height: 150px;
   display: flex; 
   flex-direction: column;
    justify-content: center;
     align-items: center; 
     color: #5d6573;
    max-width: 280px; 
    border: 1px solid #e0e0e0;
     border-radius: 15px; 
     padding: 5px 20px ;
     font-size: 14px;
     text-align: center ;
     gap: 10px;
`;

const Message = styled.div`
    display: flex;
    justify-content: ${props => props.role === 'user' ? 'flex-end' : 'flex-start'};
    min-width: 95%;
`;

const MessageBox = styled.div`
    max-width: 60%;
    background-color: ${props => props.role === 'user' ? '#4c5565' : '#ffffffdb'};
    color:  ${props => props.role === 'user' ? '#ffff' : '#4c5565'} !important;
    border-radius: 30px;
    padding: 20px;
    font-family: Poppins;
    word-break: break-word;

    // Add Markdown-specific styling
    & > * {
        margin-bottom: 10px;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
    & code {
        background-color: ${props => props.role === 'user' ? '#5c6575' : '#f0f0f0'};
        padding: 2px 4px;
        border-radius: 4px;
        font-family: monospace;
        font-size: 0.9em;
    }
    & pre {
        background-color: ${props => props.role === 'user' ? '#5c6575' : '#f0f0f0'};
        padding: 10px;
        border-radius: 8px;
        overflow-x: auto;
        font-family: Poppins;
        font-size: 0.9em;
    }
    & h1, & h2, & h3, & h4, & h5, & h6 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    & ul, & ol {
        padding-left: 20px;
        margin-bottom: 10px;
    }
    & blockquote {
        border-left: 4px solid ${props => props.role === 'user' ? '#ffffff' : '#4c5565'};
        padding-left: 10px;
        color: ${props => props.role === 'user' ? '#ffffff' : '#4c5565'};
        font-style: italic;
    }
`;
const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 20px;
`;


const GlobalStyle = createGlobalStyle`
  @keyframes effect {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;



const IconWrapper2 = styled.div`
  position: absolute;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SymbolDropdown = styled.div`
  position: absolute;
  bottom: 60px;
  left: 10%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #767676;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 100;
  padding: 10px;
`;

const SymbolButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #eeee;
}
`;

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const containerRef = useRef(null);
    let vector_store_response = [];
    const [isStreaming, setIsStreaming] = useState(false);
    const [abortController, setAbortController] = useState(null);

    const userInfo = useSelector((state) => state.user.userInfo);
    const name = userInfo.student.first_name;

    const [showDropdown, setShowDropdown] = useState(false);

    const mathematicalSymbols = ['+', '-', '×', '÷', '=', '<', '>', '≤', '≥', '√', 'π', '^',];

    const addSymbolToInput = (symbol) => {
        setInput(input + symbol);
        setShowDropdown(false); // Sembol seçildikten sonra dropdown'ı kapat
    };

    const sendMessage = async () => {
        if (isStreaming) { // Eğer stream aktifse
            if (abortController) {
                abortController.abort(); // Streami durdur
                setAbortController(null); // Abort kontrolünü temizle
                setIsStreaming(false); // Stream durumunu false yap
            }
            return; // İşlemi burada sonlandır
        }
        const controller = new AbortController();
        setAbortController(controller); // Yeni bir abort controller oluştur
        setIsStreaming(true); // Streami başlat
        if (input.trim() === '') return;

        // Kullanıcı mesajını ekleyin
        const userMessage = { content: input, role: 'user' };
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, userMessage];

            // Mesajları 5 geçmişe göre sınırlama
            if (updatedMessages.length > 10) {
                return updatedMessages.slice(updatedMessages.length - 10);
            }
            return updatedMessages;
        });
        setInput('');

        try {
            const instructionsMessages = [
                {
                    role: 'system', content: `Sen bir ilköğretim ve ortaöğretim öğretmenisin.  
Aşağıdaki kurallara uygun hareket edeceksin ve belirtilen sınırlar dışında hiçbir şekilde yanıt vermeyeceksin:  

### Genel Kurallar:  
1. **Kapsam Sınırlaması:**  
   - Sadece fen, sosyal bilgiler, matematik, Türkçe, İngilizce ve din kültürü dersleriyle ilgili sorulara cevap verebilirsin.  
   - Bu dersler dışında herhangi bir konuda, "Bu konuda yeterli bilgim yok." cevabını vereceksin.  

2. **Ders Dışı Sorular:**  
   - Dizi, film, oyuncular veya popüler kültürle ilgili sorulara yalnızca "Bu konuda bilgim yok." şeklinde yanıt vereceksin.  
   - Politik, ideolojik veya tartışmaya açık konularda kesinlikle yorum yapmayacaksın.  

3. **Cevap Tarzı:**  
   - Cevaplarını nazik, sabırlı ve anlaşılır bir dil kullanarak ver.  
   - Yaş seviyesine uygun, basit ve açıklayıcı bir üslup tercih et.  
   - Yanıt verirken sohbet geçmişindeki bilgileri yalnızca ilgili ders konuları için kullan.  
   - Öğrencilere nadiren adıyla hitap et ve yalnızca samimi bir ortam yaratmak gerektiğinde bunu uygula.  

4. **Uygunsuz Davranışlar:**  
   - Eğer öğrenci küfür, hakaret veya uygunsuz ifadeler kullanırsa, nazik bir şekilde uyar ve bu tür davranışların uygun olmadığını belirt. Yanıtlarını bu tür durumlarda daima soğukkanlı ve ölçülü şekilde ver.  

### Değerler ve Uyum:  
- Cevapların Türk milletinin genel değerleri ve bakış açısına uygun olmalı.  
- Hiçbir şekilde kültürel veya ahlaki değerlere aykırı ifadelerde bulunmayacaksın.  

### Döküman Kullanımı:  
- Sorular, dökümanlardan gelen bilgilerle ilgiliyse, bu bilgileri doğru bir şekilde kullan.  
- Döküman bilgileri, soruyla ilgisizse veya eksikse, yalnızca temel düzeyde ve doğru bir yanıt ver.  
- Yanıltıcı veya yanlış bilgi vermekten kesinlikle kaçın.

### Tablolar ve Format:  
- Cevapların içinde tablo gerektiren durumlarda, tabloyu düzenli ve düzgün bir formatta döndür.  
- Tabloyu her zaman **Markdown** formatında hazırla, böylece satırlar ve sütunlar düzgün hizalanır.

**Döküman Bilgisi:**`
                },
            ];

            try {
                const vector_store = await fetch(`http://185.250.210.54:5000/api/v1/vss?class=8&q=${encodeURIComponent(input)}&lesson=3`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                if (vector_store.ok) {
                    vector_store_response = await vector_store.json();
                } else {
                    console.warn(`HTTP error! status: ${vector_store.status}`);
                    vector_store_response = [];
                }
            } catch (error) {
                console.error('API Error:', error.message);
            } 


            // const response = await fetch('https://api.deepinfra.com/v1/openai/chat/completions', {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 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'
                },
                body: JSON.stringify({
                    // model: "meta-llama/Meta-Llama-3.1-70B-Instruct",
                    model: "gpt-4o-mini",
                    messages: [
                        ...instructionsMessages,
                        ...vector_store_response,
                        { content: `**Sohbet Geçmişi:**`, role: 'system' },
                        ...messages.slice(-10),  // Son 4 mesajı al
                        userMessage
                    ],
                    temperature: 0.7,
                    stream: true,
                }),
                signal: controller.signal,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }


            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = '';
            let assistantMessage = { content: '', role: 'assistant' };

            while (true) {
                const { done, value } = await reader.read();
                if (done) break; // Eğer okuma işlemi tamamlandıysa döngüden çık
    
                const text = decoder.decode(value, { stream: true });
                const lines = text.split('\n');
    
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.substring(6));
                            if (data.choices && data.choices[0]) {
                                accumulatedText += data.choices[0].delta?.content || '';
                                assistantMessage.content = accumulatedText;
    
                                setMessages(prevMessages => {
                                    const updatedMessages = [...prevMessages];
                                    if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].role === 'assistant') {
                                        updatedMessages[updatedMessages.length - 1] = { ...updatedMessages[updatedMessages.length - 1], content: accumulatedText };
                                    } else {
                                        updatedMessages.push(assistantMessage);
                                    }
                                    return updatedMessages.slice(-10); // Mesajları 10 ile sınırla
                                });
    
                                if (data.choices[0].finish_reason === 'stop') {
                                    setIsStreaming(false);
                                    return; // İşlemi durdur
                                }
                            }
                        } catch (error) {
                            if (error.name === 'AbortError') {
                                console.log('İstek durduruldu.');
                                break; // Döngüyü kır
                            }
                            console.error('Hata:', error);
                        }
                    }
                }
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Hata:', error);
            }
        } finally {
            setIsStreaming(false); // Stream durumunu sıfırla
            setAbortController(null); // Abort controller'ı sıfırla
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }, [messages]);

    //backgroundImage: "linear-gradient(-225deg, #ebf7f3 0%, #FFE6FA 100%)" 
    return (
        <div style={{ width: "100%", height: "100vh", }}>
            <NavigationBar item="Chatbot" />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <Container ref={containerRef}>
                    {messages.length > 0 ? (
                        <MessageContainer>
                            {messages.map((message, index) => (
                        <Message key={index} role={message.role}>
                            {message.role === 'assistant' && (
                                <IconWrapper>
                                    <img src={chattbot} />
                                </IconWrapper>
                            )}
                            <MessageBox role={message.role}>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}

                                    components={{
                                        p: ({ node, ...props }) => (
                                            <p
                                                {...props}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    color: message.role === 'user' ? '#ffffff' : '#4c5565',
                                                    textDecoration: 'none',
                                                    fontFamily: "Poppins"
                                                }}
                                            />
                                        ),

                                        table: ({ node, ...props }) => (
                                            <table
                                                style={{
                                                    width: '100%',
                                                    borderCollapse: 'collapse',
                                                    marginBottom: '1rem',
                                                    color: '#4c5565',
                                                }}
                                                {...props}
                                            />
                                        ),
                                        th: ({ node, ...props }) => (
                                            <th
                                                style={{
                                                    border: '1px solid #ddd',
                                                    padding: '8px',
                                                    backgroundColor: '#f8f9fa',
                                                    textAlign: 'left',
                                                }}
                                                {...props}
                                            />
                                        ),
                                        td: ({ node, ...props }) => (
                                            <td
                                                style={{
                                                    border: '1px solid #ddd',
                                                    padding: '8px',
                                                }}
                                                {...props}
                                            />
                                        ),

                                    }}
                                >
                                    {message.content}
                                </ReactMarkdown>
                            </MessageBox>
                        </Message>
                    ))}
                        </MessageContainer>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "50px", padding: "20px" }} >
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }} >
                                <img src={chatbot} style={{ width: "100px", height: "100px" }} />
                                <StyledTitle>Derslerle ilgili aklına takılan soruları birlikte keşfedelim.</StyledTitle>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>

                                <Info>
                                    <CiZoomIn style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Aklına takılan konuları chatbot’a sorarak açıklama yapmasını isteyebilirsin
                                    <div />
                                </Info>
                                <Info>
                                    <CiCircleQuestion style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Anlamadığın bir dersi chatbot’a danışarak daha iyi öğrenebilirsin
                                    <div />
                                </Info>
                                <Info >
                                    <CiCircleList style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Çözmekte zorlandığın bir soruyu sorup çözüm alabilirsin.
                                    <div />
                                </Info>

                            </div>
                        </div>
                    )}
                </Container>


                <div className='flex items-center justify-center mt-4 gap-2 relative w-full'>




                    <MessageInput
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Merak ettiklerini sorabilirsin"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage();
                            }
                        }}

                    />                <SymbolDropdown show={showDropdown}>
                        {mathematicalSymbols.map((symbol, index) => (
                            <SymbolButton key={index} onClick={() => addSymbolToInput(symbol)}>
                                {symbol}
                            </SymbolButton>
                        ))}
                    </SymbolDropdown>
                    <SendButton onClick={sendMessage}>
                    {isStreaming ? (
                        <TbSquare 
                        style={{ 
                            width: "30px", 
                            height: "30px", 
                            color: "#ffffff", 
                            display: "inline", 
                            cursor: "pointer" 
                        }} 
                        />
                    ) : (
                        <TbSend2 
                        style={{ 
                            width: "30px", 
                            height: "30px", 
                            color: "#ffffff", 
                            display: "inline", 
                            cursor: "pointer" 
                        }} 
                        />
                    )}
                    </SendButton>

                    <IconWrapper2>
                        <PiMathOperations style={{ width: "30px", height: "30px", color: "#606a7a", cursor: "pointer" }} onClick={() => setShowDropdown(!showDropdown)} />
                    </IconWrapper2>



                </div>
            </div>
        </div>
    );
}

export default Chatbot;